import Alert from 'react-s-alert';
import { ButlerContextProps } from '../../utils/butlerContext';

const loadData = async (newsQueryPage: number, butlerContext: ButlerContextProps) => {
  try {
    const eventRequest = butlerContext.eventService.getEvents();
    const profilesRequest = butlerContext.profileService.getUsers();
    const newsRequest = butlerContext.newsService.getNews(newsQueryPage, false, 5);

    const [profiles, news, events] = await Promise.all([profilesRequest, newsRequest, eventRequest]);

    return {
      events,
      profiles,
      news
    };
  } catch (e) {
    Alert.error('Noe gikk galt. Kontakt noen i IT for hjelp.');
    return { events: [], profiles: [], news: [] };
  }
};

export default loadData;
