import { Blockquote, Heading, MarkdownList, ThematicBreak } from 'components/prosjekter/markdownRenderer';
import React from 'react';
import ReactMarkdown from 'react-markdown';

interface Props {
  children: React.ReactNode;
}

const Markdown = ({ children }: Props) => {
  return (
    <ReactMarkdown
      components={{
        ul: ({ children, depth, ordered }) => (
          <MarkdownList ordered={ordered} depth={depth}>
            {children}
          </MarkdownList>
        ),
        hr: () => <ThematicBreak />,
        blockquote: ({ children }) => <Blockquote>{children}</Blockquote>,
        h1: ({ children }) => <Heading level={1}>{children}</Heading>,
        h2: ({ children }) => <Heading level={2}>{children}</Heading>,
        h3: ({ children }) => <Heading level={3}>{children}</Heading>,
        h4: ({ children }) => <Heading level={4}>{children}</Heading>,
        h5: ({ children }) => <Heading level={5}>{children}</Heading>,
        h6: ({ children }) => <Heading level={6}>{children}</Heading>
      }}
    >
      {(children as string) ?? ''}
    </ReactMarkdown>
  );
};

export default Markdown;
