import React from 'react';

export const prettyPhoneNumber = number => {
  if (!number) return '';
  const numString = String(number);
  return `${numString.substring(0, 2)} ${numString.substring(2, 4)} ${numString.substring(4, 6)} ${numString.substring(
    6,
    numString.length
  )}`;
};

export const toFirstLetterLowerCase = string => {
  if (!string) return '';
  return `${string.substring(0, 1).toUpperCase()}${string.substring(1, string.length).toLowerCase()}`;
};

export const prettyDate = rawDate => {
  const date = new Date(rawDate);
  const day = date.getDate() < 10 ? '0' + date.getDate() : date.getDate();
  const month = date.getMonth() < 9 ? '0' + (date.getMonth() + 1) : date.getMonth() + 1;
  const year = date.getFullYear();
  return `${day}/${month}/${year}`;
};

export const prettyTime = rawDate => {
  const date = new Date(rawDate);
  const hours = date.getHours() < 10 ? '0' + date.getHours() : date.getHours();
  const minutes = date.getMinutes() < 10 ? '0' + date.getMinutes() : date.getMinutes();
  return `${hours}:${minutes}`;
};

export const prettyDateDjango = date => {
  date = new Date(date);
  const day = date.getDate() < 10 ? '0' + date.getDate() : date.getDate();
  const month = date.getMonth() < 9 ? '0' + (date.getMonth() + 1) : date.getMonth() + 1;
  const year = date.getFullYear();
  return `${year}-${month}-${day}`;
};

export const timeSince = date => {
  let seconds = Math.floor((new Date() - date) / 1000);
  let interval = Math.floor(seconds / 31536000);

  if (interval > 1) {
    return interval + ' år';
  }

  interval = Math.floor(seconds / 2592000);
  if (interval > 1) {
    return interval + ' måneder';
  } else if (interval === 1) {
    return interval + ' måned';
  }

  interval = Math.floor(seconds / 86400);
  if (interval > 1) {
    return interval + ' dager';
  } else if (interval === 1) {
    return interval + ' dag';
  }

  interval = Math.floor(seconds / 3600);
  if (interval > 1) {
    return interval + ' timer';
  } else if (interval === 1) {
    return interval + ' time';
  }

  interval = Math.floor(seconds / 60);
  if (interval > 1) {
    return interval + ' minutter';
  } else if (interval === 1) {
    return interval + ' minutt';
  }

  return Math.floor(seconds) + ' sekunder';
};

export const makeFormOptions = options => {
  return Object.keys(options).map(function(key, index) {
    return (
      <option key={key} value={key}>
        {options[key]}
      </option>
    );
  });
};
