import { IUser } from '@jrc/jrc-butler-api/butler';
import { Link } from 'react-router-dom';
import React from 'react';

interface IProps {
  profile: IUser | undefined;
}

const ProfileImage: React.FC<IProps> = ({ profile }) => {
  return (
    <Link to={`/ansatte/${profile?.auth_id}`}>
      <img
        alt="Profile"
        className="round"
        //style={{ width: 40, height: 40 }}
        src={profile?.image ? profile.image : 'https://bulma.io/images/placeholders/128x128.png'}
      />
    </Link>
  );
};

export default ProfileImage;
