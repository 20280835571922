import React, { useState, useRef } from 'react';
import Modal from '../shared/Modal';
import AvatarEditor from 'react-avatar-editor';

interface IPictureForm {
  isActive: boolean;
  isLoading?: boolean;
  onClose: () => void;
  onSave: (updatedData: any) => Promise<void>;
}

const PictureForm = ({ isActive = false, isLoading = false, onClose, onSave }: IPictureForm) => {
  // TODO: Finish this
  const [image, setImage] = useState<string | null>(null);
  const [scale, setScale] = useState(1);
  const profileImageEditorRef = useRef(null);

  const onSelectFile = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files.length > 0) {
      const reader = new FileReader();
      //@ts-ignore
      reader.addEventListener('load', () => setImage(reader.result), false);
      reader.readAsDataURL(e.target.files[0]);
      reader.onerror = error => console.log('Error: ', error);
    }
  };

  const onClickSave = async () => {
    console.log(profileImageEditorRef);
    if (profileImageEditorRef.current) {
      // If you want the image resized to the canvas size (also a HTMLCanvasElement)
      //@ts-ignore
      const canvasScaled = profileImageEditorRef.current.getImageScaledToCanvas();

      const file: Blob = (await createBlob(canvasScaled)) as Blob;
      onSave({ file });
    }
  };

  const createBlob = (canvas: any) => {
    return new Promise((resolve, reject) => {
      canvas.toBlob((file: any) => {
        file.name = `file`;
        resolve(file);
      }, 'image/png');
    });
  };

  const onScaleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setScale(parseFloat(e.target.value));
  };

  return (
    <Modal title="Last opp bilde" isActive={isActive} onClose={onClose} onSave={onClickSave} isLoading={isLoading}>
      <div className="field">
        <div className="control">
          <input type="file" onChange={onSelectFile} placeholder="Velg bilde" />
        </div>
        {image && (
          <>
            <AvatarEditor ref={profileImageEditorRef} image={image} width={350} height={350} scale={scale} />
            <div>
              <label>Zoom: </label>
              <input
                type="range"
                min="0.1"
                max="5"
                step="0.1"
                value={scale}
                id="scale-slider"
                onChange={onScaleChange}
              />
            </div>
          </>
        )}
      </div>
    </Modal>
  );
};

export default PictureForm;
