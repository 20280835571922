import { IHttpApiBridge, MediaType } from 'conjure-client';

type UploadResponse = {
  message: string;
  url: string;
};

export default class FileStorageService {
  bridge: IHttpApiBridge;

  constructor(bridge: IHttpApiBridge) {
    this.bridge = bridge;
  }

  async upload(file: Blob, endpoint: string): Promise<UploadResponse> {
    const formData = new FormData();
    formData.append('file', file);

    return await this.bridge.callEndpoint({
      method: 'POST',
      endpointPath: endpoint,
      pathArguments: [],
      queryArguments: [],
      requestMediaType: MediaType.MULTIPART_FORM_DATA,
      responseMediaType: MediaType.APPLICATION_JSON,
      data: formData
    });
  }

  async uploadProfileImage(file: Blob): Promise<UploadResponse> {
    return this.upload(file, '/files/profiles/upload');
  }

  async uploadInternarenaFile(file: Blob): Promise<UploadResponse> {
    return this.upload(file, '/files/internarena/upload');
  }
}
