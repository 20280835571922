import * as jwt from 'jsonwebtoken';
import { butlerMetabase } from '@jrc/jrc-butler-api';

export const metabase = async (projectId: number, metaService: butlerMetabase.IMetaService) => {
  const metabaseToken = await metaService.getToken();
  var payload = {
    resource: { dashboard: 1 },
    params: {
      id: projectId
    }
  };
  var jwtToken = jwt.sign(payload, metabaseToken.token);
  const link = `https://projectstats.jrc.no/${jwtToken}`;
  return link;
};

export default metabase;
