import React, { useState } from 'react';
import { IUser } from '@jrc/jrc-butler-api/butler';
import sortBy from 'lodash/sortBy';

interface IProps {
  notSelectedMembers?: Array<IUser>;
  addMember: (member_id: string) => void;
}

const MemberSelect: React.FC<IProps> = ({ notSelectedMembers, addMember }) => {
  const [value, setValue] = useState<string>('');

  const onChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setValue(event.target.value);
  };

  const renderSuggestion = (member: IUser) => {
    return (
      <div key={member.auth_id} onClick={() => addMember(member.auth_id!)} className="member-suggestion">
        <div>
          {member.first_name} {member.last_name}
        </div>
      </div>
    );
  };

  const formattedValue = value.toLowerCase();
  let notSelectedmembersThatMatchSearchQuery: Array<IUser> = [];

  if (value !== '' && notSelectedMembers) {
    notSelectedmembersThatMatchSearchQuery = notSelectedMembers.filter((member: IUser) => {
      const fullName = (member.first_name + ' ' + member.last_name).toLowerCase();
      return fullName.includes(formattedValue);
    });
  } else {
    notSelectedmembersThatMatchSearchQuery = [];
  }

  return (
    <div className="member-list-suggest m-b-10">
      <div>
        <input onChange={onChange} className="input" placeholder="Søk etter ansatt..." />
      </div>

      <div className="unselected-member-list">
        {sortBy(notSelectedmembersThatMatchSearchQuery, ['first_name', 'last_name']).map((member: IUser) =>
          renderSuggestion(member)
        )}
      </div>
    </div>
  );
};

export default MemberSelect;
