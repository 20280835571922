import EmployeeDetails from '../pages/ansatte/detail';
import Employees from '../pages/ansatte/index';
import Home from '../pages/hjem/hjem';
import JuniorInnsikt from '../pages/innsikt/JuniorInnsikt';
import ClientDetails from '../pages/klienter/detail';
import Clients from '../pages/klienter/index';
import EditNews from '../pages/nyheter/edit';
import News from '../pages/nyheter/index';
import CreateNews from '../pages/nyheter/ny';
import ProjectDetails from '../pages/prosjekter/detail';
import Projects from '../pages/prosjekter/index';
import Styleguide from '../pages/styleguide/index';
import TimeEntry from '../pages/timeregistrering';
import Unpaid from '../pages/ubetalteTimer/unpaid';
import Wiki from '../pages/wiki/index';
import RouteItem from '../types/RouteItem';

const routes: RouteItem[] = [
  {
    title: 'Hjem',
    path: '/',
    exact: true,
    subRoute: false,
    Component: Home
  },
  {
    title: 'Nyheter',
    path: '/nyheter',
    exact: true,
    permissionForVisibility: 'news:read:unpublished',
    subRoute: false,
    Component: News
  },
  {
    title: 'Opprett ny nyhet',
    path: '/nyheter/ny',
    exact: true,
    permissionForVisibility: 'news:create',
    subRoute: true,
    Component: CreateNews
  },
  {
    title: 'Endre nyhet',
    path: '/nyheter/:id',
    exact: true,
    permissionForVisibility: 'news:update',
    subRoute: true,
    Component: EditNews
  },
  {
    title: 'Ansatte',
    path: '/ansatte',
    exact: true,
    subRoute: false,
    Component: Employees
  },
  {
    title: 'Ansattdetaljer',
    path: '/ansatte/:id',
    exact: true,
    subRoute: true,
    Component: EmployeeDetails
  },
  {
    title: 'Prosjekter',
    path: '/prosjekter',
    exact: true,
    subRoute: false,
    Component: Projects
  },
  {
    title: 'Prosjektdetaljer',
    path: '/prosjekter/:id',
    exact: true,
    subRoute: true,
    Component: ProjectDetails
  },
  {
    title: 'Klienter',
    path: '/klienter',
    exact: true,
    subRoute: false,
    Component: Clients
  },
  {
    title: 'Klientdetaljer',
    path: '/klienter/:id',
    exact: true,
    subRoute: true,
    Component: ClientDetails
  },
  {
    title: 'Timeføring',
    path: '/timeregistrering',
    exact: false,
    subRoute: false,
    Component: TimeEntry
  },
  {
    title: 'Styleguide',
    path: '/styleguide',
    exact: false,
    subRoute: false,
    Component: Styleguide
  },
  {
    title: 'Juniorboken',
    path: '/juniorboken',
    exact: false,
    subRoute: false,
    Component: Wiki
  },
  {
    title: 'Ubetalt',
    path: '/ubetalt',
    exact: true,
    subRoute: true,
    Component: Unpaid
  },
  {
    title: 'JuniorInnsikt',
    path: '/juniorinnsikt',
    exact: false,
    subRoute: false,
    Component: JuniorInnsikt
  },
  {
    title: 'JuniorWiki',
    subRoute: false,
    path: 'https://wiki.jrc.no'
  },
  {
    title: 'JuniorHvelvet',
    subRoute: false,
    path: 'https://vault-cluster.vault.a7255286-26c3-4285-98cb-8d0cc0b54a16.aws.hashicorp.cloud:8200/'
  }
];

export default routes;
