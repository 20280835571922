import React, { FC, useEffect } from 'react';
import ColumnHeader from '../../components/shared/ColumnHeader';
import Section from '../../components/styleguide/Section';
import WikiLink from '../../components/wiki/WikiLink';
import WikiWithoutLink from '../../components/wiki/WikiWithoutLink';

interface Iprops {
  title: string;
}

const Wiki: FC<Iprops> = ({ title }) => {
  useEffect(() => {
    document.title = `JrC - ${title}`;
  }, [title]);

  return (
    <div className="columns styleguide">
      <div className="column wiki">
        <ColumnHeader className="section" title={title} border />
        <Section title="Innsalg av prosjekt">
          <ul>
            <WikiWithoutLink
              title="Informasjon om salgsprosessen"
              description="Informasjon om salgsprossesen kommer her med link."
            />
            <WikiLink
              title="Provisjon"
              description="Hvis du gjør et innsalg i Junior Consulting får du provisjon. Informasjon om den gjeldene provisjonsordningen finner du her."
              link="https://docs.google.com/document/d/1x2b5r6yLFRHKX-t6QIj_-MwwOqjH-zp0XBkFUjNMoQg/edit?usp=sharing"
            />
            <WikiLink
              title="Kaffedeck"
              description="Mal til kaffedeck som benyttes på initielle kundemøter/mailer for å vise hvem vi er og hva vi har gjort tidligere.
              Viktig at malen skreddersys for kunden som får den, ved å f.eks. legge inn relevante referanseprosjekter"
              link="https://docs.google.com/presentation/d/1Eii2Fd8J5BVjHuelQTKgKzAEky2QxUnLSMi_SB8yV1E/edit?usp=sharing"
            />
            <WikiLink
              title="Powerpoint mal"
              description="Mal til powerpointer som benyttes på alle mulige slides. Viktig at malen skreddersys for hver presentasjon."
              link="https://docs.google.com/presentation/d/1M-d1WxGfCKu5ZvIImSnwrrlOewMSEjkH/edit#slide=id.p1"
            />
            <WikiLink
              title="Prising"
              description="Her finner du en guide til hvordan vi priser våre prosjekter. Merk at vi fakturer hvis kunden hindrer fremdrift av prosjektet."
              link="https://docs.google.com/document/d/1yvbhEwqSMaYVp9MbnGS2-Y7HUEK7D-I1gqMgdfnBdy8/edit?usp=sharing"
            />
            <WikiLink
              title="Konsulent-CVer"
              description="Ønsker kunden konsulent-Cver? Du finner en mal her. Konsulent-CVer kan være verdifullt i innsalgsprosessen for å vise vår kompetanse til kunden."
              link="https://drive.google.com/drive/folders/1puMiDy1wYIosrTdTyIUdv75cZSfBa0q3?usp=sharing"
            />
            <WikiLink
              title="Skrive tilbud"
              description="Kontraktsmal på tilbudsskriving finner du her. Alt det som er i rødt må endres slik at det passer til prosjektet."
              link="https://docs.google.com/document/d/1MusUYoSesMyqy9JmA3dSAAqbJ9LvYkhrp3fYGQzDMYs/edit?usp=sharing"
            />
          </ul>
        </Section>
        <Section title="Oppstart av prosjekt etter signering">
          <ul>
            <WikiLink
              title="Opprette kort på Trello"
              description="Kopier kortet *CopyThisCard - When Sending An Offer* og fjern checkboxes som ikke er relevante."
              link="https://trello.com/c/tfQ2xgm2/312-copythiscard-when-sending-an-offer"
            />
            <WikiLink
              title="Lag mappe på Drive"
              description="Kopier relevant innhold fra linken. Legg den i riktig mappe innenfor klienter.
              Fyll ut prosjektinformasjon, her er det spesielt viktig å fylle det ut hvem som skal ha provisjon."
              link="https://drive.google.com/drive/folders/14lG2bpgJItA52u1nE3qJkx5_G4kRq_Rw?usp=sharing"
            />
            <WikiLink
              title="Oppdragsavtale"
              description="Alle i JrC på prosjekt skal ha skrevet under en oppdragsavtale når de er på prosjekt. Oppskrift på dette finner du i README i denne mappen"
              link="https://drive.google.com/drive/folders/1WuasjAng5dQDX5kcix4uXfJZA4UYPtI7?usp=sharing"
            />
            <WikiLink
              title="Ansvarsfordeling"
              description="Se over ansvarsfordelingen før dere starter opp. Den beskriver ansvarsområder til konsulent, prosjektleder og ansvarlig partner på prosjektet."
              link="https://drive.google.com/file/d/1EJckKrs06EGkQklJzGnJ3GwaHhdHDR9o/view?usp=sharing"
            />
            <WikiWithoutLink
              title="Til slutt"
              description="Husk å lage en slack-kanal og sett opp prosjektet på intern.jrc.no/prosjekter. Uten dette kan du ikke føre timer."
            />
          </ul>
        </Section>
        <Section title="Mersalg">
          <ul>
            <WikiLink
              title="Mersalg"
              description="I JrC er alle ansatte ansvarlige for å få inn nye prosjekter. Her finner du mer informasjon om mersalg."
              link="https://docs.google.com/document/d/1OcH3CmSlxA2Dq__8O_I0wYcGkkMc-ea4/"
            />
          </ul>
        </Section>
        <Section title="Økonomi">
          <ul>
            <WikiWithoutLink
              title="Hvordan føre timer"
              description="Det skal kun føres timer som vi kan fakturere kunden for, dvs arbeid som er direkte knyttet mot et prosjekt. Du fører ikke timer for transport til kunde med mindre det er avtalt.
              Hvis det jobbes utover det timeantallet man har blitt enige med kunde om så kan dette føres, men da må økonomiansvarlig få beskjed.
              Internarbeid skal ikke timeføres. Bli enige innad i teamet om hvilke tags man skal bruke."
            />
            <WikiWithoutLink
              title="Hvordan føre utlegg"
              description="Send bilde av kvittering med beskrivelse av: Hva det gjelder og om det er et utlegg fra egen lomme, eller kvittering fra bruk av kredittkort.
              Sendes til okonomi@juniorconsulting.no. (Merk at vi skal kanskje bytte system)."
            />
            <WikiWithoutLink
              title="Lønn"
              description="Lønn utbetales 10. hver måned. Siden JrC ikke har råd til å utbetale lønn før kunden faktisk har betalt fakturaen med timene som er jobbet,
              vil lønnen som regel utbetales 1-2 måneder etter at man har arbeidet.
              Du kan selv følge med på om timene du har arbeidet er fakturert og betalt ved å klikke på prosjektet du arbeider på og scrolle ned til time du har jobbet."
            />
            <WikiLink
              title="Provisjonsordning"
              description="Provisjon utbetales i % av hver faktura som betales, ikke som helhet i start eller slutt.
              Dvs at hvis du har provisjon på 5% av prosjektet, vil du få lønn på 5% av alle fakturabeløp knyttet til prosjektet. Ytterligere informasjon om provisjonsordning finner du i linken."
              link="https://docs.google.com/document/d/1x2b5r6yLFRHKX-t6QIj_-MwwOqjH-zp0XBkFUjNMoQg/edit?usp=sharing"
            />
          </ul>
        </Section>
        <Section title="Informasjon for nyansatte">
          <ul>
            <WikiWithoutLink
              title="Tilganger"
              description="Du skal ha tilgang til internarena, Trello, Slack og JrC epost når du jobber i Junior Consulting."
            />
            <WikiLink
              title="Onboarding"
              description="Alle konsulenter blir onboardet i sin respektive gruppe (Design, Startegi eller Tech). På denne lenken finner du ressurser fra onboarding (kun strategi per nå)."
              link="https://drive.google.com/drive/folders/1Fks2P29eUfgDycvlnt4INrPEuXSJoRsT?usp=sharing"
            />
            <WikiLink
              title="Oppstartspakke"
              description="Oppstartspakken finner du her.
              Du kan finne informasjon om alt fra krav til passord og kryptering av disk til historien om JrC. (Det mangler dokumenter på Drive per 30.10.19)"
              link="https://drive.google.com/drive/folders/1Tdi33UktO6jOAXKCbdffZNk6R4bYmrwZ?usp=sharing"
            />
            <WikiLink
              title="Checkliste for profiler på internarena, Slack og Trello"
              description="Her finner du et dokument som beskriver hva du skal fylle ut av informasjon på de ulike tjenestene vi bruker. Husk profilbilde på Trello. (Dokument kommer)"
            />
            <WikiLink
              title="JrC-ordboka"
              description="Ordbok med mange ord og uttrykk som benyttes i en juniorhverdag."
              link="https://docs.google.com/spreadsheets/d/14Ukgcu1v6fcCR_K6AWgDvkmTG0rdm_7V147OOZfI4LI/edit?usp=sharing"
            />
          </ul>
        </Section>
        <Section title="Administrativt">
          <ul>
            <WikiLink
              title="Ansattoversikt"
              description="Oversikt over alle de ansatte i selskapet med litt mer informasjon enn det du finner her på
                  Internarena"
              link="https://docs.google.com/spreadsheets/d/1GW7KNsE-iUMkwcE01qNE_LkwAASU8BJm8XeA1A2wuPk/edit?usp=sharing"
            />
            <WikiLink
              title="Ansvarsfordeling"
              description="Besrivelse av ansvarsområder for de ulike stillingene i selskapet"
              link="https://drive.google.com/file/d/1EJckKrs06EGkQklJzGnJ3GwaHhdHDR9o/view?usp=sharing"
            />
            <WikiLink
              title="Provisjonsordning"
              description="Gjeldende provisjonsordning"
              link="https://docs.google.com/document/d/1x2b5r6yLFRHKX-t6QIj_-MwwOqjH-zp0XBkFUjNMoQg/edit?usp=sharing"
            />
            <WikiLink
              title="Staffingoversikt"
              description="Oversikt over hvilke prosjekter de ansatte er staffet på"
              link="https://docs.google.com/spreadsheets/d/1uscPl44mYZ7EZjZukBeFDsHz9UXtl5ejxYP15tiW72M/edit?usp=sharing"
            />
          </ul>
        </Section>
        <Section title="Guides">
          <ul>
            <WikiLink
              title="GDPR i JrC-prosjekter"
              description="Her finner du informasjon om når GDPR er relevant i et prosjekt.
            Det er inkludert noen eksempler fra tidligere JrC-prosjekter og andre gode kilder for å hente inn mer informasjon."
              link="https://docs.google.com/document/d/1za6AvSslOraMCMqVyJKrQForRM42nOlOiwUIxSEfM-8"
            />
          </ul>
        </Section>
        <Section title="Interne Systemer">
          <ul>
            <WikiLink
              title="Kontaktinfo per system i Interne Systemer"
              description="Har du noen spørsmål til våre Interne Systemer, send en melding! I interne systemer har vi delt inn ansvar slik at rett person å kontakte for hvert system er beskrevet i dette doc'et."
              link="https://docs.google.com/document/d/1PWWXWGjrimHqBiTF1kcJzD_hUCCiUkbQfjJYfbUqxwM/edit?usp=sharing"
            />
          </ul>
        </Section>
      </div>
    </div>
  );
};

export default Wiki;
