import React from 'react';
import PropTypes from 'prop-types';

interface IProps {
  title: string;
  children: React.ReactNode;
}

const Section: React.FC<IProps> = ({ title, children }: IProps) => (
  <div className="guide-section section">
    <div className="is-size-4">{title}</div>
    <div>{children}</div>
  </div>
);

Section.propTypes = {
  title: PropTypes.string.isRequired
};

export default Section;
