import React from 'react';

interface IProps {
  name: string;
  type: string;
  file_id: string;
  export_as: string;
  office_version?: string;
}

const Template: React.FC<IProps> = ({ name, type, file_id, export_as, office_version }: IProps) => (
  <div className="template-container level">
    <div className="card">
      <header className="card-header">
        <p className="card-header-title">{name}</p>
        <div className="card-header-icon" aria-label="more options">
          <span className="icon">
            <i className="fas fa-angle-down" aria-hidden="true" />
          </span>
        </div>
      </header>
      <div className="card-content">
        <iframe title="Google Drive" src={'https://drive.google.com/file/d/' + file_id + '/preview'} />
      </div>
      <footer className="card-footer">
        <a href={'https://drive.google.com/file/d/' + file_id + '/edit'} className="card-footer-item">
          Åpne i Google Drive
        </a>
        {type === 'document' ? (
          <a href={`https://docs.google.com/document/d/${file_id}/export?format=doc`} className="card-footer-item">
            Last ned .docx versjon
          </a>
        ) : (
          <a
            href={
              office_version
                ? 'https://drive.google.com/uc?export=download&id=' + file_id
                : 'https://docs.google.com/' + type + '/d/' + file_id + '/export/' + export_as
            }
            className="card-footer-item"
          >
            Last ned office versjon
          </a>
        )}
      </footer>
    </div>
  </div>
);

export default Template;
