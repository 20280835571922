import React, { Fragment } from 'react';
import EditIcon from '../../static/edit_icon.svg';

interface ColumnHeaderProps {
  title: string;
  className?: string;
  border?: boolean;
  onClick?: () => void;
  rightComponent?: React.DetailedHTMLProps<any, any>;
}

const ColumnHeader = ({ title, className, border = false, onClick, rightComponent }: ColumnHeaderProps) => (
  <Fragment>
    <div className="columns is-mobile title-bar is-gapless p-t-10">
      <div className={'column is-6' + (rightComponent ? '' : 'is-12-mobile')}>
        <div className={'is-size-4 has-text-weight-semibold ' + (className ?? '') + (border ? '' : 'm-b-20')}>
          {`${title} `}
          {onClick && <img src={EditIcon} alt="Edit" style={{ width: 15 }} onClick={onClick} />}
        </div>
      </div>

      {rightComponent && <div className="column has-text-right">{rightComponent}</div>}
    </div>
    {border && <div className="m-b-10 m-t-0" />}
  </Fragment>
);

export default ColumnHeader;
