import React, { useEffect, useState } from 'react';
import ArticleForm from '../../components/nyheter/ArticleForm';
import { history } from '../../utils/history';
import { useButler } from '../../utils/butlerContext';
import { useParams } from 'react-router';
import IdUrlParams from '../../types/IdUrlParams';
import { INewsItem } from '@jrc/jrc-butler-api/butler';

interface IProps {
  setIsLoading: (isLoading: boolean) => void;
  title: String;
}

const EditArticle: React.FC<IProps> = ({ setIsLoading, title }) => {
  const butler = useButler();
  const { id } = useParams() as IdUrlParams;

  const [article, setArticle] = useState<INewsItem>();

  useEffect(() => {
    const newsService = butler.newsService;
    document.title = `JrC - ${title}`;

    setIsLoading(true);

    const fetchData = async () => {
      const news = await newsService.getNewsById(parseInt(id));
      setArticle(news);
      setIsLoading(false);
    };

    fetchData();
  }, [setArticle, butler.newsService, id, setIsLoading, title]);

  const submitContent = async (title: string, markup: string, published: boolean) => {
    const newsService = butler.newsService;

    const article = {
      title,
      body: markup,
      published
    };

    await newsService.updateNews(parseInt(id), article);

    history.push('/nyheter');
  };

  return (
    <div style={{ width: '100%' }}>
      {article && (
        <ArticleForm
          markup={article.body}
          published={article.published!}
          submitContent={submitContent}
          title={article.title}
        />
      )}
    </div>
  );
};

export default EditArticle;
