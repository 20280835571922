import React, { useState, useEffect, useCallback } from 'react';
import { Link } from 'react-router-dom';
import isEqual from 'lodash/isEqual';
import ColumnHeader from '../../components/shared/ColumnHeader';
import EditIcon from '../../static/edit_icon.svg';
import NameForm from '../../components/shared/NameForm';
import DescriptionForm from '../../components/shared/DescriptionForm';
import ProjectCommentBox from '../../components/prosjekter/ProjectCommentBox';
import ProjectInfoForm from '../../components/prosjekter/ProjectInfoForm';
import ProjectResourceForm from '../../components/prosjekter/ProjectResourceForm';
import TeamMemberForm from '../../components/prosjekter/TeamMemberForm';
import { prettyDate } from '../../utils/helpers';
import loadData from '../../components/prosjekter/loadData';
import saveData from '../../components/prosjekter/saveForm';
import MODAL_TYPES from '../../components/prosjekter/modalTypes';
import MemberList from '../../components/shared/MemberList';
import ProjectHours from '../../components/prosjekter/ProjectHours';
import SalesForm from '../../components/shared/DescriptionForm';
import SURVEY_ANSWERS from '../../components/prosjekter/customerSurveyAnswers';
import Badge from '../../components/shared/Badge';
import { useButler } from '../../utils/butlerContext';
import metabase from '../../utils/metabase';
import { IEvent, IProject, IProjectMember, ITime, IUser, MemberRole } from '@jrc/jrc-butler-api/butler';
import { IResources } from '../../types/Resources';
import { IUserWithRole } from '../../types/UserWithRole';
import { Member } from 'types/Member';
import { match } from 'react-router';
import Markdown from 'components/shared/Markdown';

interface IProps {
  match: match<{ id: string }>;
  setIsLoading: (loading: boolean) => void;
  title: string;
}

const ProjectDetail: React.FC<IProps> = (props: IProps) => {
  const butlerContext = useButler();
  const { currentUser } = butlerContext;

  const [project, setProject] = useState<IProject>({ name: '' });
  const [profiles, setProfiles] = useState<IUser[]>([]);
  const [hours, setHours] = useState<ITime[]>([]);
  const [currentModal, setCurrentModal] = useState<string>('');
  const [isSaving, setIsSaving] = useState<boolean>(false);
  const [metabaseLink, setMetabaseLink] = useState<string>('');

  const getDataFromButler = useCallback(async () => {
    const { match, setIsLoading, title } = props;
    const { metaService } = butlerContext;
    document.title = `JrC - ${title}`;

    setIsLoading(true);
    const { project, profiles, hours } = await loadData(parseInt(match.params.id), butlerContext);

    const metabaseLink = project.id && (await metabase(project.id, metaService));

    setProject(project);
    setProfiles(profiles);
    setHours(hours);
    setMetabaseLink(metabaseLink ? metabaseLink : '');
    setIsLoading(false);
    // eslint-disable-next-line
  }, [butlerContext]);

  useEffect(() => {
    getDataFromButler();
  }, [getDataFromButler]);

  const closeModal = (): void => {
    setCurrentModal('');
  };

  const saveForm = async (updatedData: any) => {
    setIsSaving(true);
    const { match } = props;
    const oldProject = project;
    const { profiles, project: updatedProject } = await saveData(
      updatedData,
      currentModal,
      parseInt(match.params.id),
      project,
      currentUser ? currentUser.profile : { email: '' },
      butlerContext
    );

    setIsSaving(false);
    setCurrentModal('');
    setProfiles(profiles);
    setProject(updatedProject);

    let updatedPart = '';

    if (updatedData.members) {
      updatedPart += ' teamet til ';
    } else if (updatedData.start_date) {
      updatedPart += ' prosjektinformasjonen til ';
    } else if (updatedData.description) {
      updatedPart += ' beskrivelsen til ';
    } else if (updatedData.slack) {
      updatedPart += ' ressursene til ';
    }

    if (!isEqual(updatedProject, oldProject) && currentUser && updatedProject.id) {
      const event: IEvent = {
        type: 'project',
        title: `${currentUser.profile.first_name} oppdaterte ${updatedPart} prosjektet ${updatedProject.name}`,
        description: '',
        entity_id: updatedProject.id,
        change_by: currentUser.profile.auth_id ?? '0',
        image: currentUser.profile.image ?? '',
        icon: 'pencil',
        date: new Date().toISOString()
      };
      butlerContext.eventService.createEvent(event);
    }
  }; //End of saveForm

  // const updateWorkEntry = async (updatedData: ITime) => {
  //   const { timeService } = butlerContext;
  //   await timeService.updateTime(updatedData.id ?? 0, updatedData);
  // };

  if (project === null) return null;
  const memberProfilesWithRole =
    project.members &&
    project.members.map((member: IProjectMember) => {
      const memberProfile = profiles.find(profile => member.member_id.toString() === profile.auth_id);
      return { ...memberProfile, role: member.role } as IUserWithRole;
    });

  const projectWithMembers = { ...project, members: memberProfilesWithRole };
  const {
    sales_description,
    description,
    client,
    members,
    name,
    start_date,
    end_date,
    github_repo,
    google_drive,
    slack,
    trello,
    is_upselling,
    is_private,
    customer_survey,
    other_link
  } = projectWithMembers;

  const resources: IResources = { github_repo, google_drive, slack, trello, other_link };

  const SORT_ORDER: { [id in MemberRole]: number } = {
    AP: 1,
    PL: 2,
    K: 3,
    IK: 4
  };

  const membersSorted = members?.sort((memberA, memberB) => SORT_ORDER[memberA.role] - SORT_ORDER[memberB.role]);

  return (
    <div>
      <NameForm
        isActive={currentModal === MODAL_TYPES.NAME}
        isLoading={isSaving}
        onClose={closeModal}
        onSave={saveForm}
        name={project.name}
        placeholder="Prosjektnavn"
      />

      <DescriptionForm
        isActive={currentModal === MODAL_TYPES.DESCRIPTION}
        isLoading={isSaving}
        onClose={closeModal}
        onSave={saveForm}
        description={description ?? ''}
        placeholder="En kort beskrivelse av kunden [Kan skrives i Markdown]"
        title="Kundebeskrivelse"
      />

      <SalesForm
        isActive={currentModal === MODAL_TYPES.SALES_DESCRIPTION}
        isLoading={isSaving}
        onClose={closeModal}
        onSave={saveForm}
        description={sales_description ?? ''}
        placeholder="En kort beskrivelse av hvordan prosjektet ble solgt inn"
        title="Salgsbeskrivelse"
      />

      <ProjectResourceForm
        isActive={currentModal === MODAL_TYPES.PROJECT_RESOURCES}
        isLoading={isSaving}
        onClose={closeModal}
        onSave={saveForm}
        resources={resources}
      />

      <ProjectInfoForm
        isActive={currentModal === MODAL_TYPES.PROJECT_INFO}
        isLoading={isSaving}
        onClose={closeModal}
        onSave={saveForm}
        start_date={start_date ?? ''}
        end_date={end_date ?? ''}
        clientId={client && client.id}
        is_upselling={is_upselling ?? false}
        customer_survey={customer_survey}
        is_private={is_private ?? false}
      />

      <TeamMemberForm
        isActive={currentModal === MODAL_TYPES.TEAM_MEMBERS}
        isLoading={isSaving}
        onClose={closeModal}
        onSave={saveForm}
        profiles={profiles}
        members={projectWithMembers.members as Member[]}
      />

      <div className="columns is-multiline">
        <div className="title column is-9 p-b-0">
          <ColumnHeader title={name} onClick={() => setCurrentModal(MODAL_TYPES.NAME)} border />
        </div>
        <div className="title column p-b-0">
          {is_private ? <Badge text="Privat" color="#cb2026" /> : <Badge text="Offentlig" color="green" />}
        </div>
        <div className="info column is-full p-t-0">
          <div className="columns is-multiline is-flex-mobile flex-column-mobile">
            <div className="column is-8 p-t-0">
              <h2 className="is-size-5">
                Beskrivelse{' '}
                <img
                  src={EditIcon}
                  alt="Edit"
                  onClick={() => setCurrentModal(MODAL_TYPES.DESCRIPTION)}
                  style={{ width: 15 }}
                />
              </h2>
              <div className="m-t-5 has-text-weight-light">
                <Markdown>{description}</Markdown>
              </div>

              <h2 className="is-size-5 is-5-tablet m-t-50">
                Salg{' '}
                <img
                  src={EditIcon}
                  alt="Edit"
                  onClick={() => setCurrentModal(MODAL_TYPES.SALES_DESCRIPTION)}
                  style={{ width: 15 }}
                />
              </h2>
              <div className="m-t-5 has-text-weight-light">
                <Markdown>{sales_description}</Markdown>
              </div>

              <h2 className="is-size-5 is-5-tablet m-t-50">
                Team{' '}
                <img
                  src={EditIcon}
                  alt="Edit"
                  onClick={() => setCurrentModal(MODAL_TYPES.TEAM_MEMBERS)}
                  style={{ width: 15 }}
                />
              </h2>
              <div className="columns is-mobile is-multiline m-t-10">
                <MemberList members={membersSorted} />
              </div>
            </div>

            <div className="column is-full-mobile is-offset-1 p-t-0 first-order-mobile">
              <h5 className="is-size-5">
                Prosjektinfo{' '}
                <img
                  src={EditIcon}
                  alt="Edit"
                  onClick={() => setCurrentModal(MODAL_TYPES.PROJECT_INFO)}
                  style={{ width: 15 }}
                />
              </h5>

              <div className="columns is-multiline is-gapless is-size-6 contact-info has-text-weight-light">
                <div className="column is-half">Kunde</div>
                <div className="column is-narrow has-text-grey">
                  {client ? <Link to={`/klienter/${client.id}`}>{client.name}</Link> : 'Ikke satt'}
                </div>
                <div className="column is-half">Start</div>
                <div className="column is-narrow has-text-grey">
                  {start_date ? prettyDate(new Date(start_date)) : 'Ikke satt'}
                </div>

                <div className="column is-half">Slutt</div>
                <div className="column is-narrow has-text-grey">
                  {end_date ? prettyDate(new Date(end_date)) : 'Ikke satt'}
                </div>
                <div className="column is-half">Mersalg</div>
                <div className="column is-narrow has-text-grey">{is_upselling ? 'ja' : 'nei'}</div>
                <div className="column is-half">Kundeundersøkelse</div>
                <div className="column is-full has-text-grey">
                  {customer_survey ? SURVEY_ANSWERS[customer_survey] : SURVEY_ANSWERS.NOT_SET}
                </div>
              </div>

              <h5 className="is-size-5">Kontaktperson</h5>
              <div className="columns is-multiline is-gapless is-size-6 contact-info has-text-weight-light">
                <div className="column is-half">Navn</div>

                <div className="column is-half has-text-grey">{client && client.contact_name}</div>

                <div className="column is-half">Rolle</div>
                <div className="column is-half has-text-grey">{client && client.contact_role}</div>

                <div className="column is-half">Telefon</div>
                <div className="column is-half has-text-grey">{client && client.contact_number}</div>

                <div className="column is-half">E-post</div>
                <div className="column is-half has-text-grey">{client && client.contact_email}</div>
              </div>

              <h5 className="is-size-5">
                Ressurser{' '}
                <img
                  src={EditIcon}
                  alt="Edit"
                  onClick={() => setCurrentModal(MODAL_TYPES.PROJECT_RESOURCES)}
                  style={{ width: 15 }}
                />
              </h5>
              <div className="columns is-multiline is-gapless is-size-6 contact-info has-text-weight-light">
                <div className="column is-narrow">
                  <a href={metabaseLink} target="_blank" rel="noopener noreferrer">
                    {'Metabase'}
                  </a>
                  {slack && (
                    <div>
                      Slack <span className="has-text-grey">{slack.substring(0, 1) === '#' ? slack : `#${slack}`}</span>
                    </div>
                  )}

                  {github_repo && (
                    <div>
                      <a href={github_repo} target="_blank" rel="noopener noreferrer">
                        GitLab
                      </a>
                    </div>
                  )}

                  {trello && (
                    <div>
                      <a href={trello} target="_blank" rel="noopener noreferrer">
                        Trello
                      </a>
                    </div>
                  )}

                  {google_drive && (
                    <div>
                      <a href={google_drive} target="_blank" rel="noopener noreferrer">
                        Google Drive
                      </a>
                    </div>
                  )}
                  {other_link && (
                    <div>
                      <a href={other_link} target="_blank" rel="noopener noreferrer">
                        {other_link}
                      </a>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div>
        <ProjectHours
          members={memberProfilesWithRole}
          hours={hours}
          profiles={profiles}
          rowLimit={20}
          projectId={project.id ?? 0}
        />
      </div>
      <div className="is-mobile is-multiline">
        <ProjectCommentBox project={project} profiles={profiles} />
      </div>
    </div>
  );
};

export default ProjectDetail;
