import React from 'react';
import Spinner from '../shared/Spinner';

interface IProps {
  onClose: () => void;
  onSave: () => void;
  isActive: boolean;
  children: React.ReactNode;
  title: string;
  saveText?: string;
  cancelText?: string;
  isLoading?: boolean;
}

const Modal: React.FC<IProps> = (props: IProps) => {
  const { isActive, children, title, saveText, cancelText, isLoading } = props;

  const didClickClose = () => {
    if (props.onClose) {
      props.onClose();
    }
  };

  const didClickSave = () => {
    if (props.onSave) {
      props.onSave();
    }
  };

  return (
    <div className={'modal' + (isActive ? ' is-active' : '')}>
      <div className="modal-background" />
      <div className="modal-card">
        <header className="modal-card-head">
          <p className="modal-card-title">{title}</p>
          <button className="delete" aria-label="close" onClick={didClickClose} />
        </header>
        <section className="modal-card-body">{!isLoading ? children : <Spinner />}</section>
        <footer className="modal-card-foot">
          <button className={'button is-black' + (isLoading ? ' is-loading' : '')} onClick={didClickSave}>
            {saveText || 'Lagre'}
          </button>
          <button className="button" onClick={didClickClose}>
            {cancelText || 'Avbryt'}
          </button>
        </footer>
      </div>
    </div>
  );
};

export default Modal;
