import { IClient, IClientProject } from '@jrc/jrc-butler-api/butler';
import React, { useCallback, useEffect, useState } from 'react';
import { prettyDate, prettyPhoneNumber } from '../../utils/helpers';

import ClientCommentBox from '../../components/klienter/ClientCommentBox';
import ColumnHeader from '../../components/shared/ColumnHeader';
import ContactForm from '../../components/shared/ContactForm';
import ContactFormUpdateData from '../../types/ContactForm';
import { CurrentUser } from '../../types/CurrentUser';
import DescriptionForm from '../../components/shared/DescriptionForm';
import EditIcon from '../../static/edit_icon.svg';
import { IUser } from '@jrc/jrc-butler-api/butler/user';
import IdUrlParams from '../../types/IdUrlParams';
import JrCContactForm from '../../components/klienter/JrCContactForm';
import MODAL_TYPES from '../../components/klienter/modalTypes';
import MemberList from '../../components/shared/MemberList';
import NameForm from '../../components/shared/NameForm';
import Picture from '../../components/ansatte/Picture';
import PictureForm from '../../components/ansatte/PictureForm';
import globalSaveForm from '../../components/klienter/saveForm';
import isEqual from 'lodash/isEqual';
import loadData from '../../components/klienter/loadData';
import { useButler } from '../../utils/butlerContext';
import { useParams, match, Link } from 'react-router-dom';

interface Props {
  currentUser: CurrentUser;
  match: match<{ id: string }>;
  title: string;
  setIsLoading: (isLoading: boolean) => void;
}

const ClientDetail: React.FC<Props> = ({ currentUser, title, setIsLoading }) => {
  const context = useButler();
  const [isSaving, setIsSaving] = useState(false);
  const [client, setClient] = useState<IClient>();
  const [profiles, setProfiles] = useState(new Array<IUser>());
  const [currentModal, setCurrentModal] = useState('');
  const [JrCContacts, setJrCContacts] = useState<Array<IUser>>();
  const [projectsWithManager, setProjectsWithManager] = useState<Array<IClientProject>>();

  const { id: matchId } = useParams<IdUrlParams>();

  const callLoadData = useCallback(async () => {
    if (!context.currentUser) return;
    const { client, profiles } = await loadData(Number(matchId), context.currentUser, context);
    setProfiles(profiles);
    setClient(client);
  }, [matchId, context]);

  useEffect(() => {
    document.title = `JrC - ${title}`;
    setIsLoading(true);
    callLoadData();
    setIsLoading(false);
  }, [callLoadData, setIsLoading, title]);

  useEffect(() => {
    if (!client?.members) return;
    const contacts: IUser[] = [];
    for (const member of client?.members) {
      const profile = profiles.find(profile => member.auth_id?.toString() === profile.auth_id?.toString());
      if (profile) contacts.push(profile);
    }
    setJrCContacts(contacts);
  }, [client, profiles]);

  useEffect(() => {
    setProjectsWithManager(
      client?.projects?.map(project => {
        const manager = project.projectMembers?.find(m => m.role === 'PL');
        if (!manager) return project;
        return {
          ...project,
          projectManager: manager.name
        };
      })
    );
  }, [client]);

  const saveForm = async (updatedData: ContactFormUpdateData) => {
    setIsSaving(true);
    if (!client) return;
    const oldClient = client;
    const res = await globalSaveForm(
      updatedData,
      currentModal as MODAL_TYPES,
      client,
      Number(matchId),
      currentUser,
      context
    );
    setCurrentModal('');
    setIsSaving(false);
    if (!res) throw new Error('Res was undefined');
    if (!res.client.id) throw new Error('ClientID was undefined');
    setClient(res.client as IClient);
    setProfiles(profiles);

    if (!isEqual(res.client, oldClient)) {
      let updatedPart = getUpdatedPart(updatedData);
      let actionText = `${currentUser.profile.first_name} oppdaterte ${updatedPart} klienten ${res.client.name}`;
      await createEvent(actionText, res.client.id);
    }
  };

  const getUpdatedPart = (updatedData: ContactFormUpdateData) => {
    let updatedPart = '';

    if (updatedData.members) {
      updatedPart += ' JrC-kontaktene til ';
    } else if (updatedData.name) {
      updatedPart += ' kontaktinformasjon til ';
    } else if (updatedData.description) {
      updatedPart += ' beskrivelsen til ';
    }
    return updatedPart;
  };

  const createEvent = async (actionText: string, entity_id: number) => {
    try {
      const event = {
        type: 'client',
        title: actionText,
        description: '',
        entity_id: entity_id,
        change_by: currentUser.profile.auth_id || '',
        image: currentUser.profile.image || '',
        icon: 'pencil',
        date: new Date().toISOString()
      };
      await context.eventService.createEvent(event);
    } catch (error) {
      console.log('Error posting event: ' + error);
    }
  };

  const closeModal = () => {
    setCurrentModal('');
  };
  if (!client?.projects || !client?.members) return null;

  return (
    <div>
      <NameForm
        isActive={currentModal === MODAL_TYPES.NAME}
        isLoading={isSaving}
        onClose={closeModal}
        onSave={saveForm}
        name={client.name}
        placeholder="Kundenavn"
      />

      <DescriptionForm
        title={'Beskrivelse'}
        placeholder={'Fyll inn beskrivelse'}
        isActive={currentModal === MODAL_TYPES.DESCRIPTION}
        isLoading={isSaving}
        onClose={closeModal}
        onSave={saveForm}
        description={client.description || ''}
      />

      <JrCContactForm
        isActive={currentModal === MODAL_TYPES.JRC_CONTACTS}
        isLoading={isSaving}
        onClose={closeModal}
        onSave={saveForm}
        members={JrCContacts || []}
      />

      <ContactForm
        isActive={currentModal === MODAL_TYPES.CLIENT_CONTACT}
        isLoading={isSaving}
        onClose={closeModal}
        onSave={saveForm}
        name={client.contact_name}
        role={client.contact_role}
        phone={client.contact_number}
        email={client.contact_email}
      />
      <PictureForm
        isLoading={isSaving}
        isActive={currentModal === MODAL_TYPES.IMAGE}
        onClose={closeModal}
        onSave={saveForm}
      />
      <div className="columns is-multiline">
        <div className="column is-full p-b-0">
          <ColumnHeader title={client.name} onClick={() => setCurrentModal(MODAL_TYPES.NAME)} border />
        </div>
        <div className="column is-full p-t-0">
          <div className="columns is-flex-mobile flex-column-mobile">
            <div className="column is-7 p-t-0">
              <h2 className="is-size-5">
                Beskrivelse
                <img
                  src={EditIcon}
                  alt="Edit"
                  style={{ width: 15 }}
                  onClick={() => setCurrentModal(MODAL_TYPES.DESCRIPTION)}
                />
              </h2>
              <div className="m-t-5 has-text-weight-light">
                {client.description || <span className="has-text-grey-light">Legg til en beskrivelse</span>}
              </div>

              <h2 className="is-size-5 is-5-tablet m-t-50">
                JrC Contacts
                <img
                  src={EditIcon}
                  alt="Edit"
                  onClick={() => setCurrentModal(MODAL_TYPES.JRC_CONTACTS)}
                  style={{ width: 15 }}
                />
              </h2>
              <div className="columns is-mobile is-multiline">
                <MemberList members={JrCContacts} />
              </div>

              <h2 className="is-size-5 m-t-50">Prosjekter</h2>
              <table className="table is-fullwidth">
                <thead>
                  <tr>
                    <th>Navn</th>
                    <th>Prosjektleder</th>
                    <th className="is-hidden-mobile">Start dato</th>
                    <th className="is-hidden-mobile">Status</th>
                  </tr>
                </thead>
                {/* TODO: FIX PROJECT TYPE TO INCLUDE PROJECTMANAGER AND STATUS */}
                <tbody>
                  {projectsWithManager?.map(project => (
                    <Link className="table-row" key={String(project.id)} to={`/prosjekter/${project.id}`}>
                      <td>{project.name}</td>
                      <td>{(project as any).projectManager || 'Ikke satt'}</td>
                      <td className="is-hidden-mobile">{prettyDate(new Date(project.start_date || 0))}</td>
                      <td className="is-hidden-mobile">{(project as any).status}</td>
                    </Link>
                  ))}
                </tbody>
              </table>

              <div className="is-mobile is-multiline">
                <ClientCommentBox client={client} />
              </div>
            </div>

            <div className="column is-offset-1 is-narrow p-t-0 first-order-mobile">
              <div className="has-text-centered-mobile">
                <Picture canEdit={true} imageUrl={client.image} onClick={() => setCurrentModal(MODAL_TYPES.IMAGE)} />
              </div>
              <h5 className="is-size-5">
                Kontaktperson
                <img
                  src={EditIcon}
                  alt="Edit"
                  onClick={() => setCurrentModal(MODAL_TYPES.CLIENT_CONTACT)}
                  style={{ width: 15 }}
                />
              </h5>

              <div className="columns is-multiline is-gapless is-size-6 contact-info has-text-weight-light">
                <div className="column is-half">Navn</div>
                <div className="column is-narrow has-text-grey">{client.contact_name}</div>
                <div className="column is-half">Rolle</div>
                <div className="column is-narrow has-text-grey">{client.contact_role}</div>
                <div className="column is-half">Telefon</div>
                <div className="column is-narrow has-text-grey">{prettyPhoneNumber(client.contact_number)}</div>
                <div className="column is-full">E-post</div>
                <div className="column is-half has-text-grey">{client.contact_email}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ClientDetail;
