import React, { useState } from 'react';
import { useAuth0 } from '../../utils/authContext';
import { IUser, JrcTitles } from '@jrc/jrc-butler-api/butler';
import { useButler } from '../../utils/butlerContext';

const ProfileInfoForm: React.FC = () => {
  const [firstName, setFirstName] = useState<string>('');
  const [lastName, setLastName] = useState<string>('');
  const [pendingRegister, setPendingRegister] = useState(false);
  const { user, apiUser } = useAuth0();
  const { profileService } = useButler();

  let buttonClass = 'button is-primary';

  const formIsValid = (firstName: string, lastName: string) => {
    return firstName && lastName && firstName.length > 2 && lastName.length > 2;
  };

  const createProfile = async () => {
    setPendingRegister(true);

    const email = user?.email;
    const auth_id = apiUser?.sub?.replace('auth0|', '');

    if (email && auth_id) {
      const profile: IUser = {
        first_name: firstName,
        last_name: lastName,
        email: user!.email!,
        auth_id: auth_id,
        title: JrcTitles.CONSULTANT,
        active: true
      };

      try {
        await profileService.createUser(profile);
        window.location.href = '/';
      } catch (error) {
        // TODO: Fix potential error messages
      }
    }
  };

  if (pendingRegister) {
    buttonClass += 'is-loading';
  }

  return (
    <div style={{ maxWidth: 360 }}>
      <div>
        <div className="is-size-4 m-t-20">Velkommen til Junior Consulting!</div>
        <div className="m-t-5 has-text-weight-light is-size-6">
          <p>For å gå videre til InternArena trenger vi å vite hvem du er!</p>
        </div>
      </div>
      <div className="m-t-20">
        <input
          className="login-input"
          value={firstName}
          onChange={event => setFirstName(event.target.value)}
          placeholder="Fornavn"
        />
      </div>
      <div className="m-t-20">
        <input
          className="login-input"
          placeholder="Etternavn"
          value={lastName}
          onChange={event => setLastName(event.target.value)}
        />
      </div>

      <div className="m-t-20 has-text-right">
        <button className={buttonClass} disabled={!formIsValid(firstName, lastName)} onClick={() => createProfile()}>
          Ferdig
        </button>
      </div>
    </div>
  );
};

export default ProfileInfoForm;
