import React, { useCallback, useEffect, useState } from 'react';

import { IUser } from '@jrc/jrc-butler-api/butler';
import JrCContactList from './JrCContactList';
import MemberSelect from './../shared/MemberSelect';
import Modal from '../shared/Modal';
import differenceBy from 'lodash/differenceBy';
import { useButler } from '../../utils/butlerContext';

interface Props {
  members: IUser[];
  onSave: (argument: any) => void;
  isActive: boolean;
  onClose: () => void;
  isLoading: boolean;
}

const JrCContactFrom: React.FC<Props> = ({ members, onSave, isActive, onClose, isLoading }: Props) => {
  const [selectedContacts, setSelectedContacts] = useState(members);
  const [notSelectedContacts, setNotSelectedContacts] = useState<IUser[]>();
  const [profiles, setProfiles] = useState<IUser[]>();
  const context = useButler();

  const fetchUsers = useCallback(async () => {
    const profiles = await context.profileService.getUsers();
    setProfiles(profiles);
  }, [context.profileService]);

  useEffect(() => {
    fetchUsers();
  }, [fetchUsers]);

  useEffect(() => {
    const notSelectedContacts = differenceBy(profiles, selectedContacts, 'auth_id');
    setNotSelectedContacts(notSelectedContacts);
  }, [profiles, selectedContacts]);

  const didClickSave = () => {
    onSave({ members: selectedContacts });
  };

  const addContact = (memberId: string) => {
    const newContact = profiles?.find(profile => profile.auth_id === memberId);
    if (!newContact) return;
    setSelectedContacts([...selectedContacts, newContact]);
  };

  const removeContact = (memberId: string | null | undefined) => {
    setSelectedContacts(selectedContacts.filter(member => member.auth_id !== memberId));
  };

  return (
    <Modal title="JrC Contacts" isActive={isActive} onClose={onClose} onSave={didClickSave} isLoading={isLoading}>
      <MemberSelect addMember={addContact} notSelectedMembers={notSelectedContacts} />
      <JrCContactList selectedContacts={selectedContacts} removeContact={removeContact} />
    </Modal>
  );
};

export default JrCContactFrom;
