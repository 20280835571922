import React, { useState, useEffect, useCallback } from 'react';
import Modal from '../shared/Modal';
import MaskedInput from 'react-text-mask';
import { makeFormOptions } from '../../utils/helpers';
import SURVEY_ANSWERS from './customerSurveyAnswers';
import DatePicker from 'react-datepicker';
import nb from 'date-fns/locale/nb';
import { useButler } from '../../utils/butlerContext';
import { IClientInList, SurveyAnswer } from '@jrc/jrc-butler-api/butler';

interface IOnSave {
  start_date: string;
  end_date: string;
  clientId?: number;
  is_upselling: boolean;
  customer_survey?: SurveyAnswer | null;
  is_private: boolean;
}

interface IProps {
  start_date: string;
  end_date: string;
  is_upselling: boolean;
  is_private?: boolean;
  clientId?: number | null;
  customer_survey?: SurveyAnswer | null;
  onSave: (ob: IOnSave) => void;
  isActive: boolean;
  isLoading: boolean;
  onClose: () => void;
}

const ProjectInfoForm: React.FC<IProps> = (props: IProps) => {
  const butlerContext = useButler();
  const { isLoading, isActive, onClose } = props;

  const [clients, setClients] = useState<IClientInList[]>([]);
  const [isLoadingClients, setIsLoadingClients] = useState<boolean>(true);
  const [startDate, setStartDate] = useState<string>(props.start_date);
  const [endDate, setEndDate] = useState<string>(props.end_date);
  const [clientId, setClientId] = useState<number | null | undefined>(props.clientId);
  const [isUpselling, setIsUpselling] = useState<boolean>(props.is_upselling);
  const [customerSurvey, setCustomerSurvey] = useState<SurveyAnswer | null | undefined>(props.customer_survey);
  const [isPrivate, setIsPrivate] = useState<boolean>(props.is_private || false);

  const getClients = useCallback(async () => {
    const clients = await butlerContext.clientService.getClients();
    setClients(clients);
    setIsLoadingClients(false);
  }, [butlerContext, setClients, setIsLoadingClients]);

  useEffect(() => {
    getClients();
  }, [getClients]);

  const didClickSave = (): void => {
    props.onSave({
      start_date: startDate,
      end_date: endDate,
      clientId: clientId || undefined,
      is_upselling: isUpselling,
      customer_survey: customerSurvey,
      is_private: isPrivate
    });
  };

  return (
    <Modal
      title="Prosjektinformasjon"
      onSave={didClickSave}
      isLoading={isLoading}
      isActive={isActive}
      onClose={onClose}
    >
      <div className="field">
        <label className="label">Kunde</label>
        <div className="control">
          <div className={`select ${isLoadingClients ? ' is-loading' : ''}`}>
            <select value={clientId || 0} onChange={e => setClientId(parseInt(e.target.value))}>
              <option disabled value={0}>
                Velg klient
              </option>
              {clients.map(client => (
                <option value={client.id ?? 0} key={client.id ?? 0}>
                  {client.name}
                </option>
              ))}
            </select>
          </div>
        </div>
      </div>

      <div className="field">
        <div className="control">
          <label className="label">Start dato</label>
          <DatePicker
            className="input"
            locale={nb}
            selected={startDate ? new Date(startDate) : null}
            onChange={(date: Date) => setStartDate(date ? date.toISOString() : '')}
            placeholderText="dag/måned/år"
            dateFormat="dd/MM/yyyy"
            customInput={<MaskedInput mask={[/\d/, /\d/, '/', /\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/]} />}
          />
        </div>
      </div>

      <div className="field">
        <label className="label">Slutt dato</label>
        <div className="control">
          <DatePicker
            className="input"
            locale={nb}
            selected={endDate ? new Date(endDate) : null}
            onChange={(date: Date) => setEndDate(date ? date.toISOString() : '')}
            placeholderText="dag/måned/år"
            dateFormat="dd/MM/yyyy"
            customInput={<MaskedInput mask={[/\d/, /\d/, '/', /\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/]} />}
          />
        </div>
      </div>

      <div>
        <label className="label">Mersalg</label>
        <input
          className="tooltip is-tooltip-right"
          defaultChecked={isUpselling}
          onChange={event => setIsUpselling(!isUpselling)}
          type="checkbox"
          name="upselling"
          data-tooltip="Trenger du virkelig forklaring?"
        />
      </div>
      <div className="field">
        <label className="label">Kundeundersøkelse</label>
        <div className="control">
          <div className={`select ${isLoadingClients ? ' is-loading' : ''}`}>
            <select value={customerSurvey || 0} onChange={e => setCustomerSurvey(e.target.value as SurveyAnswer)}>
              <option disabled value={0}>
                Er kundeundersøkelsen besvart?
              </option>
              {makeFormOptions(SURVEY_ANSWERS)}
            </select>
          </div>
        </div>
      </div>
      <div>
        <label className="label">Prosjektet er privat</label>
        <input
          className="tooltip is-tooltip-right"
          defaultChecked={isPrivate}
          onChange={() => setIsPrivate(!isPrivate)}
          type="checkbox"
          name="private"
          data-tooltip="Kryss av hvis prosjektet er privat"
        />
      </div>
    </Modal>
  );
};

export default ProjectInfoForm;
