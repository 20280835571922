import React, { useCallback, useEffect, useState } from 'react';
import ColumnHeader from 'components/shared/ColumnHeader';
import EditIcon from 'static/edit_icon.svg';
import AboutMeForm from 'components/ansatte/AboutMeForm';
import ContactInfoForm from 'components/ansatte/ContactInfoForm';
import { prettyPhoneNumber, toFirstLetterLowerCase, prettyDate } from 'utils/helpers';
import Picture from 'components/ansatte/Picture';
import PictureForm from 'components/ansatte/PictureForm';
import loadData from 'components/ansatte/loadData';
import globalSaveForm from 'components/ansatte/saveForm';
import MODAL_TYPES from 'components/ansatte/modalTypes';
import STUDY_PROGRAMS, { StudyTypes } from 'components/ansatte/studyPrograms';
import PROJECT_GROUPS, { ProjectGroupTypes } from 'components/ansatte/projectGroups';
import Sticky from 'react-stickynode';
import { useButler } from 'utils/butlerContext';
import isEqual from 'lodash/isEqual';
import { IEvent, IProjectInList, IUser } from '@jrc/jrc-butler-api/butler';
import { CurrentUser } from 'types/CurrentUser';
import { Link, useParams } from 'react-router-dom';
import IdUrlParams from 'types/IdUrlParams';
import { IUpdatedData } from 'types/updatedData';

interface EmployeeDetailProps {
  setIsLoading: (value: boolean) => void;
  isLoading: boolean;
  currentUser: CurrentUser;
  title: string;
}

interface IModalProps {
  onClose: () => void;
  onSave: (updatedData: IUpdatedData) => Promise<void>;
  isSaving: boolean;
}

const EmployeeDetail: React.FC<EmployeeDetailProps> = ({ currentUser, setIsLoading, isLoading, title }) => {
  const { id: matchId } = useParams<IdUrlParams>();
  const butlerContext = useButler();
  const [isSaving, setIsSaving] = useState(false);
  const [currentModal, setCurrentModal] = useState<string | null>(null);
  const [profile, setProfile] = useState<IUser>();
  const [projects, setProjects] = useState<IProjectInList[] | undefined>([]);

  const populateState = useCallback(async () => {
    setIsLoading(true);
    const data = await loadData(matchId, butlerContext);
    setProfile(data?.profile);
    setProjects(data?.projects);
    setIsLoading(false);
  }, [butlerContext, matchId, setIsLoading]);

  useEffect(() => {
    document.title = `JrC - ${title}`;
    populateState();
  }, [matchId, populateState, title]);

  const closeModal = () => {
    setCurrentModal(null);
  };

  const showModal = (type: MODAL_TYPES) => {
    setCurrentModal(type);
  };

  const saveForm = async (updatedData: IUpdatedData) => {
    console.log('SaveForm:', updatedData);

    setIsSaving(true);
    const isCurrentUser = currentUser.profile.auth_id?.toString() === matchId.toString();
    const isPartnerEdit = !isCurrentUser && ['PARTNER', 'CEO'].includes(currentUser.profile.title!);
    const updatedProfile = await globalSaveForm(
      updatedData,
      matchId,
      currentModal,
      butlerContext,
      isPartnerEdit,
      profile!
    );

    if (isCurrentUser && !isEqual(updatedProfile, profile)) {
      // TODO: Change entity_id to be a string and not a number
      const event: IEvent = {
        type: 'employee',
        title: `${profile?.first_name} oppdaterte profilen sin.`,
        description: '',
        entity_id: parseInt(profile?.auth_id!),
        change_by: profile?.auth_id || '',
        image: profile?.image || '',
        icon: 'pencil',
        date: new Date().toISOString()
      };
      butlerContext.eventService.createEvent(event);
    }

    if (updatedProfile) {
      setProfile(updatedProfile);
      setIsSaving(false);
      setCurrentModal(null);
    }
  };

  const getStatusText = (profile: IUser) => {
    if (profile.active) {
      return profile.exchange ? 'Aktiv (på utveksling)' : 'Aktiv';
    } else {
      return 'Alumni';
    }
  };

  if (isLoading || !profile) return null;
  const {
    bio_text,
    fun_fact,
    email,
    phone_nr,
    address,
    active,
    linkedin,
    exchange,
    study_program,
    project_group,
    alumni_company,
    alumni_title,
    quote
  } = profile!;

  const statusText = getStatusText(profile);

  const modalProps: IModalProps = {
    onClose: closeModal,
    onSave: saveForm,
    isSaving
  };

  const isCurrentUser = currentUser.profile.auth_id!.toString() === matchId.toString();
  const isPartner = ['PARTNER', 'CEO'].includes(currentUser.profile.title!);
  const canEdit = isPartner || isCurrentUser;

  return (
    <div>
      {canEdit && (
        <div>
          <AboutMeForm isActive={currentModal === MODAL_TYPES.ABOUT_ME} {...modalProps} about={bio_text} />
          <AboutMeForm
            isActive={currentModal === MODAL_TYPES.FUN_FACT}
            about={fun_fact}
            placeholder="En liten fun fact om deg som vises på juniorconsulting.no"
            {...modalProps}
          />
          <AboutMeForm
            isActive={currentModal === MODAL_TYPES.QUOTE}
            about={quote}
            placeholder="En liten quote fra deg som vises på juniorconsulting.no"
            {...modalProps}
          />
          <ContactInfoForm
            isActive={currentModal === MODAL_TYPES.CONTACT_INFO}
            editorIsPartner={isPartner}
            isLoading={isLoading}
            userInfo={{
              title: profile.title,
              email,
              phone_nr,
              active,
              address,
              linkedin,
              exchange,
              study_program: study_program as string,
              project_group: project_group as string,
              alumni_company,
              alumni_title
            }}
            {...modalProps}
          />
          <PictureForm isActive={currentModal === MODAL_TYPES.IMAGE} {...modalProps} />
        </div>
      )}

      <div className="columns is-multiline">
        <div className="column is-full p-b-0">
          <Sticky>
            <div className="background-white">
              <ColumnHeader title={`${profile.first_name} ${profile.last_name}`} border />
            </div>
          </Sticky>
        </div>
        <div className="column is-full p-t-0">
          <div className="columns is-flex-mobile flex-column-mobile">
            <div className="column is-7 p-t-0">
              <h2 className="is-size-5">
                Om meg{' '}
                {canEdit && (
                  <img
                    src={EditIcon}
                    alt="EditIcon"
                    onClick={() => showModal(MODAL_TYPES.ABOUT_ME)}
                    style={{ width: 15 }}
                  />
                )}
              </h2>
              <div className="m-t-5 has-text-weight-light">{profile.bio_text}</div>

              <h2 className="is-size-5 m-t-30">
                Fun fact{' '}
                {canEdit && (
                  <img
                    src={EditIcon}
                    alt="EditIcon"
                    onClick={() => showModal(MODAL_TYPES.FUN_FACT)}
                    style={{ width: 15 }}
                  />
                )}
              </h2>

              <div className="m-t-5 has-text-weight-light">{profile.fun_fact}</div>

              {(profile.title === 'PARTNER' || profile.title === 'CEO') && (
                <div>
                  <h2 className="is-size-5 m-t-30">
                    Quote{' '}
                    {canEdit && (
                      <img
                        src={EditIcon}
                        alt="EditIcon"
                        onClick={() => showModal(MODAL_TYPES.QUOTE)}
                        style={{ width: 15 }}
                      />
                    )}
                  </h2>

                  <div className="m-t-5 has-text-weight-light">{profile.quote}</div>
                </div>
              )}

              {projects && (
                <div>
                  <h2 className="is-size-5 m-t-30">Prosjekter</h2>
                  <table className="table is-fullwidth">
                    <thead>
                      <tr>
                        <th>Navn</th>
                        <th>Rolle</th>
                        <th className="is-hidden-mobile">Start dato</th>
                        <th className="is-hidden-mobile">Type</th>
                      </tr>
                    </thead>
                    <tbody>
                      {projects.map((project: IProjectInList) => (
                        <Link className="table-row" key={project.id!} to={`/prosjekter/${project.id}`}>
                          <td>{project.name}</td>
                          <td>{project?.projectMembers?.find(member => member.member_id === profile.auth_id)?.role}</td>
                          <td className="is-hidden-mobile">{prettyDate(new Date(project.start_date!))}</td>
                          <td className="is-hidden-mobile">{toFirstLetterLowerCase(project.type)}</td>
                        </Link>
                      ))}
                    </tbody>
                  </table>
                </div>
              )}
            </div>

            <div className="column is-offset-1 is-narrow first-order-mobile">
              <div className="has-text-centered-mobile">
                <Picture
                  canEdit={canEdit}
                  imageUrl={profile.image}
                  onClick={() => setCurrentModal(MODAL_TYPES.IMAGE)}
                />
              </div>

              <h5 className="is-size-5 m-t-20">
                Kontaktinformasjon{' '}
                {canEdit && (
                  <img
                    src={EditIcon}
                    alt="EditIcon"
                    onClick={() => showModal(MODAL_TYPES.CONTACT_INFO)}
                    style={{ width: 15 }}
                  />
                )}
              </h5>

              <div className="columns is-multiline is-gapless contact-info has-text-weight-light">
                <div className="column is-half">Status</div>
                <div className="column is-narrow has-text-grey">{statusText}</div>
                <div className="column is-half">Studieprogram</div>
                <div className="column is-narrow has-text-grey">
                  {STUDY_PROGRAMS[profile.study_program as StudyTypes]}
                </div>
                <div className="column is-half">Prosjektgruppe</div>
                <div className="column is-narrow has-text-grey">
                  {PROJECT_GROUPS[profile.project_group as ProjectGroupTypes]}
                </div>
                <div className="column is-half">Telefon</div>
                <div className="column is-narrow has-text-grey">{prettyPhoneNumber(profile.phone_nr)}</div>
                <div className="column is-half">LinkedIn</div>
                <div className="column is-narrow has-text-grey">
                  <a href={profile.linkedin!} target="_blank" rel="noopener noreferrer">
                    {profile.linkedin && profile.linkedin.slice(profile.linkedin.lastIndexOf('/') + 1)}
                  </a>
                </div>
                <div className="column is-full m-t-5">E-post</div>
                <div className="column is-full has-text-grey">{profile.email}</div>
                <div className="column is-half">Adresse</div>
                <div className="column is-narrow has-text-grey">{profile.address}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EmployeeDetail;
