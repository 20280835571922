export type StudyTypes =
  | 'NONE'
  | 'MTDT'
  | 'MTKOM'
  | 'MTIOT'
  | 'MTDESIG'
  | 'BIT'
  | 'MTMART'
  | 'MTENERG'
  | 'MTBYGG'
  | 'MTTK'
  | 'CPSY6';

enum STUDY_PROGRAMS {
  // These fields are the same as the ones in profilesService in jrc-butler
  // If changes are made here, you must also do the same changes in the model.
  NONE = 'Ikke satt',
  MTDT = 'Datateknologi',
  MTKOM = 'Kommunikasjonsteknologi',
  MTIOT = 'Industriell økonomi og teknologiledelse',
  MTDESIG = 'Industriell design',
  BIT = 'Informatikk',
  MTMART = 'Marin teknikk',
  MTENERG = 'Energi og miljø',
  MTBYGG = 'Bygg- og miljøteknikk',
  MTTK = 'Kybernetikk og robotikk',
  CPSY6 = 'Profesjonsstudiet i psykologi'
}

export default STUDY_PROGRAMS;
