import { IProject } from '@jrc/jrc-butler-api/butler';
import { IProjectWithManager } from '../../types/ProjectWithManager';

const PROJECT_STATES = {
  ALL: 'Alle',
  ON_GOING: 'Pågående',
  DELIVERED: 'Levert',
  NOT_STARTED: 'Ikke startet',
  UNKNOWN: 'Ikke satt',
  MINE: 'Mine prosjekter',
  ALL_PROJECTS: 'Alle prosjekter',
  PRIVATE_PROJECTS: 'Private prosjekter',
  PUBLIC_PROJECTS: 'Offentlige prosjekter'
};

const filters = [
  { id: PROJECT_STATES.ON_GOING, title: 'Pågående' },
  { id: PROJECT_STATES.ALL, title: 'Alle' },
  {
    id: PROJECT_STATES.DELIVERED,
    title: 'Levert'
  },
  { id: PROJECT_STATES.NOT_STARTED, title: 'Ikke startet' },
  { id: PROJECT_STATES.UNKNOWN, title: 'Ikke satt' },
  { id: PROJECT_STATES.MINE, title: 'Mine prosjekter' }
];

const projectVisbility = [
  { id: PROJECT_STATES.ALL_PROJECTS, title: 'Alle prosjekter' },
  { id: PROJECT_STATES.PRIVATE_PROJECTS, title: 'Private prosjekter' },
  { id: PROJECT_STATES.PUBLIC_PROJECTS, title: 'Offentlige prosjekter' }
];

const getStatus = (project: IProject): string => {
  let { start_date, end_date } = project;
  if (!start_date) return PROJECT_STATES.UNKNOWN;
  const start_date_date: Date = new Date(start_date || 0);
  const end_date_date: Date = new Date(end_date || 0);
  const now: Date = new Date();

  if (start_date_date > now) {
    return PROJECT_STATES.NOT_STARTED;
  }

  if (end_date && end_date_date < now) {
    return PROJECT_STATES.DELIVERED;
  }

  if (start_date_date < now) {
    return PROJECT_STATES.ON_GOING;
  }
  return PROJECT_STATES.UNKNOWN;
};

const getFilteredProjects = (
  projects: Array<IProjectWithManager>,
  filter: string,
  currentUserAuthId: string,
  searchText: string,
  accessFilter: string
) => {
  let filteredProjects = projects.sort((a, b) => (a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1));
  filteredProjects = filterByAccessFilter(filteredProjects, accessFilter);
  if (searchText) {
    filteredProjects = filteredProjects.filter(
      project =>
        project.name.toLowerCase().includes(searchText.toLowerCase()) ||
        (project.clientName && project.clientName.toLowerCase().includes(searchText.toLowerCase()))
    );
  }
  if (filter !== PROJECT_STATES.ALL) {
    if (filter === PROJECT_STATES.MINE) {
      filteredProjects = filteredProjects.filter(project =>
        project.projectMembers ? project.projectMembers.find(member => member.member_id === currentUserAuthId) : []
      );
    } else {
      filteredProjects = filteredProjects.filter(project => getStatus(project) === filter);
    }
  }
  return filteredProjects;
};

const filterByAccessFilter = (filteredProjects: Array<IProjectWithManager>, accessFilter: string) => {
  if (accessFilter !== PROJECT_STATES.ALL_PROJECTS) {
    if (accessFilter === PROJECT_STATES.PRIVATE_PROJECTS) {
      filteredProjects = filteredProjects.filter(project => project.is_private);
      return filteredProjects;
    }
    if (accessFilter === PROJECT_STATES.PUBLIC_PROJECTS) {
      filteredProjects = filteredProjects.filter(project => !project.is_private);
      return filteredProjects;
    }
    return [];
  } else {
    return filteredProjects;
  }
};

export { PROJECT_STATES, filters, projectVisbility, getStatus, getFilteredProjects };
