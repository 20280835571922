interface IModalTypes {
  NAME: string;
  DESCRIPTION: string;
  PROJECT_INFO: string;
  PROJECT_RESOURCES: string;
  PROJECT_CONTACT: string;
  TEAM_MEMBERS: string;
  INVOICE_NUMBER: string;
  SALES_DESCRIPTION: string;
  DOWNLOAD_HOURS: string;
}

const MODAL_TYPES: IModalTypes = {
  NAME: 'NAME',
  DESCRIPTION: 'DESCRIPTION',
  PROJECT_INFO: 'PROJECT_INFO',
  PROJECT_RESOURCES: 'PROJECT_RESOURCES',
  PROJECT_CONTACT: 'PROJECT_CONTACT',
  TEAM_MEMBERS: 'TEAM_MEMBERS',
  INVOICE_NUMBER: 'INVOICE_NUMBER',
  SALES_DESCRIPTION: 'SALES_DESCRIPTION',
  DOWNLOAD_HOURS: 'DOWNLOAD_HOURS'
};

export default MODAL_TYPES;
