import { ButlerContextProps } from '../../utils/butlerContext';

const loadData = async (projectId: number, butlerContext: ButlerContextProps) => {
  const projectRequest = butlerContext.projectService.getProject(projectId);
  const profileRequest = butlerContext.profileService.getUsers();
  const hourRequest = butlerContext.timeService.hoursPerProject(projectId);

  const [project, profiles, hours] = await Promise.all([projectRequest, profileRequest, hourRequest]);
  return { project, profiles, hours };
};

export default loadData;
