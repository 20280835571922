import {
  IClientComment,
  IClientCommentEntity,
  IProjectComment,
  IProjectCommentEntity,
  IUser
} from '@jrc/jrc-butler-api/butler';
import { prettyDate, prettyTime } from '../../utils/helpers';

import Comment from './Comment';
import React from 'react';
import { useButler } from '../../utils/butlerContext';

interface IProps {
  onDelete: (commentId: number) => void;
  onUpdate: (updatedComment: IClientCommentEntity | IProjectCommentEntity) => Promise<void>;
  comments: IProjectComment[] | IClientComment[];
  profiles: IUser[];
  isLoading: boolean;
}

const CommentList: React.FC<IProps> = ({ comments, profiles, isLoading, onDelete, onUpdate }) => {
  const butlerContext = useButler();

  const handleDeleteComment = (commentId: number) => {
    onDelete(commentId);
  };

  const handleUpdateComment = (editedComment: string, commentId: number) => {
    onUpdate({ text: editedComment, id: commentId, project_id: 0 });
  };

  const commentHasBeenUpdated = (comment: IProjectComment | IProjectComment) => {
    const updatedTime = new Date(comment.updated ?? '').getTime();
    const createdTime = new Date(comment.created ?? '').getTime();
    // Avoid false positives. Comment is updated after being populated on creation in backend
    const numberOfMilisecondBasesToIgnore = 2;
    if (
      updatedTime.toString().slice(0, -numberOfMilisecondBasesToIgnore) !==
      createdTime.toString().slice(0, -numberOfMilisecondBasesToIgnore)
    ) {
      return prettyDate(new Date(comment.updated ?? '')) + ' ' + prettyTime(new Date(comment.updated ?? ''));
    } else {
      return false;
    }
  };

  const renderComment = (comment: IProjectComment | IClientComment) => {
    const { currentUser } = butlerContext;
    const profile = comment.author_id && profiles.find(profile => profile.auth_id === comment.author_id);
    const created = prettyDate(new Date(comment.created ?? '')) + ' ' + prettyTime(new Date(comment.created ?? ''));
    const updated = commentHasBeenUpdated(comment);
    const allowDelete = currentUser && profile && currentUser.profile.auth_id === profile.auth_id;

    return (
      <Comment
        key={comment.id ?? 0}
        profile={profile as IUser}
        text={comment.text}
        created={created}
        updated={updated}
        onDelete={() => handleDeleteComment(comment.id ?? 0)}
        onSave={(editedComment: string) => handleUpdateComment(editedComment, comment.id ?? 0)}
        allowDelete={allowDelete as boolean}
      />
    );
  };

  if (isLoading && comments) {
    return (
      <div className="CommentList small-profile-image">
        {(comments as any[]).map(comment => renderComment(comment))}
        <button className="button loading-button is-loading">&nbsp;</button>
      </div>
    );
  } else if (isLoading) {
    return (
      <div className="CommentList">
        <button className="button loading-button is-loading">Laster kommentarer</button>
      </div>
    );
  }
  return (
    <div className="CommentList small-profile-image">
      {comments ? (comments as any[]).map(comment => renderComment(comment)) : ''}
    </div>
  );
};

export default CommentList;
