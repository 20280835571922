import Alert from 'react-s-alert';
import { ButlerContextProps } from 'utils/butlerContext';

const loadData = async (employeeId: string, butlerContext: ButlerContextProps) => {
  try {
    const profileRequest = butlerContext.profileService.getUser(employeeId);
    const projectRequest = butlerContext.projectService.getProjectsForUserId(employeeId);
    const [profile, projects] = await Promise.all([profileRequest, projectRequest]);

    return {
      profile,
      projects
    };
  } catch (e) {
    Alert.error('Noe gikk galt. Kontakt noen i IT for hjelp.');
  }
};

export default loadData;
