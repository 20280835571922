import React, { useEffect, useState } from 'react';
import { library } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrashAlt, faEdit } from '@fortawesome/free-solid-svg-icons';
import { IProject, ITime } from '@jrc/jrc-butler-api/butler';
import Alert from 'react-s-alert';
import { validateIfStringIsValidNumber } from '../../pages/timeregistrering';
library.add(faTrashAlt, faEdit);

interface Props {
  projects: IProject[];
  entry: ITime;
  authId: string;
  invoiceId: string | undefined | null;
  onDelete: (entryId: number) => void;
  onUpdate: (timeEntry: ITime) => void;
}

const findProjectById = (projects: IProject[], projectId: number) => {
  return projects.find(project => project.id === projectId);
};

const TimeEntry: React.FC<Props> = ({ projects, entry, authId, invoiceId, onDelete, onUpdate }: Props) => {
  const [showEditForm, setShowEditForm] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [project, setProject] = useState<IProject | undefined>();
  const [date, setDate] = useState(entry.date);
  const [hours, setHours] = useState(String(entry.hours));
  const [description, setDescription] = useState(entry.description);
  const [tag, setTag] = useState(entry.tag);

  const toggleEdit = () => setShowEditForm(!showEditForm);

  useEffect(() => {
    setProject(findProjectById(projects, entry.projectId));
  }, [entry, projects]);

  const saveEntry = () => {
    setIsLoading(true);
    toggleEdit();
    if (!project || !project.id) {
      Alert.error('Det skjedde en feil da vi skulle oppdatere timene dine.');
      console.error('Project was empty when we tried to save entry');
      return;
    }
    onUpdate({
      projectId: project!.id,
      date,
      hours: Number(hours),
      description,
      tag,
      invoiceId: entry.invoiceId,
      authId: entry.authId,
      id: entry.id,
      paid: entry.paid
    });
    setIsLoading(false);
  };

  const deleteEntry = () => {
    if (invoiceId || entry.id === null || entry.id === undefined) return;
    onDelete(entry.id);
  };

  const handleHourInput = (value: string) => {
    if (validateIfStringIsValidNumber(value)) {
      setHours(value);
    }
  };

  if (showEditForm) {
    return (
      <tr>
        <td>
          <input className="input is-2" type="text" defaultValue={date} onChange={e => setDate(e.target.value)} />
        </td>
        <td>
          <div className="field select">
            <select
              value={project?.id || 0}
              onChange={e => setProject(findProjectById(projects, Number(e.target.value)))}
            >
              <option disabled value={0}>
                Velg prosjekt
              </option>
              {projects.map(project => (
                <option value={String(project.id)} key={String(project.id)}>
                  {project.name}
                </option>
              ))}
            </select>
          </div>
        </td>
        <td>
          <input className="input" type="text" value={hours} onChange={e => handleHourInput(e.target.value)} />
        </td>
        <td>
          <input
            className="input"
            type="text"
            defaultValue={description}
            onChange={e => setDescription(String(e.target.value))}
          />
        </td>
        <td>
          <input className="input" type="text" defaultValue={tag} onChange={e => setTag(e.target.value)} />
        </td>
        <td>
          <button
            className={'button is-small is-primary' + (isLoading ? ' is-loading' : '')}
            onClick={() => saveEntry()}
          >
            Lagre
          </button>
        </td>
      </tr>
    );
  }

  return (
    <tr>
      <td>{date.slice(0, 10)}</td>
      <td>{project && project.name}</td>
      <td>{hours}</td>
      <td>{description}</td>
      <td>{tag}</td>
      <td>
        {!invoiceId && (
          <>
            <button className="button is-small is-black m-r-5 button-icon-center" onClick={() => toggleEdit()}>
              <FontAwesomeIcon icon="edit" />
            </button>
            <button className="button is-small is-primary button-icon-center" onClick={() => deleteEntry()}>
              <FontAwesomeIcon icon="trash-alt" />
            </button>
          </>
        )}
      </td>
    </tr>
  );
};

export default TimeEntry;
