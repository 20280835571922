import React from 'react';
import Modal from '../shared/Modal';
import Alert from 'react-s-alert';

interface Props {
  isActive: boolean;
  onClose: () => void;
  onSave: (name: string | undefined) => void;
  isLoading: boolean;
}

const NewClientForm: React.FC<Props> = ({ isActive, onClose, onSave, isLoading }: Props) => {
  let name_ref: HTMLInputElement | null;

  const didClickSave = () => {
    const name = name_ref?.value;
    if (name === '') {
      Alert.error('Kundenavn må fylles ut');
    } else {
      onSave(name);
    }
  };
  return (
    <Modal
      title="Opprett ny klient"
      isActive={isActive}
      onClose={onClose}
      onSave={() => didClickSave()}
      isLoading={isLoading}
    >
      <div className="field">
        <div className="control">
          <input ref={ref => (name_ref = ref)} className="input" placeholder="Klientnavn" />
        </div>
      </div>
    </Modal>
  );
};

export default NewClientForm;
