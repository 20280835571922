import React, { useState } from 'react';
import ProfileImage from './ProfileImage';
import JrcLogo from '../../static/jrc_logo.svg';
import { Link, useLocation } from 'react-router-dom';
import { useButler } from '../../utils/butlerContext';
import Spinner from './Spinner';
import RouteItem from '../../types/RouteItem';
import { useAuth0 } from '../../utils/authContext';

interface MobileMenuProps {
  menuItems: RouteItem[];
}

const MobileMenu: React.FC<MobileMenuProps> = ({ menuItems }) => {
  const [isActive, setIsActive] = useState(false);
  const location = useLocation();
  const { currentUser } = useButler();
  const { logout, apiUser } = useAuth0();

  if (!currentUser) {
    return <Spinner />;
  }

  const profile = currentUser?.profile;

  let hamburgerStyle = 'hamburger hamburger--3dy js-hamburger';
  if (isActive) hamburgerStyle += ' is-active';

  return (
    <div className="is-hidden-tablet">
      {isActive && (
        <div
          className="mobile-menu-overlay"
          style={{
            position: 'fixed',
            marginTop: 50,
            top: 0,
            bottom: 0,
            left: 0,
            right: 0,
            padding: '0 20px 0 20px',
            backgroundColor: '#f7f9fa',
            textAlign: 'center'
          }}
        >
          {menuItems
            .filter(item =>
              item.permissionForVisibility ? apiUser?.permissions?.includes(item.permissionForVisibility) : true
            )
            .map(item => (
              <div
                key={item.title}
                className={`item is-size-5 ${item.path === location.pathname ? ' active' : ''}`}
                onClick={() => setIsActive(prevState => !prevState)}
              >
                <Link to={item.path}>{item.title}</Link>
              </div>
            ))}
          <div className="user-card">
            <div className="fix-height m-l-10 m-r-10" />
            <div className="columns m-l-5 m-b-30">
              <div className="column is-narrow">
                <ProfileImage profile={profile} />
              </div>
              <div>
                <Link to={`/ansatte/${profile.auth_id}`}>{`${profile.first_name} ${profile.last_name}`}</Link>
              </div>
              <div className="has-text-weight-light">
                <div className="logout" onClick={() => logout()}>
                  Logg ut
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
      <div className="mobile-menu is-hidden-tablet columns is-gapless is-mobile flex-align-center">
        <div className="column">
          <Link to="/">
            <img src={JrcLogo} alt="Logo" style={{ width: '30px' }} />
          </Link>
        </div>
        <div className="column has-text-right">
          <div className={hamburgerStyle} onClick={() => setIsActive(prevState => !prevState)}>
            <div className="hamburger-box">
              <div className="hamburger-inner" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MobileMenu;
