import MODAL_TYPES from './modalTypes';
import loadData from './loadData';
import { ButlerContextProps } from '../../utils/butlerContext';
import { IProject, IUser } from '@jrc/jrc-butler-api/butler';

const saveForm = async (
  updatedData: any, //@todo have to change this
  currentModal: string,
  projectId: number,
  project: IProject,
  currentUser: IUser,
  butlerContext: ButlerContextProps
) => {
  if (currentModal === MODAL_TYPES.NAME) {
    const updatedProject: IProject = {
      name: updatedData.name
    };
    await butlerContext.projectService.updateProject(updatedProject, projectId);
  }

  if (currentModal === MODAL_TYPES.DESCRIPTION) {
    const updatedProject: IProject = {
      name: project.name,
      description: updatedData.description
    };
    await butlerContext.projectService.updateProject(updatedProject, projectId);
  } else if (currentModal === MODAL_TYPES.PROJECT_INFO) {
    const updatedProject: IProject = {
      name: project.name,
      start_date: updatedData.start_date,
      end_date: updatedData.end_date,
      client_id: updatedData.clientId,
      is_upselling: updatedData.is_upselling,
      customer_survey: updatedData.customer_survey,
      is_private: updatedData.is_private
    };
    await butlerContext.projectService.updateProject(updatedProject, projectId);
  } else if (currentModal === MODAL_TYPES.PROJECT_RESOURCES) {
    const updatedProject: IProject = {
      name: project.name,
      slack: updatedData.slack,
      github_repo: updatedData.gitlab,
      google_drive: updatedData.google_drive,
      trello: updatedData.trello,
      other_link: updatedData.other_link
    };
    await butlerContext.projectService.updateProject(updatedProject, projectId);
  } else if (currentModal === MODAL_TYPES.TEAM_MEMBERS) {
    const members = updatedData.members.map((m: any) => ({
      //@todo have to fix this
      member_id: m.auth_id,
      role: m.role
    }));
    await butlerContext.projectMemberService.updateProjectMembers(projectId, members);
  } else if (currentModal === MODAL_TYPES.SALES_DESCRIPTION) {
    const updatedProject: IProject = {
      name: project.name,
      sales_description: updatedData.description
    };
    await butlerContext.projectService.updateProject(updatedProject, projectId);
  }
  return loadData(projectId, butlerContext);
};

export default saveForm;
