import React, { useState } from 'react';
import { IUpdatedData } from 'types/updatedData';
import Modal from '../shared/Modal';

interface AboutMeFormProps {
  about: string | null | undefined;
  isActive: boolean;
  onClose: () => void;
  onSave: (updatedData: IUpdatedData) => Promise<void>;
  isSaving: boolean;
  isLoading?: boolean;
  placeholder?: string;
}

const AboutMeForm: React.FC<AboutMeFormProps> = ({ about, isActive, onClose, isLoading, onSave, placeholder }) => {
  const [aboutMeInput, setAboutMeInput] = useState(about ?? '');

  const handleChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    e.preventDefault();
    setAboutMeInput(e.target.value);
  };

  const didClickSave = () => {
    // 'about' is deconstructed a lot of places - keeps this consistent
    const about = aboutMeInput;
    onSave({ about });
  };

  return (
    <Modal title="Om meg" isActive={isActive} onClose={onClose} onSave={() => didClickSave()} isLoading={isLoading}>
      <div className="field">
        <div className="control">
          <textarea
            value={aboutMeInput}
            onChange={handleChange}
            className="textarea"
            placeholder={placeholder || 'Kort tekst om deg selv som vil vises på juniorconsulting.no'}
          />
        </div>
      </div>
    </Modal>
  );
};

export default AboutMeForm;
