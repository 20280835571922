import { ButlerContextProps, CurrentUser } from '../../utils/butlerContext';
import { IClient, IUser } from '@jrc/jrc-butler-api/butler';

import MODAL_TYPES from './modalTypes';
import loadData from './loadData';

interface UpdatedData {
  email?: string;
  name?: string;
  phone?: string;
  role?: string;
  members?: IUser[];
  description?: string;
  file?: Blob;
}

const saveForm = async (
  updatedData: UpdatedData,
  currentModal: MODAL_TYPES,
  client: IClient,
  clientId: number,
  currentUser: CurrentUser,
  butlerContext: ButlerContextProps
) => {
  const { image, ...clientWithoutImage } = client;

  if (currentModal === MODAL_TYPES.CLIENT_CONTACT) {
    const client = {
      name: clientWithoutImage.name,
      contact_email: updatedData.email,
      contact_name: updatedData.name,
      contact_number: updatedData.phone,
      contact_role: updatedData.role
    };
    await butlerContext.clientService.updateClient(client, clientId);
  }

  if (currentModal === MODAL_TYPES.JRC_CONTACTS) {
    const members = updatedData.members?.map(m => m.auth_id || ''); // TODO: This hack is a bit ugly
    if (members) {
      await butlerContext.clientMemberService.updateClientMembers(clientId, members);
    }
  }

  if (currentModal === MODAL_TYPES.DESCRIPTION) {
    const client = {
      name: clientWithoutImage.name,
      description: updatedData.description
    };
    await butlerContext.clientService.updateClient(client, clientId);
  }

  if (currentModal === MODAL_TYPES.NAME) {
    if (!updatedData.name) return;
    const client = {
      name: updatedData.name
    };
    await butlerContext.clientService.updateClient(client, clientId);
  }

  if (currentModal === MODAL_TYPES.IMAGE) {
    if (!updatedData.file) return;
    const uploadedFile = await butlerContext.fileStorageService.uploadInternarenaFile(updatedData.file);
    const client = {
      name: clientWithoutImage.name,
      image: uploadedFile.url
    };
    await butlerContext.clientService.updateClient(client, clientId);
    document.location.reload();
  }
  return await loadData(clientId, currentUser, butlerContext);
};

export default saveForm;
