import React, { useState } from 'react';
import { Route, useHistory, RouteComponentProps } from 'react-router-dom';

import LeftMenu from './LeftMenu';
import MobileMenu from './MobileMenu';
import Spinner from './Spinner';
import AVAILABLE_ROUTES from '../../utils/routes';
import { useButler } from '../../utils/butlerContext';

const Layout: React.FC = () => {
  const [isLoading, setIsLoading] = useState(false);
  const { currentUser, isInitializing } = useButler();
  const history = useHistory();

  const commonProps = { isLoading, setIsLoading, currentUser };

  if (isInitializing) {
    return <Spinner />;
  }

  if (!currentUser) {
    history.push('/register');
    return <Spinner />;
  }

  const renderRoutes = () =>
    AVAILABLE_ROUTES.filter(menuItem => menuItem.Component).map(({ path, exact, Component, title }) => (
      <Route
        key={path}
        path={path}
        exact={exact}
        render={(routeProps: RouteComponentProps) =>
          Component && <Component {...routeProps} {...commonProps} title={title} />
        }
      />
    ));

  const MENU_ITEMS = AVAILABLE_ROUTES.filter(menuItem => !menuItem.subRoute);
  return (
    <div>
      <MobileMenu menuItems={MENU_ITEMS} />
      <div className="columns is-fullheight">
        <div className="column left-menu is-fullheight is-hidden-mobile">
          <LeftMenu menuItems={MENU_ITEMS} />
        </div>
        <div className="column is-12-mobile is-fullheight m-t-20">
          <main>
            {renderRoutes()}
            {isLoading && <Spinner />}
          </main>
        </div>
      </div>
    </div>
  );
};

export default Layout;
