import {
  IClient,
  IClientComment,
  IClientCommentEntity,
  IEvent,
  IProjectCommentEntity,
  IUser
} from '@jrc/jrc-butler-api/butler';
import React, { useCallback, useEffect, useState } from 'react';

import CommentForm from '../../components/shared/CommentForm';
import CommentList from '../../components/shared/CommentList';
import { useButler } from '../../utils/butlerContext';

interface Props {
  client: IClient;
}

const ClientCommentBox: React.FC<Props> = ({ client }: Props) => {
  const [comments, setComments] = useState<IClientComment[]>();
  const [isLoading, setIsLoading] = useState(false);
  const [profiles, setProfiles] = useState<IUser[]>();
  const context = useButler();

  const fetchUsers = useCallback(async () => {
    const profiles = await context.profileService.getUsers();
    setProfiles(profiles);
  }, [context.profileService]);

  useEffect(() => {
    fetchUsers();
  }, [fetchUsers]);

  const fetchComments = async () => {
    if (!client.id) return;
    const comments = await context.clientCommentService.getClientComments(client?.id);
    setComments(comments);
  };

  const createClientComment = async (newComment: IClientCommentEntity | IProjectCommentEntity) => {
    setIsLoading(true);
    if (!client.id) return;
    await context.clientCommentService.createClientComment({ ...newComment, client_id: client.id });
    await fetchComments();

    const actionText = `${context.currentUser?.profile.first_name} kommentert klienten ${client.name}`;
    await createEvent(actionText, client.id);
    setIsLoading(false);
  };

  const updateClientComment = async (updatedComment: IClientCommentEntity | IProjectCommentEntity) => {
    setIsLoading(true);
    if (!client.id || !updatedComment.id) return;
    await context.clientCommentService.updateClientComment(
      { ...updatedComment, client_id: client.id },
      updatedComment.id
    );
    await fetchComments();

    const actionText = `${context.currentUser?.profile.first_name} oppdaterte kommentaren på klienten ${client.name}`;
    await createEvent(actionText, client.id);
    setIsLoading(false);
  };

  const deleteClientComment = async (commentId: number) => {
    setIsLoading(true);
    if (!client.id) return;
    await context.clientCommentService.deleteClientComment(commentId);
    await fetchComments();

    const actionText = `${context.currentUser?.profile.first_name} slettet sin kommentar på klienten ${client.name}`;
    await createEvent(actionText, client.id);
    setIsLoading(false);
  };

  const createEvent = async (actionText: string, entityId: number) => {
    if (!context.currentUser?.profile.auth_id) return;
    try {
      const event: IEvent = {
        type: 'client',
        title: actionText,
        description: '',
        entity_id: entityId,
        change_by: context.currentUser?.profile.auth_id,
        image: context.currentUser?.profile.image || '',
        icon: 'pencil',
        date: new Date().toISOString()
      };
      await context.eventService.createEvent(event);
    } catch (e) {
      console.log('Error posting event: ' + e);
    }
  };

  return (
    <div className="is-fullwidth">
      <h2 className="is-size-5 is-5-tablet m-t-100">Kommentarer</h2>
      <div className="CommentBox m-t-10">
        <CommentList
          comments={comments || []}
          profiles={profiles || []}
          isLoading={isLoading}
          onDelete={(commentId: number) => deleteClientComment(commentId)} // TODO: Remove this type definition after merge
          onUpdate={(updatedComment: IClientCommentEntity | IProjectCommentEntity) =>
            updateClientComment(updatedComment)
          } // TODO: Remove this type definition after merge
        />
        <CommentForm onSave={createClientComment} isLoading={isLoading} maxLength={3000} />
      </div>
    </div>
  );
};

export default ClientCommentBox;
