import React, { useEffect, useState } from 'react';
import { convertToRaw, EditorState, ContentState } from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';
import { history } from '../../utils/history';

interface IProps {
  markup?: string;
  submitContent: (title: string, markup: string, published: boolean) => void;
  isLoading?: boolean;
  title?: string;
  published?: boolean;
}

const NewArticlePage: React.FC<IProps> = ({ markup, submitContent, isLoading, title, published }) => {
  const [editorState, setEditorState] = useState<EditorState>();
  const [isClient, setIsClient] = useState<boolean>();
  const [isPublished, setIsPublished] = useState<boolean>(published ?? true);
  const [editTitle, setEditTitle] = useState<string>(title ?? '');

  useEffect(() => {
    let editorState = EditorState.createEmpty();

    if (markup) {
      const blocksFromHtml = htmlToDraft(markup);
      const { contentBlocks, entityMap } = blocksFromHtml;
      const contentState = ContentState.createFromBlockArray(contentBlocks, entityMap);
      editorState = EditorState.createWithContent(contentState);
    }

    setIsClient(true);
    setEditorState(editorState);
  }, [markup]);

  const onEditorStateChange = (editorState: EditorState) => {
    setEditorState(editorState);
  };

  const submitContentLocal = () => {
    if (editorState) {
      const contentState = convertToRaw(editorState.getCurrentContent());
      const markup = draftToHtml(contentState);
      submitContent && submitContent(editTitle, markup, isPublished);
    }
  };

  const updateMode = !!title;
  if (!isClient) return null;

  return (
    <div>
      <div className="columns">
        <div className="column">
          <input
            value={editTitle}
            onChange={event => setEditTitle(event.target.value)}
            placeholder="Tittel"
            style={{ fontSize: 40, border: 0, width: '100%' }}
            className="m-b-20 no-outline"
          />
        </div>
        <div className="column is-4">
          <div>
            <div>
              <button
                className={`button is-rounded is-black is-large ${isLoading ? 'is-loading' : ''}`}
                onClick={() => submitContentLocal()}
              >
                {updateMode ? 'Lagre' : 'Publiser'}
              </button>
              <button onClick={() => history.push('/nyheter')} className="button is-rounded is-default m-l-5 is-large">
                Avbryt
              </button>
            </div>
            <div className="control m-t-10">
              <label className="checkbox is-size-5">
                <input type="checkbox" onChange={() => setIsPublished(!isPublished)} checked={isPublished} />
                <span className="has-text-weight-light p-l-5">Publisert</span>
              </label>
            </div>
          </div>
        </div>
      </div>
      <div className="columns">
        <div className="column is-8">
          <div className="content">
            <Editor
              toolbar={{
                options: ['inline', 'blockType', 'list', 'emoji', 'link', 'image'],
                inline: { inDropdown: true },
                textAlign: { inDropdown: true },
                list: {
                  inDropdown: true
                },
                blockType: {
                  inDropdown: true,
                  options: ['Normal', 'H1', 'H2', 'Blockquote', 'Code']
                }
              }}
              editorState={editorState}
              placeholder="Det var en gang..."
              onEditorStateChange={onEditorStateChange}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default NewArticlePage;
