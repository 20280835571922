import React from 'react';
import JrcLogoWithText from '../../static/jrc_logo_letters.svg';
import ProfileInfoForm from '../../components/register/ProfileInfoForm';

const Signup: React.FC = () => {
  return (
    <div className="login-container">
      <div className="login-form-container">
        <div className="has-text-centered">
          <img className="jrc-logo" src={JrcLogoWithText} alt="Logo" />
        </div>
        <ProfileInfoForm />
      </div>
    </div>
  );
};

export default Signup;
