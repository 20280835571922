import { IProject, IUnpaidHours, IUser } from '@jrc/jrc-butler-api/butler';
import React, { useCallback, useEffect, useState } from 'react';

//@ts-ignore
import ReactExport from 'react-data-export';
import { useButler } from '../../utils/butlerContext';

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

interface UnpaidProps {
  isLoading: boolean;
  setIsLoading: (value: boolean) => void;
  title: string;
}

interface IUnpaidHoursRow {
  client: string;
  userName: string;
  payGroup: string;
  rate: number;
  pay: string;
  invoiceSize: string;
  commission: string;
  project: string;
}

const Unpaid: React.FC<UnpaidProps> = ({ setIsLoading, title }) => {
  const { timeService, projectService, profileService } = useButler();
  const context = useButler();

  const [unpaidHoursRow, setUnpaidHoursRow] = useState<IUnpaidHoursRow[]>([]);

  //TODO: Disse bør sikkert ikke hardkodes?!
  //TODO: hør med økonomiansvarlig om disse satsene blir brukt.
  const PL_RATE = 262.93;
  const K_rate = 219.11;

  const getValuesForOneRow = useCallback(
    async (currentUser: IUser | undefined, unpaid: IUnpaidHours) => {
      const project: IProject = await projectService.getProject(unpaid.projectId);
      const member = project.members!.find(member => member.member_id === unpaid.authId);
      const user = await profileService.getUser(unpaid.authId.toString());
      const userName = user.first_name + ' ' + user.last_name;

      let client = '';
      if (project.client !== null) {
        client = project.client!.name;
      }

      let role = '';
      let pay = 0;
      let rate = 0;

      if (member && unpaid.hours !== 'NaN') {
        role = member.role;
        pay = role === 'PL' ? PL_RATE * unpaid.hours : K_rate * unpaid.hours;
        rate = role === 'PL' ? PL_RATE : K_rate;
      }

      return {
        client,
        invoiceId: unpaid.invoiceId,
        userName,
        payGroup: `timelønn, ${role}`,
        hours: unpaid.hours,
        rate,
        pay: pay.toFixed(2),
        invoiceSize: '',
        commission: '',
        project: ''
      };
    },
    [profileService, projectService]
  );

  const getValuesFromIds = useCallback(
    (unpaidHours: IUnpaidHours[]) => {
      const promises = unpaidHours.map(unpaid => getValuesForOneRow(context.currentUser?.profile, unpaid));
      return Promise.all(promises).then(x => x);
    },
    [context.currentUser, getValuesForOneRow]
  );

  const populateUnpaidHours = useCallback(async () => {
    const unpaidHoursWithIds = await timeService.consultantPaymentUnpaid();
    const unpaidHours = await getValuesFromIds(unpaidHoursWithIds);
    setUnpaidHoursRow(unpaidHours);
  }, [getValuesFromIds, timeService]);

  useEffect(() => {
    document.title = `JrC - ${title}`;
    setIsLoading(true);
    populateUnpaidHours();
    setIsLoading(false);
  }, [populateUnpaidHours, setIsLoading, title]);

  const getCurrentDate = (separator = ''): string => {
    let newDate = new Date();
    let date = newDate.getDate();
    let month = newDate.getMonth() + 1;
    let year = newDate.getFullYear();

    return `${year}${separator}${month < 10 ? `0${month}` : `${month}`}${separator}${
      date < 10 ? `0${date}` : `${date}`
    }`;
  };

  const headers = [
    { label: 'Kunde', value: 'client' },
    { label: 'Prosjekt', value: 'project' },
    { label: 'Faktura', value: 'invoiceId' },
    { label: 'Ansatt', value: 'userName' },
    { label: 'Lønningstype', value: 'payGroup' },
    { label: 'Fakturastørrelse', value: 'invoiceSize' },
    { label: 'Timer', value: 'hours' },
    { label: 'Provisjon', value: 'commission' },
    { label: 'Sats', value: 'rate' },
    { label: 'Bruttolønn', value: 'pay' }
  ];

  const date = getCurrentDate('-');
  return (
    <div>
      <h5 className="title is-5">Ubetalte timer</h5>
      <ExcelFile element={<button className="button is-primary">Last ned</button>} filename={'Ubetalte timer ' + date}>
        <ExcelSheet data={unpaidHoursRow} name={`Ubetalte timer + ${date}`}>
          {headers.map(header => (
            <ExcelColumn key={header.label} label={header.label} value={header.value} />
          ))}
        </ExcelSheet>
      </ExcelFile>
    </div>
  );
};

export default Unpaid;
