import React, { useState, useEffect, ChangeEvent, useCallback } from 'react';
import ColumnHeader from '../../components/shared/ColumnHeader';
import { CurrentUser } from '../../utils/butlerContext';
import { history } from '../../utils/history';
import NewProjectForm from '../../components/prosjekter/NewProjectForm';
import Sticky from 'react-stickynode';
import TabSelection from '../../components/shared/TabSelection';
import {
  PROJECT_STATES,
  filters,
  projectVisbility,
  getStatus,
  getFilteredProjects
} from '../../components/prosjekter/filterUtil';
import { useButler } from '../../utils/butlerContext';
import { IUser } from '@jrc/jrc-butler-api/butler';
import { IProjectWithManager } from '../../types/ProjectWithManager';
import { Link } from 'react-router-dom';

interface IProps {
  setIsLoading: (isLoading: boolean) => void;
  isLoading: boolean;
  title: string;
  currentUser: CurrentUser;
}

const ProjectList: React.FC<IProps> = (props: IProps) => {
  const [showNewFormModal, setShowNewFormModal] = useState<boolean>(false);
  const [isCreatingProject, setIsCreatingProject] = useState<boolean>(false);
  const [currentFilter, setCurrentFilter] = useState<string>(PROJECT_STATES.ON_GOING);
  const [searchText, setSearchText] = useState<string>('');
  const [projects, setProjects] = useState<Array<IProjectWithManager>>([]);
  const [profiles, setProfiles] = useState<Array<IUser>>([]);
  const [accessFilter, setAccessFilter] = useState<string>(PROJECT_STATES.ALL_PROJECTS);
  const [finished, setFinished] = useState<boolean>(false);

  const { profileService, projectService } = useButler();

  const fetchProjects = useCallback(async () => {
    const { setIsLoading, isLoading, title } = props;
    document.title = `JrC - ${title}`;

    const projects = (await projectService.getProjects()) as Array<IProjectWithManager>;
    const profiles = await profileService.getUsers();
    setProjects(projects);
    setProfiles(profiles);
    if (isLoading) {
      setFinished(true);
      setIsLoading(false);
    }
  }, [props, profileService, projectService]);

  useEffect(() => {
    const { setIsLoading } = props;

    if (!finished) {
      setIsLoading(true);
    }

    fetchProjects();
  }, [fetchProjects, props, profileService, projectService, finished]);

  const createProject = async (projectName: string) => {
    setIsCreatingProject(true);
    const project = await projectService.createProject({ name: projectName });
    setIsCreatingProject(false);

    history.push(`/prosjekter/${project.id}`);
  };

  // Add project manager profiles to each project so we can use the PM name in the table
  const projectsWithManager = (): Array<IProjectWithManager> => {
    return projects.map(project => {
      const manager = project.projectMembers ? project.projectMembers.find(member => member.role === 'PL') : null;
      if (!manager) return project;
      const manageProfile = profiles.find(p => {
        if (!p.auth_id) return false;
        return p.auth_id.toString() === manager.member_id.toString();
      });

      return { ...project, projectManager: manageProfile ? manageProfile : { email: '' } };
    });
  };

  const ProjectData = () => {
    return (
      <>
        {getFilteredProjects(projectsWithManager(), currentFilter, currentUserAuthId, searchText, accessFilter).map(
          project => (
            <Link className="table-row" key={project.id ? project.id : ''} to={`/prosjekter/${project.id}`}>
              <td>{project.name}</td>
              <td>{project.clientName && project.clientName}</td>
              <td className="is-hidden-mobile">
                {project.projectManager
                  ? `${project.projectManager.first_name} ${project.projectManager.last_name}`
                  : 'Ikke satt'}
              </td>
              <td
                className={
                  getStatus(project) === 'Ikke satt' ? 'is-hidden-mobile has-text-grey-lighter' : 'is-hidden-mobile'
                }
              >
                {getStatus(project)}
              </td>
            </Link>
          )
        )}
      </>
    );
  };

  const handleProjectFilter = (id: string) => setAccessFilter(id);

  const onSearch = (event: ChangeEvent<HTMLInputElement>) => setSearchText(event.target.value);

  const { currentUser } = props;
  const currentUserAuthId = currentUser.profile.auth_id ? currentUser.profile.auth_id : '';

  return (
    <div className="columns">
      <NewProjectForm
        isActive={showNewFormModal}
        isLoading={isCreatingProject}
        onSave={(projectName: string) => createProject(projectName)}
        onClose={() => setShowNewFormModal(false)}
      />

      <div className="column">
        <Sticky>
          <div className="background-white">
            <ColumnHeader
              title="Prosjekter"
              border={false}
              rightComponent={
                <button className="button is-black is-rounded" onClick={() => setShowNewFormModal(true)}>
                  Opprett prosjekt
                </button>
              }
            />
          </div>
        </Sticky>

        <div className="background-white">
          <input onChange={onSearch} className="input search-bar" placeholder="Søk" />{' '}
        </div>
        <TabSelection items={filters} onSelect={(item: any) => setCurrentFilter(item.id)} />
        <TabSelection items={projectVisbility} onSelect={(item: any) => handleProjectFilter(item.id)} />
        <table className="table is-fullwidth">
          <thead>
            <tr>
              <th style={{ width: '20%' }}>Navn</th>
              <th style={{ width: '25%' }}>Kunde</th>
              <th style={{ width: '25%' }} className="is-hidden-mobile">
                Prosjektleder
              </th>
              <th style={{ width: '10%' }} className="is-hidden-mobile">
                Status
              </th>
            </tr>
          </thead>
          <tbody>
            <ProjectData />
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default ProjectList;
