import React, { useCallback, useEffect, useState } from 'react';
import ColumnHeader from 'components/shared/ColumnHeader';
import { orderBy } from 'lodash';
import { prettyPhoneNumber, toFirstLetterLowerCase } from 'utils/helpers';
import Sticky from 'react-stickynode';
import TabSelection from 'components/shared/TabSelection';
import PROJECT_GROUPS, { ProjectGroupTypes } from 'components/ansatte/projectGroups';
import { useButler } from 'utils/butlerContext';
import { IUser } from '@jrc/jrc-butler-api/butler/user';
import { IFilter } from 'types/Filter';
import { Link } from 'react-router-dom';

const filters: IFilter[] = [
  { id: 'All', title: 'Alle' },
  { id: 'Partner', title: 'Partner' },
  { id: 'Aktiv', title: 'Aktiv' },
  { id: 'Alumni', title: 'Alumni' }
];

let project_groups_filters: IFilterGroup[] = [{ id: 'All', title: 'Alle' }];

const populateProjectGroupFilter = () => {
  project_groups_filters = [{ id: 'All', title: 'Alle' }];
  Object.keys(PROJECT_GROUPS).forEach((value: string) => {
    project_groups_filters.push({ id: value as ProjectGroupTypes, title: PROJECT_GROUPS[value as ProjectGroupTypes] });
  });
};

interface EmployeeListProps {
  setIsLoading: (value: boolean) => void;
  title: string;
}

const EmployeeList: React.FC<EmployeeListProps> = ({ setIsLoading, title }: EmployeeListProps) => {
  const { profileService, currentUser } = useButler();

  const [searchText, setSearchText] = useState<string>('');
  const [profiles, setProfiles] = useState<IUser[]>([]);
  const [currentFilter, setCurrentFilter] = useState<FilterType>('All');
  const [projectGroupFilter, setProjectGroupFilter] = useState<ProjectGroupType>('All');

  const onSearch = (event: React.FormEvent<HTMLInputElement>) => {
    setSearchText(event.currentTarget.value);
  };

  const populateProfiles = useCallback(async () => {
    setIsLoading(true);
    const profiles = await profileService.getUsers();
    setProfiles(profiles);
    setIsLoading(false);
  }, [setIsLoading, setProfiles, profileService]);

  useEffect(() => {
    populateProjectGroupFilter();
    document.title = `JrC - ${title}`;
    populateProfiles();
  }, [populateProfiles, title]);

  const getFilteredProfiles = (
    profiles: IUser[],
    filter: FilterType,
    projectGroupFilter: ProjectGroupType,
    searchText: string
  ): IUser[] => {
    const sortedProfiles = orderBy(profiles, ['first_name', 'last_name']);
    let filteredProfiles = sortedProfiles;

    if (filter !== 'All') {
      if (filter === 'Aktiv') {
        filteredProfiles = filteredProfiles.filter(p => p.active);
      } else if (filter === 'Alumni') {
        filteredProfiles = filteredProfiles.filter(p => !p.active);
      } else if (filter === 'Partner') {
        filteredProfiles = filteredProfiles.filter(p => p.title === 'PARTNER' || p.title === 'CEO');
      }
    }

    if (projectGroupFilter !== 'All') {
      filteredProfiles = filteredProfiles.filter(p => p.project_group === projectGroupFilter);
    }

    if (searchText) {
      filteredProfiles = filteredProfiles.filter(
        profile =>
          `${profile.first_name} ${profile.last_name}`.toLowerCase().includes(searchText.toLowerCase()) ||
          (profile.phone_nr && profile.phone_nr.includes(searchText))
      );
    }

    return filteredProfiles;
  };

  const currentUserAuthId = currentUser?.profile.auth_id;
  const filteredProfiles = getFilteredProfiles(profiles, currentFilter, projectGroupFilter, searchText);

  return (
    <div className="columns">
      <div className="column">
        <Sticky>
          <div className="background-white">
            <ColumnHeader
              title="Ansatte"
              border={false}
              rightComponent={<input onChange={onSearch} className="input search-bar" placeholder="Søk" />}
            />
          </div>
        </Sticky>
        <TabSelection items={filters} onSelect={(filter: IFilter) => setCurrentFilter(filter.id as FilterType)} />
        <TabSelection
          items={project_groups_filters}
          onSelect={(filter: IFilterGroup) => setProjectGroupFilter(filter.id)}
        />

        <table className="table is-fullwidth">
          <thead>
            <tr>
              <th style={{ width: '20%' }}>Navn ({filteredProfiles.length})</th>
              <th style={{ width: '15%' }}>Telefon</th>
              <th style={{ width: '30%' }} className="is-hidden-mobile">
                E-post
              </th>
              <th style={{ width: '15%' }} className="is-hidden-mobile">
                Rolle
              </th>
            </tr>
          </thead>
          <tbody>
            {filteredProfiles.map((profile: IUser) => (
              <Link className="table-row" key={profile.auth_id!} to={`/ansatte/${profile.auth_id}`}>
                <td
                  className={profile.auth_id === currentUserAuthId ? 'bold' : ''}
                >{`${profile.first_name} ${profile.last_name}`}</td>
                <td className={profile.auth_id === currentUserAuthId ? 'bold' : ''}>
                  {prettyPhoneNumber(profile.phone_nr)}
                </td>
                <td className={`is-hidden-mobile ${profile.auth_id === currentUserAuthId ? 'bold' : ''}`}>
                  {profile.email}
                </td>
                <td className={`is-hidden-mobile ${profile.auth_id === currentUserAuthId ? 'bold' : ''}`}>
                  {toFirstLetterLowerCase(profile.title)}
                </td>
              </Link>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default EmployeeList;
