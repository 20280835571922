import React, { useState } from 'react';
import ProfileImage from './ProfileImage';
import { Link } from 'react-router-dom';
import Alert from 'react-s-alert';
import { library } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash, faEdit } from '@fortawesome/free-solid-svg-icons';
import { IUser } from '@jrc/jrc-butler-api/butler';
library.add(faTrash, faEdit);

interface IProps {
  text: string;
  allowDelete: boolean;
  profile: IUser;
  onDelete: () => void;
  created: string;
  updated: string | boolean;
  onSave: (editedComment: string) => any;
}

const Comment: React.FC<IProps> = (props: IProps) => {
  const { profile, created, updated, allowDelete } = props;

  const [isEditable, setIsEditable] = useState<boolean>(false);
  const [text, setText] = useState<string>(props.text);
  const [chars_left, setChars_left] = useState<number>(3000 - props.text.length);

  const didClickDelete = () => {
    if (allowDelete) {
      props.onDelete();
    }
  };

  const didClickEdit = () => {
    setIsEditable(!isEditable);
    setText(props.text);
  };

  const handleChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    let input = event.target.value;
    setText(input);
    setChars_left(3000 - input.length);
  };

  const didClickSave = () => {
    if (!text || text.length === 0) {
      Alert.error('Kommentar kan ikke være tom');
    } else {
      props.onSave(text);
    }
    setIsEditable(true);
  };

  const allowEdit = allowDelete;
  return (
    <div>
      <div className="Comment">
        <div className="columns">
          <div className="colum m-t-5">
            <ProfileImage profile={profile} />
          </div>
          <div className="column is-narrow m-t-5">
            <div className="grey">
              <Link to={`/ansatte/{profile.auth_id}`}>{`${profile.first_name} ${profile.last_name}`}</Link>
            </div>
          </div>
          <div className="column m-t-5">
            <div className="grey text-align-right">{created}&nbsp;</div>
            <div className="grey text-align-right">{updated ? '(Sist endret: ' + updated + ')' : ''}</div>
          </div>
        </div>
        {isEditable ? (
          <div>
            <p className="grey fa-pull-right">{chars_left} tegn</p>
            <div className="field">
              <div className="Comment-body control">
                <textarea
                  value={text}
                  onChange={handleChange}
                  className="textarea"
                  placeholder="Kommentar"
                  maxLength={3000}
                />
              </div>
              <footer className="textbox-footer">
                <button className={'button is-black'} onClick={didClickSave}>
                  {'Lagre'}
                </button>
                <button className={'button'} onClick={didClickEdit}>
                  {'Lukk'}
                </button>
              </footer>
            </div>
          </div>
        ) : (
          <div>
            <div className="Comment-body">{text}</div>
            {allowDelete ? (
              <button onClick={didClickDelete} className="btn-flat fa-pull-right">
                <FontAwesomeIcon icon="trash-alt" />
              </button>
            ) : (
              ''
            )}
            {allowEdit ? (
              <button onClick={didClickEdit} className="btn-flat fa-pull-right">
                <FontAwesomeIcon icon="edit" />
              </button>
            ) : (
              ''
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default Comment;
