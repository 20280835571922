import React, { useState } from 'react';
import Autocomplete from 'react-autocomplete';
import DatePicker from 'react-datepicker';
import nb from 'date-fns/locale/nb';
import { IProject } from '@jrc/jrc-butler-api/butler';
import { IUpdateTimeEntry, validateIfStringIsValidNumber } from '../../pages/timeregistrering';

interface Props {
  projects: IProject[];
  tagOptions: string[];
  isLoading: boolean;
  onSave: (timeEntry: IUpdateTimeEntry) => void;
}

const WorkHourForm: React.FC<Props> = ({ projects, tagOptions, isLoading, onSave }: Props) => {
  const [projectId, setProjectId] = useState(0);
  const [date, setDate] = useState(new Date().toISOString());
  const [hours, setHours] = useState('');
  const [description, setDescription] = useState('');
  const [tag, setTag] = useState('');

  const save = () => {
    onSave({ projectId, date, hours: Number(hours), description, tag });
    setProjectId(0);
    setDate(new Date().toISOString());
    setHours('');
    setDescription('');
    setTag('');
  };

  const handleHourInput = (value: string) => {
    if (validateIfStringIsValidNumber(value)) {
      setHours(value);
    }
  };

  return (
    <div className="field is-horizontal">
      <div className="field-body">
        <div className="field">
          <div className="control is-expanded">
            <DatePicker
              className="input"
              locale={nb}
              selected={new Date(date)}
              onChange={date => {
                if (date !== null) setDate(new Date(date as Date).toISOString());
              }}
              dateFormat="yyyy-MM-dd"
            />
          </div>
        </div>
        <div className="m-r-10">
          <div className="field select">
            <select value={projectId || 0} onChange={e => setProjectId(Number(e.target.value))}>
              <option disabled value={0}>
                Velg prosjekt
              </option>
              {projects &&
                projects.map(project => (
                  <option value={String(project.id)} key={String(project.id)}>
                    {project.name}
                  </option>
                ))}
            </select>
          </div>
        </div>

        <div className="field">
          <p className="control is-expanded">
            <input
              onChange={e => handleHourInput(e.target.value)}
              value={hours}
              className="input"
              type="text"
              placeholder="Antall timer"
            />
          </p>
        </div>

        <div className="field">
          <p className="control is-expanded">
            <input
              onChange={e => setDescription(e.target.value)}
              className="input"
              value={description}
              type="text"
              placeholder="Beskrivelse"
            />
          </p>
        </div>

        <div className="field">
          <div className="control is-expanded">
            <Autocomplete
              getItemValue={item => item.label}
              items={tagOptions.map(o => {
                return { label: o };
              })}
              shouldItemRender={(item, value) => item.label.toLowerCase().indexOf(value.toLowerCase()) > -1}
              renderItem={(item, isHighlighted) => (
                <div
                  key={item.label}
                  style={{
                    background: isHighlighted ? 'lightgray' : 'white',
                    padding: '2px'
                  }}
                >
                  {item.label}
                </div>
              )}
              renderInput={props => <input className="input" placeholder="Tag" {...props} />}
              value={tag}
              onChange={e => setTag(e.target.value)}
              onSelect={val => setTag(val)}
            />
          </div>
        </div>

        <div className="field">
          <div className="control">
            <button className={'button is-primary' + (isLoading ? ' is-loading' : '')} onClick={() => save()}>
              Registrer
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default WorkHourForm;
