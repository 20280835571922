import React from 'react';
import Sticky from 'react-stickynode';
import { library } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckSquare, faBan } from '@fortawesome/free-solid-svg-icons';
import ColumnHeader from '../../components/shared/ColumnHeader';
import Modal from '../../components/shared/Modal';
import { history } from '../../utils/history';
import { ButlerContext } from '../../utils/butlerContext';
import { prettyDate, prettyTime } from '../../utils/helpers';
import { Link } from 'react-router-dom';
library.add(faCheckSquare, faBan);

class Nyheter extends React.Component {
  static contextType = ButlerContext;
  state = {
    articleToDelete: null,
    isDeleting: false,
    news: []
  };

  async componentDidMount() {
    this.mounted = true;
    const { setIsLoading, title } = this.props;
    document.title = `JrC - ${title}`;
    const newsService = this.context.newsService;

    setIsLoading(true);
    const news = await newsService.getNews(0, true, 100);

    const profiles = await this.context.profileService.getUsers();

    const docsWithAuthor = news.map(doc => ({
      ...doc,
      author: profiles.find(p => p.auth_id === doc.author.toString())
    }));

    // To ensure we don't do calls to unmounted components
    if (this.mounted) {
      this.setState({ news: docsWithAuthor });
      setIsLoading(false);
    }
  }

  componentWillUnmount() {
    this.mounted = false;
  }

  onClickDelete = article => {
    this.setState({ articleToDelete: article });
  };

  deleteArticle = async id => {
    this.setState({ isDeleting: true });
    const newsService = this.context.newsService;
    await newsService.deleteNews(id);
    window.location.reload();
  };

  render() {
    const { articleToDelete, isLoading, isDeleting, news } = this.state;
    if (isLoading) return null;
    return (
      <div className="columns">
        <Modal
          onClose={() => this.setState({ articleToDelete: null })}
          onSave={() => this.deleteArticle(articleToDelete.id)}
          title="Slett artikkel?"
          saveText="Ja"
          isLoading={isDeleting}
          isActive={articleToDelete !== null}
        >
          Er du sikker på at du vil slette: <strong>{articleToDelete && articleToDelete.title}</strong>? Dette kan ikke
          angres.
        </Modal>
        <div className="column">
          <Sticky>
            <div className="background-white">
              <ColumnHeader
                title="Nyheter"
                border={false}
                rightComponent={
                  <button onClick={() => history.push('/nyheter/ny')} className="button is-rounded is-black">
                    Ny artikkel
                  </button>
                }
              />
            </div>
          </Sticky>

          <table className="table is-fullwidth">
            <thead>
              <tr>
                <th>Tittel</th>
                <th>Forfatter</th>
                <th className="is-hidden-mobile">Opprettet</th>
                <th>Publisert</th>
                <th />
              </tr>
            </thead>
            <tbody>
              {news &&
                news.map(doc => (
                  <Link className="table-row" key={doc.id} to={`/nyheter/${doc.id}`}>
                    <td>{doc.title}</td>
                    <td>
                      {doc.author.first_name} {doc.author.last_name}
                    </td>
                    <td className="is-hidden-mobile">
                      {prettyDate(doc.posted_date)} {prettyTime(doc.posted_date)}
                    </td>
                    <td>
                      {doc.published ? (
                        <span className="icon has-text-success">
                          <FontAwesomeIcon icon="check-square" />
                        </span>
                      ) : (
                        <span className="icon has-text-danger">
                          <FontAwesomeIcon icon="ban" />
                        </span>
                      )}
                    </td>
                    <td
                      onClick={e => {
                        e.stopPropagation();
                        this.onClickDelete(doc);
                      }}
                    >
                      <div className="delete" />
                    </td>
                  </Link>
                ))}
            </tbody>
          </table>
        </div>
      </div>
    );
  }
}

export default Nyheter;
