import { IUser } from '@jrc/jrc-butler-api/butler';
import Alert from 'react-s-alert';
import { IUpdatedData } from 'types/updatedData';
import { ButlerContextProps } from 'utils/butlerContext';
import MODAL_TYPES from './modalTypes';

const globalSaveForm = async (
  updatedData: IUpdatedData,
  employeeId: string,
  currentModal: string | null,
  butlerContext: ButlerContextProps,
  isPartnerEdit: boolean,
  profile: IUser
) => {
  const { image, ...profileWithoutImage } = profile!;
  let result;

  // Check the currently opened
  if (currentModal === MODAL_TYPES.ABOUT_ME) {
    result = isPartnerEdit
      ? await butlerContext.profileService.updateUserAdmin(
          { ...profileWithoutImage, bio_text: updatedData.about },
          employeeId
        )
      : await butlerContext.profileService.updateUser({
          ...profileWithoutImage,
          bio_text: updatedData.about
        });
  } else if (currentModal === MODAL_TYPES.CONTACT_INFO) {
    result = isPartnerEdit
      ? await butlerContext.profileService.updateUserAdmin({ ...profileWithoutImage, ...updatedData }, employeeId)
      : await butlerContext.profileService.updateUser({ ...profileWithoutImage, ...updatedData });
  } else if (currentModal === MODAL_TYPES.FUN_FACT || currentModal === MODAL_TYPES.QUOTE) {
    result = isPartnerEdit
      ? await butlerContext.profileService.updateUserAdmin(
          { ...profileWithoutImage, fun_fact: updatedData.about },
          employeeId
        )
      : await butlerContext.profileService.updateUser({ ...profileWithoutImage, fun_fact: updatedData.about });
  } else if (currentModal === MODAL_TYPES.IMAGE) {
    if (!updatedData.file) {
      Alert.error('Det skjedde noe feil med opplastningen. Ta kontakt med IS dersom det vedvarer');
    } else {
      const uploadedFile = await butlerContext.fileStorageService.uploadProfileImage(updatedData.file);
      result = isPartnerEdit
        ? await butlerContext.profileService.updateUserAdmin(
            { ...profileWithoutImage, image: uploadedFile.url },
            employeeId
          )
        : await butlerContext.profileService.updateUser({ ...profileWithoutImage, image: uploadedFile.url });
    }
  }

  if (result) {
    // TODO: Fix this with global state
    // Reload the entire page to make sure the LeftNav refreshes to.
    // This hack could go away with global state
    if (currentModal === MODAL_TYPES.IMAGE) {
      document.location.reload();
      return null;
    }
    return result;
  } else {
    Alert.error('Har du husket å fylle ut ALLE felter og satt prosjektgruppe? Hvis ja, kontakt noen i IT for hjelp.');
  }
};

export default globalSaveForm;
