import React, { useCallback, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { Link } from 'react-router-dom';
import ProfileImage from '../../components/shared/ProfileImage';
import ColumnHeader from '../../components/shared/ColumnHeader';
import loadData from '../../components/hjem/loadData';
import { timeSince } from '../../utils/helpers';
import Article from '../../components/hjem/Article';
import { useButler } from '../../utils/butlerContext';
import { IEvent, INewsItem, IUser } from '@jrc/jrc-butler-api/butler';

interface Props {
  location: string;
  setIsLoading: (isLoading: boolean) => void;
  isLoading: boolean;
  title: string;
}

const Hjem: React.FC<Props> = ({ setIsLoading, isLoading, title }) => {
  const context = useButler();
  const location = useLocation();

  const [news, setNews] = useState<INewsItem[]>([]);
  const [events, setEvents] = useState<IEvent[]>([]);
  const [profiles, setProfiles] = useState<IUser[]>([]);
  const [currentNewsPage, setCurrentNewsPage] = useState(0);

  const updateNews = useCallback(async () => {
    setIsLoading(true);
    const newsService = context.newsService;
    const queryPage = location.search.split('=')[1] ?? 0;

    const news = await newsService.getNews(Number(queryPage), false, 5);

    setCurrentNewsPage(Number(queryPage));
    setNews(news);
    setIsLoading(false);
  }, [context, setIsLoading, location.search]);

  useEffect(() => {
    updateNews();
  }, [updateNews, location.search, title]);

  const initData = useCallback(async () => {
    setIsLoading(true);
    document.title = `JrC - ${title}`;
    let queryPage = currentNewsPage;
    if (location.search) {
      queryPage = parseInt(location.search.split('=')[1]);
      setCurrentNewsPage(Number(queryPage));
    }
    const { events, profiles, news } = await loadData(queryPage, context);
    setEvents(events);
    setProfiles(profiles);
    setNews(news);
    setIsLoading(false);
  }, [setIsLoading, title, currentNewsPage, location.search, context]);

  useEffect(() => {
    initData();
  }, [initData]);

  const likeArticle = async (id: number) => {
    const newsService = context.newsService;

    if (isLoading) return;
    setIsLoading(true);

    await newsService.likeNews(id);
    const news = await newsService.getNews(currentNewsPage, false, 5);

    setNews(news);

    setIsLoading(false);
  };

  const getEventUrls = (event: IEvent) => {
    switch (event.type) {
      case 'client':
        return `/klienter/${event.entity_id}`;
      case 'project':
        return `/prosjekter/${event.entity_id}`;
      default:
        return `/ansatte/${event.entity_id || event.change_by}`;
    }
  };

  if (isLoading) return null;

  const firstEvents = events
    .sort((a, b) => new Date(b.date).getMilliseconds() - new Date(a.date).getMilliseconds())
    .slice(0, 10)
    .map(event => {
      const profile = profiles.find(p => p.auth_id === event.change_by);
      return { ...event, profile };
    });

  const articlesWithProfiles = news.map(article => {
    const profile = profiles.find(p => p.auth_id === article.author);
    return { ...article, author: profile };
  });

  return (
    <div className="columns">
      <div className="column">
        <ColumnHeader title="Nyheter" border />
        <div className="content">
          {articlesWithProfiles.map(
            article =>
              article.id && (
                <Article
                  key={article.id}
                  id={article.id}
                  currentUserId={context.currentUser?.profile.auth_id}
                  onLike={likeArticle}
                  {...article}
                />
              )
          )}
        </div>
        <nav className="pagination is-rounded" role="navigation" aria-label="pagination">
          {currentNewsPage > 0 ? (
            <Link to={`/?page=${currentNewsPage - 1}`}>
              <p className="pagination-previous">Forrige</p>
            </Link>
          ) : (
            <span />
          )}
          {news.length >= 5 && (
            <Link to={`/?page=${currentNewsPage + 1}`}>
              <p className="pagination-next">Neste side</p>
            </Link>
          )}
        </nav>
      </div>

      <div className="column is-3 is-4-tablet is-3-desktop is-hidden-mobile m-l-20 small-profile-image">
        <ColumnHeader title="Aktivitet" border />
        {firstEvents
          .sort((eventA, eventB) => eventB.date.localeCompare(eventA.date))
          .map(event => {
            const href = getEventUrls(event);
            return (
              event.id && (
                <article className="media" key={event.id}>
                  <figure className="media-left">
                    <p className="image">
                      <ProfileImage profile={event.profile} />
                    </p>
                  </figure>
                  <div className="media-content">
                    <div className="content">
                      <div className="is-size-6">
                        <Link to={href}>{event.title}</Link>
                      </div>

                      <span className="has-text-grey is-size-6">
                        <small> {timeSince(new Date(event.date))} siden</small>
                      </span>
                    </div>
                  </div>
                </article>
              )
            );
          })}
      </div>
    </div>
  );
};

export default Hjem;
