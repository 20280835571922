import React from 'react';
import JrcLogo from '../../static/jrc_logo.svg';
import { Link, useLocation } from 'react-router-dom';
import ProfileImage from './ProfileImage';
import { useAuth0 } from '../../utils/authContext';
import { useButler } from '../../utils/butlerContext';
import RouteItem from '../../types/RouteItem';
import Spinner from './Spinner';

interface IProps {
  menuItems: RouteItem[];
}

const LeftMenu: React.FC<IProps> = ({ menuItems }) => {
  const location = useLocation();
  const { currentUser } = useButler();
  const { logout, apiUser } = useAuth0();

  if (!currentUser) {
    return <Spinner />;
  }

  const profile = currentUser?.profile;

  return (
    <div className="column left-menu is-fullheight is-hidden-mobile">
      <div className="left-menu__logo">
        <img src={JrcLogo} alt="Logo" className="logo" />
      </div>
      <nav>
        {menuItems
          .filter(item =>
            item.permissionForVisibility ? apiUser?.permissions?.includes(item.permissionForVisibility) : true
          )
          .map(item => (
            <div key={item.title} className={`item ${item.path === location.pathname ? ' active' : ''}`}>
              {item.Component ? <Link to={item.path}>{item.title}</Link> : <a href={item.path}>{item.title}</a>}
            </div>
          ))}
      </nav>
      <div className="user-card">
        <div className="fix-height m-l-10 m-r-10" />
        <div className="columns m-l-5 m-b-5">
          <div className="column is-narrow">
            <ProfileImage profile={profile} />
          </div>

          <div className="column">
            <div>
              <Link to={`/ansatte/${profile.auth_id}`}>{`${profile.first_name} ${profile.last_name}`}</Link>
            </div>
            <div className="has-text-weight-light">
              <div className="logout" onClick={() => logout()}>
                Logg ut
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LeftMenu;
