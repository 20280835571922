const customerSurveryAnswers = {
  // These fields are the same as the ones in models.py for jrc-profiles.
  // If changes are made here, you must also do the same changes in the model.
  NOT_SENT: 'Ikke sendt ut',
  SENT: 'Sendt',
  ANSWERED: 'Besvart',
  NOT_SENDING: 'Skal ikke sendes ut',
  NOT_SET: 'Ikke satt'
};

export default customerSurveryAnswers;
