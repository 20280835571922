import React from 'react';
import { prettyDate, prettyTime } from '../../utils/helpers';
import { library } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHeart } from '@fortawesome/free-solid-svg-icons';
import { IUser } from '@jrc/jrc-butler-api/butler';
library.add(faHeart);

interface Props {
  id: number | null;
  title: string;
  body: string;
  author: IUser | undefined;
  posted_date?: string | null | undefined;
  likes?: string[] | null | undefined;
  onLike: (id: number) => void;
  currentUserId: string | null | undefined;
}

const Article: React.FC<Props> = ({ id, title, body, author, posted_date, likes, onLike, currentUserId }) => {
  const userHasLikedArticle = likes?.some(auth_id => auth_id === currentUserId);
  const heartStyle = {
    opacity: userHasLikedArticle ? 1 : 0.5,
    cursor: 'pointer'
  };
  return (
    <article className="m-b-50">
      <div className="columns is-mobile">
        <div className="column">
          <div className="is-size-4">{title}</div>
          <div className="is-size-7">
            {author?.first_name} - {posted_date && prettyDate(new Date(posted_date))}{' '}
            {posted_date && prettyTime(new Date(posted_date))}
          </div>
          <div
            className="m-t-20"
            dangerouslySetInnerHTML={{
              __html: body
            }}
          />
        </div>

        <div className="column is-narrow has-text-right">
          <img
            alt="Author"
            className="image is-96x96 is-hidden-mobile round"
            src={author?.image ? author.image : 'https://bulma.io/images/placeholders/128x128.png'}
          />

          <div className="m-t-30 has-text-centered has-text-danger" style={heartStyle}>
            <div
              className="icon is-large fa-layers"
              onClick={() => {
                if (!id) return;
                !userHasLikedArticle && onLike(id);
              }}
            >
              <FontAwesomeIcon icon="heart" size="3x" />

              <span
                className="has-text-weight-bold"
                style={{
                  position: 'absolute',
                  color: 'white',
                  top: '50%',
                  left: '50%',
                  transform: 'translate(-50%,-50%)',
                  width: 20,
                  fontSize: 14
                }}
              >
                {likes?.length}
              </span>
            </div>
          </div>
        </div>
      </div>
    </article>
  );
};

export default Article;
