import React, { useEffect, useState, useCallback } from 'react';
import { useButler } from '../../utils/butlerContext';
import IframeResizer from 'iframe-resizer-react';
import * as jwt from 'jsonwebtoken';

interface JuniorInnsiktProps {}

const METABASE_SITE_URL = 'https://metabase.jrc.no';

const JuniorInnsikt = (props: JuniorInnsiktProps) => {
  const [jwtToken, setJwtToken] = useState('');
  const { metaService } = useButler();

  const getToken = useCallback(async () => {
    const metabaseToken = await metaService.getToken();
    const payload = {
      resource: { dashboard: 2 },
      params: {}
    };
    const jwtToken = jwt.sign(payload, metabaseToken.token);
    setJwtToken(jwtToken);
  }, [metaService]);

  useEffect(() => {
    getToken();
  }, [getToken]);

  var iframeUrl = METABASE_SITE_URL + '/embed/dashboard/' + jwtToken + '#bordered=false&titled=true';
  return (
    <div>
      <IframeResizer
        title={'JuniorInnsikt'}
        style={{ width: '1px', minWidth: '100%' }}
        src={iframeUrl}
        frameBorder={0}
      ></IframeResizer>
    </div>
  );
};

export default JuniorInnsikt;
