import React, { useEffect, useState } from 'react';
import Modal from '../shared/Modal';
import ProjectMemberList from './ProjectMemberList';
import MemberSelect from '../shared/MemberSelect';
import differenceBy from 'lodash/differenceBy';
import { IUser, MemberRole } from '@jrc/jrc-butler-api/butler';
import { Member } from 'types/Member';

interface IProps {
  members: Member[];
  profiles: IUser[];
  onSave: ({ members }: { members: any }) => void;
  isActive: boolean;
  onClose: () => void;
  isLoading: boolean;
}

const TeamMemberForm: React.FC<IProps> = ({ members, profiles, onSave, isActive, onClose, isLoading }) => {
  const [selectedMembers, setSelectedMembers] = useState<any>(members);

  useEffect(() => {
    setSelectedMembers(members);
  }, [members]);

  const didClickSave = (): void => {
    onSave({
      members: selectedMembers
    });
  };

  const changeRoleForMember = (memberId: string, newRole: MemberRole): void => {
    setSelectedMembers(
      selectedMembers.map((member: any) => {
        if (member.auth_id !== memberId) return member;
        return {
          ...member,
          role: newRole
        };
      })
    );
  };

  const addMember = (member_id: string): void => {
    const newMember = profiles.find(profile => profile.auth_id === member_id);
    setSelectedMembers([
      ...selectedMembers,
      {
        ...newMember,
        role: 'K'
      }
    ]);
  };

  const removeMember = (member_id: string): void => {
    setSelectedMembers(selectedMembers.filter((member: any) => member.auth_id !== member_id));
  };

  const notSelectedMembers = differenceBy(profiles, selectedMembers, 'auth_id');

  return (
    <Modal title="Team" isActive={isActive} onClose={onClose} onSave={didClickSave} isLoading={isLoading}>
      <MemberSelect addMember={addMember} notSelectedMembers={notSelectedMembers} />
      <ProjectMemberList
        selectedMembers={selectedMembers}
        setRoleForMember={changeRoleForMember}
        removeMember={removeMember}
      />
    </Modal>
  );
};

export default TeamMemberForm;
