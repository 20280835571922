import { MemberRole } from '@jrc/jrc-butler-api/butler';
import React from 'react';

interface IProps {
  removeMember: (auth_id: string) => void;
  selectedMembers: any;
  setRoleForMember: (memberId: string, newRole: MemberRole) => void;
}

const ProjectMemberList: React.FC<IProps> = (props: IProps) => {
  const { selectedMembers, setRoleForMember } = props;

  const removeMember = (member: any): void => {
    props.removeMember(member.auth_id);
  };

  return (
    <div className="selected-member-list">
      <table className="table is-fullwidth">
        <thead>
          <tr>
            <th>Navn</th>
            <th>Rolle</th>
            <th />
          </tr>
        </thead>
        <tbody>
          {selectedMembers &&
            selectedMembers.map((member: any, i: number) => {
              const didChangeRole = (e: React.ChangeEvent<HTMLSelectElement>) =>
                setRoleForMember(member.auth_id, e.target.value as MemberRole);
              return (
                <tr key={i}>
                  <td>
                    {member.first_name} {member.last_name}
                  </td>
                  <td>
                    <div className="field select">
                      <select value={member.role} onChange={didChangeRole}>
                        <option disabled value="None">
                          Velg rolle
                        </option>
                        <option value="K">Konsulent</option>
                        <option value="PL">Prosjektleder</option>
                        <option value="AP">Ansvarlig partner</option>
                      </select>
                    </div>
                  </td>
                  <td onClick={() => removeMember(member)}>
                    <div className="delete" />
                  </td>
                </tr>
              );
            })}
        </tbody>
      </table>
    </div>
  );
};

export default ProjectMemberList;
