import React from 'react';
import ReactDOM from 'react-dom';
import './styles/global.scss';
import App from './App';
import { history } from './utils/history';

import { AUTH0_AUDIENCE, AUTH0_CLIENT_ID, AUTH0_DOMAIN, AUTH0_LEEWAY } from './config';
import { Auth0Provider } from './utils/authContext';
import { RedirectLoginResult } from '@auth0/auth0-spa-js';

const onAuthRedirectCallback = (redirectResult?: RedirectLoginResult) => {
  const targetUrl =
    redirectResult && redirectResult.appState && redirectResult.appState.targetUrl
      ? redirectResult.appState.targetUrl
      : window.location.pathname;

  history.push(targetUrl);
};

ReactDOM.render(
  <Auth0Provider
    domain={AUTH0_DOMAIN}
    client_id={AUTH0_CLIENT_ID}
    redirect_uri={window.location.origin}
    audience={AUTH0_AUDIENCE}
    leeway={AUTH0_LEEWAY}
    onRedirectCallback={onAuthRedirectCallback}
  >
    <App />
  </Auth0Provider>,
  document.getElementById('root')
);
