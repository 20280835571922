import React, { useState } from 'react';
import Modal from '../shared/Modal';

interface IProps {
  onSave: (name: string) => void;
  onClose: () => void;
  isActive: boolean;
  isLoading: boolean;
}

const NewProjectForm: React.FC<IProps> = (props: IProps) => {
  const { isActive, onClose, isLoading } = props;
  const [name_ref, setName_ref] = useState<HTMLInputElement | null>(null);

  const didClickSave = (): void => {
    const name: string = name_ref ? name_ref.value : '';
    props.onSave(name);
  };

  return (
    <Modal title="Opprett prosjekt" isActive={isActive} onClose={onClose} onSave={didClickSave} isLoading={isLoading}>
      <div className="field">
        <div className="control">
          <input ref={ref => setName_ref(ref)} className="input" placeholder="Prosjektnavn" />
        </div>
      </div>
    </Modal>
  );
};

export default NewProjectForm;
