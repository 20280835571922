import React from 'react';

// If a certain Markdown-tag doesn't render,
// it's probably because we're using Bulma, and you should make a custom renderer.
// Check out the docs here https://github.com/rexxars/react-markdown

interface MarkdownListProps {
  ordered: boolean;
  depth: number;
  children?: React.ReactNode;
}
interface BlockquoteProps {
  children?: React.ReactNode;
}

interface HeadingProps {
  level: number;
  children?: React.ReactNode;
}

type Header = 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6';

const Heading: React.FC<HeadingProps> = ({ children, level }) => {
  const TagName = `h${level}` as Header;
  return <TagName className={`title is-${level} has-text-weight-medium`}>{children}</TagName>;
};

const Blockquote: React.FC<BlockquoteProps> = ({ children }) => <div className="m-t-5 blockquote">{children}</div>;

const ThematicBreak: React.FC = () => <hr className="thematicBreak" />;

const MarkdownList: React.FC<MarkdownListProps> = ({ ordered, depth, children }) => {
  const TagName = ordered ? 'ol' : 'ul';
  return depth === 0 ? (
    <div className="content">
      <TagName>{children}</TagName>
    </div>
  ) : (
    <TagName>{children}</TagName>
  );
};

export { Heading, Blockquote, ThematicBreak, MarkdownList };
