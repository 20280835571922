import React from 'react';

interface IProps {
  text: React.ReactNode;
  color: string;
}

const Badge: React.FC<IProps> = ({ text, color }: IProps) => (
  <div
    style={{
      display: 'inline',
      color: 'white',
      backgroundColor: color,
      padding: '3px 7px',
      borderRadius: '4px',
      fontWeight: 'normal',
      margin: '5px',
      height: '100%',
      fontSize: '0.6em'
    }}
  >
    {text}
  </div>
);

export default Badge;
