import React, { useState, useEffect, useContext, useRef } from 'react';
import createAuth0Client, {
  Auth0ClientOptions,
  GetTokenSilentlyOptions,
  IdToken,
  LogoutOptions,
  RedirectLoginOptions,
  RedirectLoginResult
} from '@auth0/auth0-spa-js';
import Auth0Client from '@auth0/auth0-spa-js/dist/typings/Auth0Client';
import jwt_decode from 'jwt-decode';

export interface RegistrationStatus {
  isEmailValid: boolean;
  isEmailVerified: boolean;
}

export interface Auth0RedirectState {
  targetUrl?: string;
}

export interface Auth0User extends Omit<IdToken, '__raw'> {}

export interface ApiUser {
  sub?: string;
  exp?: string;
  permissions?: string[];
}

interface AuthContextProps {
  user?: Auth0User;
  isAuthenticated: boolean;
  isInitializing: boolean;
  apiToken?: string;
  apiUser?: ApiUser;
  registrationStatus?: RegistrationStatus;
  loginWithRedirect(o?: RedirectLoginOptions): Promise<void>;
  getTokenSilently(o?: GetTokenSilentlyOptions): Promise<string | undefined>;
  logout(o?: LogoutOptions): void;
}

interface Auth0ProviderOptions {
  children: React.ReactNode;
  onRedirectCallback(result: RedirectLoginResult): void;
}

export const Auth0Context = React.createContext<AuthContextProps>({
  isAuthenticated: false,
  isInitializing: false,
  loginWithRedirect: () => {
    return Promise.reject();
  },
  getTokenSilently: () => {
    return Promise.reject();
  },
  logout: () => {}
});

export const useAuth0 = () => useContext(Auth0Context);

export const Auth0Provider = ({
  children,
  onRedirectCallback,
  ...initOptions
}: Auth0ProviderOptions & Auth0ClientOptions) => {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [isInitializing, setIsInitializing] = useState(true);
  const [user, setUser] = useState<Auth0User>();
  const [apiToken, setApiToken] = useState<string | undefined>(undefined);
  const [apiUser, setApiUser] = useState<ApiUser | undefined>(undefined);
  const [auth0Client, setAuth0Client] = useState<Auth0Client>();
  const [registrationStatus, setRegistrationStatus] = useState<RegistrationStatus | undefined>(undefined);

  const initOptionsStaticReference = useRef(initOptions);

  useEffect(() => {
    const initAuth0 = async () => {
      const auth0FromHook = await createAuth0Client(initOptionsStaticReference.current);
      setAuth0Client(auth0FromHook);

      if (window.location.search.includes('code=')) {
        let appState: RedirectLoginResult = {};
        try {
          const res = await auth0FromHook.handleRedirectCallback();
          res.appState && (appState = res.appState);
        } finally {
          onRedirectCallback(appState);
        }
      }

      const authed = await auth0FromHook.isAuthenticated();

      if (!authed) {
        const isEmailValid = !window.location.search.includes('error_description=invalid_email');
        const isEmailVerified = !window.location.search.includes('error_description=verify_email');

        if (!isEmailValid || !isEmailVerified)
          setRegistrationStatus({
            isEmailValid,
            isEmailVerified
          });
      }

      if (authed) {
        const userProfile = await auth0FromHook.getUser();
        const apiToken: string = await auth0FromHook.getTokenSilently();
        const apiUser = jwt_decode<ApiUser>(apiToken);

        setApiUser(apiUser);
        setUser(userProfile);
        setApiToken(apiToken);
        setIsAuthenticated(true);
      }

      setIsInitializing(false);
    };

    initAuth0();
  }, [initOptionsStaticReference, onRedirectCallback]);

  const loginWithRedirect = (options?: RedirectLoginOptions) => auth0Client!.loginWithRedirect(options);

  const getTokenSilently = (options?: GetTokenSilentlyOptions) => auth0Client!.getTokenSilently(options);

  const logout = (options?: LogoutOptions) => {
    const logoutOptions: LogoutOptions = {
      returnTo: window.location.protocol + '//' + window.location.host
    };

    return auth0Client!.logout({ ...logoutOptions, ...options });
  };

  return (
    <Auth0Context.Provider
      value={{
        user,
        isAuthenticated,
        isInitializing,
        apiToken,
        apiUser,
        registrationStatus,
        loginWithRedirect,
        logout,
        getTokenSilently
      }}
    >
      {children}
    </Auth0Context.Provider>
  );
};
