import React, { useEffect, useState } from 'react';
import ArticleForm from '../../components/nyheter/ArticleForm';
import { history } from '../../utils/history';
import { useButler } from '../../utils/butlerContext';

interface IProps {
  setIsLoading: (isLoading: boolean) => void;
  title: String;
  isLoading: Boolean;
}

const NewArticlePage: React.FC<IProps> = ({ setIsLoading, title, isLoading }) => {
  const butler = useButler();
  const newsService = butler.newsService;

  const [isClient, setIsClient] = useState(false);

  useEffect(() => {
    document.title = `JrC - ${title}`;

    setIsClient(true);
    setTimeout(() => setIsLoading(false), 500);
  }, [setIsClient, setIsLoading, title]);

  const submitContent = async (title: string, markup: string, published: boolean) => {
    setIsLoading(true);

    const news = { title, body: markup, published };
    await newsService.createNews(news);

    setIsLoading(false);
    history.push('/');
  };

  if (!isClient) return null;

  return <div>{!isLoading && <ArticleForm isLoading={isLoading} submitContent={submitContent} />}</div>;
};

export default NewArticlePage;
