import {
  IClientCommentEntity,
  IEvent,
  IProject,
  IProjectComment,
  IProjectCommentEntity,
  IUser
} from '@jrc/jrc-butler-api/butler';
import React, { useCallback, useEffect, useState } from 'react';

import CommentForm from '../shared/CommentForm';
import CommentList from '../shared/CommentList';
import { useButler } from '../../utils/butlerContext';

interface IProps {
  project: IProject;
  profiles: IUser[];
}

const ProjectCommentBox: React.FC<IProps> = (props: IProps) => {
  const butlerContext = useButler();

  const [comments, setComments] = useState<IProjectComment[]>(props.project.comments ?? []);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const setStateWithLoadData = useCallback(async () => {
    setIsLoading(false);
    const { project } = props;
    const newComments = await butlerContext.projectCommentService.getProjectComments(project.id ?? 0);
    setComments(newComments);
  }, [butlerContext, setIsLoading, props]);

  useEffect(() => {
    setStateWithLoadData();
  }, [setStateWithLoadData, props]);

  const createProjectComment = async (newComment: IProjectCommentEntity | IClientCommentEntity) => {
    setIsLoading(true);
    const { project } = props;
    const { currentUser } = butlerContext;
    project.id &&
      (await butlerContext.projectCommentService.createProjectComment({ ...newComment, project_id: project.id }));
    setStateWithLoadData();

    let actionText = `${currentUser ? currentUser.profile.first_name : ''} kommenterte prosjektet ${project.name}`;
    project.id && createEvent(actionText, project.id);
    setIsLoading(false);
  };

  const updateProjectComment = async (updatedComment: IProjectCommentEntity | IClientCommentEntity) => {
    setIsLoading(true);
    const { project } = props;
    const { currentUser } = butlerContext;
    project.id &&
      updatedComment.id &&
      (await butlerContext.projectCommentService.updateProjectComment(
        { ...updatedComment, project_id: project.id },
        updatedComment.id
      ));

    setStateWithLoadData();

    const actionText = `${currentUser ? currentUser.profile.first_name : ''} oppdaterte kommentaren på prosjektet ${
      project.name
    }`;
    project.id && createEvent(actionText, project.id);
    setIsLoading(false);
  };

  const deleteProjectComment = async (commentId: number) => {
    setIsLoading(true);
    const { project } = props;
    const { currentUser } = butlerContext;
    await butlerContext.projectCommentService.deleteProjectComment(commentId);
    setStateWithLoadData();

    let actionText = `${currentUser ? currentUser.profile.first_name : ''} slettet kommentaren på projektet ${
      project.name
    }`;
    project.id && createEvent(actionText, project.id);
    setIsLoading(false);
  };

  const createEvent = async (actionText: string, entity_id: number) => {
    const { currentUser } = butlerContext;

    try {
      if (currentUser) {
        const event: IEvent = {
          type: 'project',
          title: actionText,
          description: '',
          entity_id: entity_id,
          change_by: currentUser.profile.auth_id ?? '',
          image: currentUser.profile.image ?? '',
          icon: 'pencil',
          date: new Date().toISOString()
        };
        butlerContext.eventService.createEvent(event);
      }
    } catch (error) {
      console.log('Error posting event: ' + error);
    }
  };

  const { profiles } = props;
  return (
    <div className="is-fullwidth">
      <h2 className="is-size-5 is-5-tablet m-t-100">Kommentarer</h2>
      <div className="CommentBox m-t-10">
        <CommentList
          comments={comments}
          profiles={profiles}
          isLoading={isLoading}
          onDelete={(commentId: number) => deleteProjectComment(commentId)}
          onUpdate={(updatedComment: IProjectCommentEntity | IClientCommentEntity) =>
            updateProjectComment(updatedComment)
          }
        />
        <CommentForm onSave={createProjectComment} isLoading={isLoading} maxLength={3000} />
      </div>
    </div>
  );
};

export default ProjectCommentBox;
