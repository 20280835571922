import React, { useState } from 'react';
import { IResources } from '../../types/Resources';
import Modal from '../shared/Modal';

interface IOnSave {
  slack?: string | null;
  gitlab?: string | null;
  trello?: string | null;
  google_drive?: string | null;
  other_link?: string | null;
}

interface IProps {
  isLoading: boolean;
  isActive: boolean;
  onClose: () => void;
  onSave: (ob: IOnSave) => void;
  resources: IResources;
}

const ProjectResourceForm: React.FC<IProps> = (props: IProps) => {
  const { resources, isLoading, isActive, onClose } = props;

  const [slackInput, setSlackInput] = useState<HTMLInputElement | null>(null);
  const [gitLabInput, setGitLabInput] = useState<HTMLInputElement | null>(null);
  const [trelloInput, setTrelloInput] = useState<HTMLInputElement | null>(null);
  const [googleDriveInput, setGoogleDriveInput] = useState<HTMLInputElement | null>(null);
  const [otherInput, setOtherInput] = useState<HTMLInputElement | null>(null);

  const didClickSave = () => {
    const slack = slackInput && slackInput.value;
    const gitlab = gitLabInput && gitLabInput.value;
    const trello = trelloInput && trelloInput.value;
    const google_drive = googleDriveInput && googleDriveInput.value;
    const other_link = otherInput && otherInput.value;
    props.onSave({ slack, gitlab, trello, google_drive, other_link });
  };

  return (
    <Modal title="Prosjektressurser" onSave={didClickSave} isLoading={isLoading} isActive={isActive} onClose={onClose}>
      <div className="field">
        <label className="label">Slack</label>
        <div className="control">
          <input
            className="input"
            ref={ref => setSlackInput(ref)}
            defaultValue={resources.slack ?? ''}
            type="text"
            placeholder="#awesome-project"
          />
        </div>
      </div>

      <div className="field">
        <label className="label">Gitlab</label>
        <div className="control">
          <input
            className="input"
            ref={ref => setGitLabInput(ref)}
            defaultValue={resources.github_repo ?? ''}
            type="email"
            placeholder="e.g. https://git.jrc.no/my-awesome-project"
          />
        </div>
      </div>

      <div className="field">
        <label className="label">Trello</label>
        <div className="control">
          <input
            className="input"
            ref={ref => setTrelloInput(ref)}
            defaultValue={resources.trello ?? ''}
            type="email"
            placeholder="e.g. https://trello.com/my-board"
          />
        </div>
      </div>

      <div className="field">
        <label className="label">Google Drive</label>
        <div className="control">
          <input
            className="input"
            ref={ref => setGoogleDriveInput(ref)}
            defaultValue={resources.google_drive ?? ''}
            type="email"
            placeholder="e.g. https://drive.google.com/long-ass-hash"
          />
        </div>
      </div>
      <div className="field">
        <label className="label">Diverse</label>
        <div className="control">
          <input
            className="input"
            ref={ref => setOtherInput(ref)}
            defaultValue={resources.other_link ?? ''}
            type="email"
            placeholder="e.g. https://test.test.no/test/xx"
          />
        </div>
      </div>
    </Modal>
  );
};

export default ProjectResourceForm;
