import React from 'react';
import JrcLogoWithText from '../../static/jrc_logo_letters.svg';
import { useAuth0 } from '../../utils/authContext';

const ValidateEmail: React.FC = () => {
  const { logout } = useAuth0();

  return (
    <div className="login-container">
      <div className="login-form-container">
        <div className="has-text-centered">
          <img className="jrc-logo" src={JrcLogoWithText} alt="Logo" />
        </div>
        <h3>Du må ha en @juniorconsulting epost for å logge inn på internarena</h3>
        <div className="m-t-20 has-text-right">
          <button className="button is-primary" onClick={() => logout()}>
            Logg ut
          </button>
        </div>
      </div>
    </div>
  );
};

export default ValidateEmail;
