import React, { useState } from 'react';
import Modal from './Modal';

interface IOnSave {
  description: string;
}

interface IProps {
  onSave: (ob: IOnSave) => void;
  description: string;
  isActive: boolean;
  onClose: () => void;
  isLoading?: boolean;
  title: string;
  placeholder: string;
}

const DescriptionForm: React.FC<IProps> = (props: IProps) => {
  const { description, isActive, onClose, isLoading, title, placeholder } = props;
  const [descriptionRef, setDescriptionRef] = useState<HTMLTextAreaElement | null>(null);

  const didClickSave = () => {
    const description = descriptionRef && descriptionRef.value;
    description && props.onSave({ description });
  };

  return (
    <Modal title={title} isActive={isActive} onClose={onClose} onSave={didClickSave} isLoading={isLoading}>
      <div className="field">
        <div className="control">
          <textarea
            defaultValue={description}
            ref={ref => setDescriptionRef(ref)}
            className="textarea"
            placeholder={placeholder}
          />
        </div>
      </div>
    </Modal>
  );
};

export default DescriptionForm;
