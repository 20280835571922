import React, { Fragment } from 'react';
import { Link } from 'react-router-dom';
import { IUser, MemberRole } from '@jrc/jrc-butler-api/butler';
import { IUserWithRole } from '../../types/UserWithRole';

interface IProps {
  members: Array<IUser | IUserWithRole | undefined> | undefined;
}

const PROJECT_ROLES: { [id in MemberRole]: string } = {
  K: 'Konsulent',
  PL: 'Prosjektleder',
  AP: 'Ansvarlig partner',
  IK: ''
};

const MemberList: React.FC<IProps> = ({ members }) => (
  <Fragment>
    {members?.map(
      (member, index) =>
        member && (
          <div className="column is-2-desktop is-6-tablet is-6-mobile has-text-centered" key={index}>
            <div>
              <img
                className="round member-picture"
                alt="Profile"
                src={member.image ? member.image : 'https://bulma.io/images/placeholders/128x128.png'}
              />
            </div>
            <div className="is-size-7">
              <Link to={`/ansatte/${member.auth_id}`}>
                {member.first_name} {member.last_name}
              </Link>
            </div>
            <div className="is-size-7 has-text-grey">{'role' in member ? PROJECT_ROLES[member.role] : ''}</div>
          </div>
        )
    )}
  </Fragment>
);

export default MemberList;
