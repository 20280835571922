import React, { useState } from 'react';
import Alert from 'react-s-alert';
import InvoiceNumberForm from '../shared/InvoiceNumberForm';
import DownloadHoursForm from './DownloadHoursForm';
import MODAL_TYPES from './modalTypes';
import { prettyDate } from '../../utils/helpers';
import { library } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes, faCheck } from '@fortawesome/free-solid-svg-icons';
import { useButler } from '../../utils/butlerContext';
import { ITime, IUser } from '@jrc/jrc-butler-api/butler';
import { IHoursWithEmployeeName } from '../../types/Hours';
library.add(faTimes, faCheck);

interface IProps {
  members: any;
  hours: ITime[];
  profiles: IUser[];
  rowLimit: number;
  projectId: number;
}

const ProjectHours: React.FC<IProps> = (props: IProps) => {
  const butlerContext = useButler();

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isLoadingMore, setIsLoadingMore] = useState<boolean>(false);
  const [markAll, setMarkAll] = useState<boolean>(true);
  const [currentModal, setCurrentModal] = useState<null | string>(null);
  const [ids, setIds] = useState<number[]>([]);
  const [rowLimit, setRowLimit] = useState<number>(props.rowLimit);
  const [showMore, setShowMore] = useState<boolean>(false);

  const updateWorkHour = async (updatedData: any) => {
    setIsLoading(true);

    const timeService = butlerContext.timeService;
    await timeService.updateTime(updatedData.id, updatedData);
    Alert.success('Timen ble oppdatert!');
    setIsLoading(false);
  };

  const closeModal = () => {
    setCurrentModal(null);
  };

  const calculateWorkHoursStatistics = (hours: ITime[]) => {
    const numHours =
      Math.round(
        (hours.length > 0
          ? hours.reduce((sum, project) => (project.hours === 'NaN' ? 0 : sum + project.hours), 0)
          : 0) * 100
      ) / 100;
    const paidHours =
      Math.round(
        (hours.length > 0
          ? hours.reduce(
              (sum, project) => (project.paid ? (project.hours === 'NaN' ? 0 : sum + project.hours) : sum),
              0
            )
          : 0) * 100
      ) / 100;
    const outstandingHours =
      Math.round(
        (hours.length > 0
          ? hours.reduce(
              (sum, project) => (project.invoiceId ? sum : project.hours === 'NaN' ? 0 : sum + project.hours),
              0
            )
          : 0) * 100
      ) / 100;
    const billedHours = numHours - outstandingHours;
    return { numHours, paidHours, outstandingHours, billedHours };
  };

  const addInvoiceNumber = async (invoiceNumber: string) => {
    const promises: Promise<void>[] = [];
    ids.forEach(id => {
      let hourEntry = props.hours.find(hour => hour.id === id);
      if (hourEntry) {
        hourEntry.invoiceId = invoiceNumber;
        promises.push(updateWorkHour(hourEntry));
      }
    });
    await Promise.all(promises);
    closeModal();
  };

  const markAsPaid = async () => {
    const promises: Promise<void>[] = [];
    ids.forEach(id => {
      let hourEntry = props.hours.find(hour => hour.id === id);
      if (hourEntry) {
        hourEntry.paid = !hourEntry.paid;
        promises.push(updateWorkHour(hourEntry));
      }
    });
    await Promise.all(promises);
  };

  const isPaid = (paid: boolean): JSX.Element => {
    if (paid) return <FontAwesomeIcon icon="check" />;
    return <FontAwesomeIcon icon="times" />;
  };

  const handleSelectUnbilled = (hoursWithEmployeeName: IHoursWithEmployeeName[]) => {
    const length = hoursWithEmployeeName.length < rowLimit ? hoursWithEmployeeName.length : rowLimit;
    const unbilledIds: number[] = hoursWithEmployeeName
      .slice(0, length)
      .filter(hour => !ids.includes(hour.id ?? 0))
      .filter(hour => !hour.invoiceId)
      .map(hour => hour.id ?? 0);

    setIds([...ids, ...unbilledIds]);
  };

  const handleSelectAll = (hoursWithEmployeeName: IHoursWithEmployeeName[]) => {
    const length = hoursWithEmployeeName.length < rowLimit ? hoursWithEmployeeName.length : rowLimit;
    setMarkAll(prevValue => !prevValue);
    if (markAll) {
      const idsToAdd = hoursWithEmployeeName
        .slice(0, length)
        .filter(hour => !ids.includes(hour.id ?? 0))
        .map(hour => hour.id ?? 0);
      setIds([...ids, ...idsToAdd]);
    } else {
      setIds([]);
    }
  };

  const handleSelectUnpaid = (hoursWithEmployeeName: IHoursWithEmployeeName[]) => {
    const length = hoursWithEmployeeName.length < rowLimit ? hoursWithEmployeeName.length : rowLimit;
    const unpaidIds: number[] = hoursWithEmployeeName
      .slice(0, length)
      .filter(hour => !ids.includes(hour.id ?? 0))
      .filter(hour => !hour.paid)
      .map(hour => hour.id ?? 0);
    setIds([...ids, ...unpaidIds]);
  };

  const handleSelect = (id: number) => {
    if (!ids.includes(id)) {
      setIds([...ids, id]);
    } else {
      setIds(ids.filter(prevId => prevId !== id));
    }
  };

  const showMoreFunc = (length: number) => {
    setIsLoadingMore(true);
    setRowLimit(length);
    setShowMore(true);
    setIsLoadingMore(false);
  };

  const showLess = () => {
    setRowLimit(props.rowLimit);
    setShowMore(false);
  };

  const downloadHours = async (fromDate: string, toDate: string) => {
    setIsLoading(true);
    const timeService = butlerContext.timeService;
    const query = `${props.projectId},${fromDate},${toDate}`;
    const csv_list = await timeService.exportWorkHours(query);
    let csv = '';
    csv_list.forEach(row => (csv += row + '\n'));
    Alert.success('Lastet ned timer!');

    let element = document.createElement('a');
    element.setAttribute('href', 'data:text/csv;charset=utf-8,' + encodeURIComponent(csv));
    element.setAttribute('download', 'timer.csv');

    element.style.display = 'none';
    document.body.appendChild(element);

    element.click();

    document.body.removeChild(element);
    setIsLoading(false);
  };

  const renderAdminWorkHours = (hoursWithEmployeeName: IHoursWithEmployeeName[]): React.ReactNode => {
    let showMoreBool = showMore && hoursWithEmployeeName.length > props.rowLimit;
    let showLessBool = !showMore && hoursWithEmployeeName.length > props.rowLimit;
    return (
      <div>
        <InvoiceNumberForm
          isActive={currentModal === MODAL_TYPES.INVOICE_NUMBER}
          isLoading={isLoading}
          onClose={closeModal}
          onSave={addInvoiceNumber}
        />
        <DownloadHoursForm
          isActive={currentModal === MODAL_TYPES.DOWNLOAD_HOURS}
          isLoading={isLoading}
          onClose={closeModal}
          onSave={downloadHours}
        />
        <button
          className="button project-button mark-button is-primary is-small"
          onClick={() => handleSelectAll(hoursWithEmployeeName)}
        >
          Marker alle timer
        </button>
        <button
          className="button project-button mark-button is-primary is-small"
          onClick={() => handleSelectUnpaid(hoursWithEmployeeName)}
        >
          Marker ubetalte timer
        </button>
        <button
          className="button project-button mark-button is-primary is-small"
          onClick={() => handleSelectUnbilled(hoursWithEmployeeName)}
        >
          Marker ufakturerte
        </button>
        <button
          className="button project-button is-primary is-small"
          onClick={() => setCurrentModal(MODAL_TYPES.INVOICE_NUMBER)}
        >
          Fakturer valgte
        </button>
        <button className="button project-button is-primary is-small m-l-10" onClick={markAsPaid}>
          Merk valgte som betalt
        </button>
        <button
          className="button project-button is-primary is-small m-l-10"
          onClick={() => setCurrentModal(MODAL_TYPES.DOWNLOAD_HOURS)}
        >
          Last ned timer
        </button>

        <table className="table is-fullwidth is-striped">
          <thead>
            <tr>
              <th>&nbsp;</th>
              <th>Dato</th>
              <th>Ansatt</th>
              <th>Antall timer</th>
              <th>Beskrivelse</th>
              <th>Tags</th>
              <th>Faktura</th>
              <th>Utbetalt</th>
            </tr>
          </thead>
          <tbody>
            {hoursWithEmployeeName.length > 0 ? (
              hoursWithEmployeeName
                .sort((a, b) => (new Date(a.date) > new Date(b.date) || (a.id ?? 0) > (b.id ?? 0) ? -1 : 1))
                .slice(0, rowLimit)
                .map(hour => (
                  <tr key={hour.id ?? 0}>
                    <td className="field">
                      <input
                        id={(hour.id ?? '') as string}
                        type="checkbox"
                        value={hour.id ?? 0}
                        checked={ids.includes(hour.id ?? 0)}
                        onClick={() => handleSelect(hour.id ?? 0)}
                      />
                    </td>
                    <td>{prettyDate(new Date(hour.date))}</td>
                    <td>{hour.employee_name}</td>
                    <td>{hour.hours}</td>
                    <td>{hour.description}</td>
                    <td>{hour.tag}</td>
                    <td>{hour.invoiceId}</td>
                    <td className="has-text-centered">{isPaid(hour.paid ?? true)}</td>
                  </tr>
                ))
            ) : (
              <tr />
            )}
          </tbody>
        </table>
        {showMoreBool ? (
          <button className={'button is-black' + (isLoadingMore ? ' is-loading' : '')} onClick={() => showLess()}>
            Vis mindre
          </button>
        ) : (
          ''
        )}
        {showLessBool ? (
          <button
            className={'button is-black' + (isLoadingMore ? ' is-loading' : '')}
            onClick={() => showMoreFunc(hoursWithEmployeeName.length)}
          >
            Vis mer
          </button>
        ) : (
          ''
        )}
      </div>
    );
  };

  const { numHours, outstandingHours, billedHours } = calculateWorkHoursStatistics(props.hours);

  const hours = Array.isArray(props.hours) ? props.hours : [];
  let hoursWithEmployeeName: IHoursWithEmployeeName[] = hours.map(hour => {
    const profile = props.profiles.find(profile => profile.auth_id === hour.authId.toString());
    const outHour = hour as IHoursWithEmployeeName;
    outHour.employee_name = `${profile ? profile.first_name : ''} ${profile ? profile.last_name : ''}`;
    return outHour;
  });

  return (
    <div className="column is-full is-hidden-mobile">
      <div className="column is-multiline">
        <h5 className="title is-5">Timeføring</h5>

        <div className="level m-t-20 m-b-50">
          <div className="level-item has-text-centered">
            <div>
              <p className="heading">Tot. timer</p>
              <p className="title">{numHours}</p>
            </div>
          </div>
          <div className="level-item has-text-centered">
            <div>
              <p className="heading">Fakturerte timer</p>
              <p className="title">{billedHours.toFixed(2)}</p>
            </div>
          </div>
          <div className="level-item has-text-centered">
            <div>
              <p className="heading">Utestående timer</p>
              <p className="title">{outstandingHours}</p>
            </div>
          </div>
        </div>
        <div>{renderAdminWorkHours(hoursWithEmployeeName)}</div>
      </div>
    </div>
  );
};

export default ProjectHours;
