import React from 'react';
import JrcLogoWithText from '../../static/jrc_logo_letters.svg';
import { useAuth0 } from '../../utils/authContext';

const VerifyEmail: React.FC = () => {
  const { logout } = useAuth0();

  return (
    <div className="login-container">
      <div className="login-form-container">
        <div className="has-text-centered">
          <img className="jrc-logo" src={JrcLogoWithText} alt="Logo" />
        </div>
        <h3>
          <p>Du må bekrefte eposten din før du kan logge inn på internarena.</p>
          <p>Ta kontakt med IT dersom du ikke har mottatt mail.</p>
        </h3>
        <div className="m-t-20 has-text-centered">
          <button className="button is-primary" onClick={() => logout()}>
            Jeg har verifisert eposten min
          </button>
        </div>
      </div>
    </div>
  );
};

export default VerifyEmail;
