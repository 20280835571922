import React, { useState } from 'react';
import Modal from './Modal';

interface IProps {
  onSave: (invoice_number: string) => void;
  isActive: boolean;
  onClose: () => void;
  isLoading: boolean;
}

const InvoiceNumberForm: React.FC<IProps> = (props: IProps) => {
  const { isActive, onClose, isLoading } = props;
  const [invoiceNumber, setInvoiceNumber] = useState('');

  const didClickSave = () => {
    if (!invoiceNumber) {
      return;
    }
    props.onSave(invoiceNumber);
  };

  return (
    <Modal title="Fakturer timer" isActive={isActive} onClose={onClose} onSave={didClickSave} isLoading={isLoading}>
      <div className="field">
        <div className="control">
          <input
            type="text"
            className="input"
            value={invoiceNumber}
            onChange={e => setInvoiceNumber(e.target.value)}
            placeholder="Fakturanummer"
          />
        </div>
      </div>
    </Modal>
  );
};

export default InvoiceNumberForm;
