import { CurrentUser, useButler } from '../../utils/butlerContext';
import { IClientCommentEntity, IProjectCommentEntity } from '@jrc/jrc-butler-api/butler';
import React, { useState } from 'react';

import Alert from 'react-s-alert';
import { Link } from 'react-router-dom';
import ProfileImage from './ProfileImage';

interface IProps {
  maxLength: number;
  isLoading: boolean;
  onSave: (newComment: IProjectCommentEntity | IClientCommentEntity) => Promise<void>;
}

const CommentForm: React.FC<IProps> = ({ maxLength, onSave, isLoading }) => {
  const currentUser = useButler().currentUser as CurrentUser;

  const [text, setText] = useState<string>('');
  const [chars_left, setChars_left] = useState<number>(maxLength);

  const handleChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    let input = event.currentTarget.value;
    setText(input);
    setChars_left(maxLength - input.length);
  };

  const didClickSave = () => {
    let auth_id = currentUser.profile.auth_id ?? '';
    if (!text || text.length === 0) {
      Alert.error('Kommentar kan ikke være tom');
    } else {
      onSave({ author_id: auth_id, text, project_id: 0 });
    }
    setText('');
  };

  return (
    <div>
      <div className="CommentForm small-profile-image">
        <div className="columns">
          <div className="colum m-t-5">
            <ProfileImage profile={currentUser.profile} />
          </div>
          <div className="column is-narrow m-t-5">
            <div className="has-text-semibold black">
              <Link
                to={`/ansatte/${currentUser.profile.auth_id}`}
              >{`${currentUser.profile.first_name} ${currentUser.profile.last_name}`}</Link>
            </div>
          </div>
        </div>
        <div>
          <p className="grey fa-pull-right">{chars_left} tegn</p>
          <div className="field">
            <div className="Comment-body control">
              <textarea
                value={text}
                onChange={handleChange}
                className="textarea"
                placeholder="Kommentar"
                maxLength={maxLength}
                rows={1}
              />
            </div>
            <footer className="textbox-footer">
              <button className={'button is-black' + (isLoading ? ' is-loading' : '')} onClick={() => didClickSave()}>
                {'Lagre'}
              </button>
            </footer>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CommentForm;
