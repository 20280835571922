import React from 'react';
import AlumniLogo from '../../static/logos/jrc_alumni.png';
import AlumniLogoAi from '../../static/logos/jrc_alumni.ai';
import FlatLogo from '../../static/logos/jrc_logo_flat.png';
import FlatLogoAi from '../../static/logos/jrc_logo_flat.ai';
import FlatWhiteLogo from '../../static/logos/jrc_logo_flat_white.png';
import IconLogo from '../../static/logos/jrc_logo_icon.png';
import IconLogoAi from '../../static/logos/jrc_logo_icon.ai';
import IconWhiteLogo from '../../static/logos/jrc_logo_icon_white.png';
import IconWhiteLogoAi from '../../static/logos/jrc_logo_icon_white.ai';
import MainLogo from '../../static/logos/jrc_logo_main.png';
import MainLogoAi from '../../static/logos/jrc_logo_main.ai';
import MainWhiteLogo from '../../static/logos/jrc_logo_main_white.png';
import MainWhiteLogoAi from '../../static/logos/jrc_logo_main_white.ai';

interface IProps {
  raw: string;
  name: string;
  description: string;
}

interface IAvailableLogos {
  [index: string]: string;
}

const availableLogos: IAvailableLogos = {
  alumni: AlumniLogo,
  alumni_ai: AlumniLogoAi,
  flat: FlatLogo,
  flat_ai: FlatLogoAi,
  flat_white: FlatWhiteLogo,
  icon: IconLogo,
  icon_ai: IconLogoAi,
  icon_white: IconWhiteLogo,
  icon_white_ai: IconWhiteLogoAi,
  main: MainLogo,
  main_ai: MainLogoAi,
  main_white: MainWhiteLogo,
  main_white_ai: MainWhiteLogoAi
};

const Logo: React.FC<IProps> = ({ raw, name, description }: IProps) => {
  const hasAiVersion = ['flat', 'icon', 'alumni', 'main'].includes(raw);
  const hasWhiteVersion = ['flat', 'icon', 'main'].includes(raw);
  return (
    <div className="logo-container">
      <div>
        <img alt={`Logo ${name}`} className="box-shadow" src={availableLogos[raw]} />
      </div>
      <div className="logo-description">
        <div>{description}</div>
        {raw !== 'alumni' && (
          <a className="btn btn-danger" role="button" href={availableLogos[raw]} download={'JrC logo ' + raw}>
            <button className="button is-rounded">Last ned farget logo</button>
          </a>
        )}
        {hasWhiteVersion && (
          <a
            className="btn btn-danger"
            role="button"
            href={availableLogos[raw + '_white']}
            download={'JrC hvit logo ' + raw}
          >
            <button className="button is-rounded">Last ned hvit logo</button>
          </a>
        )}
        {hasAiVersion && (
          <a
            className="btn btn-danger"
            role="button"
            href={availableLogos[raw + '_ai']}
            download={`JrC logo ${raw}.ai`}
          >
            <button className="button is-rounded">Last ned farget vektor</button>
          </a>
        )}
        {hasAiVersion && hasWhiteVersion && raw !== 'flat' && (
          <a
            className="btn btn-danger"
            role="button"
            href={availableLogos[raw + '_white_ai']}
            download={`JrC hvit logo ${raw}_white.ai`}
          >
            <button className="button is-rounded">Last ned hvit vektor</button>
          </a>
        )}
      </div>
    </div>
  );
};

export default Logo;
