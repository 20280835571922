import React, { useState } from 'react';
import Modal from './Modal';
import Alert from 'react-s-alert';

interface IOnSave {
  name: string;
}

interface IProps {
  name: string;
  onSave: (ob: IOnSave) => void;
  isLoading: boolean;
  onClose: () => void;
  isActive: boolean;
  placeholder: string;
}

const NameForm: React.FC<IProps> = (props: IProps) => {
  const [name, setName] = useState<string>(props.name);
  const { placeholder, isActive, onClose, isLoading } = props;

  const didClickSave = (fieldName: string) => {
    if (!name || name.length === 0) {
      Alert.error(fieldName + ' må fylles ut');
    } else {
      props.onSave({ name });
    }
  };

  return (
    <Modal
      title={placeholder}
      isActive={isActive}
      onClose={onClose}
      onSave={() => didClickSave(placeholder)}
      isLoading={isLoading}
    >
      <div className="field">
        <div className="control">
          <input
            value={name}
            onChange={e => {
              setName(e.target.value);
            }}
            className="input"
            placeholder={placeholder}
          />
        </div>
      </div>
    </Modal>
  );
};

export default NameForm;
