import React, { useCallback, useEffect, useState } from 'react';
import Sticky from 'react-stickynode';
import ColumnHeader from '../../components/shared/ColumnHeader';
import NewClientForm from '../../components/klienter/NewClientForm';
import { IClient, IClientInList, IEvent } from '@jrc/jrc-butler-api/butler';
import { IFilter } from '../../types/Filter';
import { IUser } from '@jrc/jrc-butler-api/butler/user';
import TabSelection from '../../components/shared/TabSelection';
import { history } from '../../utils/history';
import { prettyPhoneNumber } from '../../utils/helpers';
import { useButler } from '../../utils/butlerContext';
import { Link } from 'react-router-dom';

enum CLIENT_STATES {
  ALL = 'Alle',
  MINE = 'Mine klienter',
  WITH_PROJECTS = 'Med prosjekter'
}

const filters: IFilter[] = [
  { id: CLIENT_STATES.ALL, title: 'Alle' },
  { id: CLIENT_STATES.MINE, title: 'Mine klienter' },
  {
    id: CLIENT_STATES.WITH_PROJECTS,
    title: 'Med prosjekter'
  }
];

interface Props {
  title: string;
  setIsLoading: (isLoading: boolean) => void;
}

const ClientList: React.FC<Props> = ({ title, setIsLoading }: Props) => {
  const context = useButler();
  const [showNewClientModal, setShowNewClientModal] = useState(false);
  const [currentFilter, setCurrentFilter] = useState(CLIENT_STATES.ALL);
  const [isCreatingClient, setIsCreatingClient] = useState(false);
  const [clients, setClients] = useState(new Array<IClient>());
  const [profiles, setProfiles] = useState(new Array<IUser>());

  const loadProfileAndClients = useCallback(async () => {
    const profileRequest = context.profileService.getUsers();
    const clientRequest = context.clientService.getClients();

    const [clients, profiles] = await Promise.all([clientRequest, profileRequest]);

    setProfiles(profiles);
    setClients(clients);
    setIsLoading(false);
  }, [setIsLoading, context]);

  useEffect(() => {
    document.title = `JrC - ${title}`;
    setIsLoading(true);
    loadProfileAndClients();
  }, [loadProfileAndClients, setIsLoading, title]);

  const createClient = async (name?: string) => {
    setIsCreatingClient(true);

    if (!name) return alert('Klarte ikke å lage klient');

    const client = await context.clientService.createClient({ name });
    if (client) {
      const event: IEvent = {
        type: 'client',
        title: `${context.currentUser?.profile.first_name} opprettet klienten ${client.name}`,
        description: '',
        entity_id: client.id || 0,
        change_by: context.currentUser?.profile.auth_id || '',
        image: context.currentUser?.profile.image || '',
        icon: 'pencil',
        date: new Date().toISOString()
      };
      await context.eventService.createEvent(event);

      history.push(`/klienter/${client.id}`);
    }
    setIsCreatingClient(false);
  };

  const sortClients = (clientA: IClientInList, clientB: IClientInList) => {
    return clientA.name.toLowerCase().localeCompare(clientB.name.toLowerCase());
  };

  const getFilteredClients = (
    clients: IClientInList[],
    filter: CLIENT_STATES,
    currentUserAuthId: string | undefined | null
  ) => {
    let filteredClients = clients.sort(sortClients);

    if (filter !== CLIENT_STATES.ALL) {
      if (filter === CLIENT_STATES.MINE) {
        filteredClients = filteredClients.filter(client =>
          client.client_members?.find(member => member.auth_id === currentUserAuthId)
        );
      } else if (filter === CLIENT_STATES.WITH_PROJECTS) {
        filteredClients = filteredClients.filter(c => (c.project_count ? c.project_count > 0 : false));
      }
    }
    return filteredClients;
  };

  return (
    <div className="columns">
      <NewClientForm
        onClose={() => setShowNewClientModal(false)}
        onSave={name => createClient(name)}
        isActive={showNewClientModal}
        isLoading={isCreatingClient}
      />

      <div className="column">
        <Sticky>
          <div className="background-white">
            <ColumnHeader
              title="Klienter"
              border={false}
              rightComponent={
                <button className="button is-black is-rounded" onClick={() => setShowNewClientModal(true)}>
                  Opprett klient
                </button>
              }
            />
          </div>
        </Sticky>

        <TabSelection items={filters} onSelect={(item: IFilter) => setCurrentFilter(item.id as CLIENT_STATES)} />

        <table className="table is-fullwidth">
          <thead>
            <tr>
              <th>Navner</th>
              <th className="is-hidden-mobile">Kontaktperson</th>
              <th>Telefon</th>
              <th className="is-hidden-mobile">Kunde siden</th>
              <th className="is-hidden-mobile">Prosjekter</th>
              <th className="is-hidden-mobile">JrC-kontakt</th>
            </tr>
          </thead>
          <tbody>
            {getFilteredClients(clients, currentFilter, context.currentUser?.profile.auth_id).map(client => {
              const authIdForLastJrcContact = client.client_members?.length
                ? client.client_members[client.client_members.length - 1].auth_id
                : null;
              const profileForLastJrcContact = profiles.find(p => p.auth_id === authIdForLastJrcContact);
              if (!client.id) return <h1>Det skjedde en feil. Ta kontakt med interne systemer</h1>;
              return (
                <Link className="table-row" key={client.id} to={`/klienter/${client.id}`}>
                  <td>{client.name}</td>
                  <td className="is-hidden-mobile">{client.contact_name}</td>
                  <td className="is-hidden-mobile">{prettyPhoneNumber(client.contact_number)}</td>
                  <td />
                  <td className="is-hidden-mobile">{client.project_count}</td>
                  <td>
                    {' '}
                    {profileForLastJrcContact
                      ? profileForLastJrcContact.first_name + ' ' + profileForLastJrcContact.last_name
                      : ''}
                  </td>
                </Link>
              );
            })}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default ClientList;
