import React, { useState } from 'react';
import { IFilter } from '../../types/Filter';

interface IProps {
  items: ReadonlyArray<IFilter>;
  // onSelect: (item: IFilter) => void;
  onSelect: any;
  defaultSelected?: IFilter;
}

const TabSelection: React.FC<IProps> = (props: IProps) => {
  const [selectedItem, setSelectedItem] = useState<string>(
    props.defaultSelected ? props.defaultSelected.id : props.items[0].id
  );

  const didSelectItem = (item: IFilter) => {
    if (props.onSelect) {
      props.onSelect(item);
    }
    setSelectedItem(item.id);
  };

  const { items } = props;
  return (
    <div className="tabs is-toggle is-toggle-rounded m-t-15 m-b-20">
      <ul>
        {items.map(item => (
          <li key={item.id} className={selectedItem === item.id ? 'is-active' : ''} onClick={() => didSelectItem(item)}>
            <a href="# ">
              <span>{item.title}</span>
            </a>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default TabSelection;
