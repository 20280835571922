import React, { FC } from 'react';
interface Iprops {
  title: string;
  description: string;
}
const WikiWithoutLink: FC<Iprops> = ({ title, description }) => (
  <li>
    <p className="has-text-dark">{title}</p>
    <br />
    <p>{description}</p>
  </li>
);

export default WikiWithoutLink;
