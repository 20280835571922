import React, { useState } from 'react';

interface IPicture {
  imageUrl: string | undefined | null;
  canEdit: boolean;
  onClick: () => void;
}

const Picture: React.FC<IPicture> = ({ imageUrl, canEdit, onClick }) => {
  const [imageIsHovered, setImageIsHovered] = useState(false);

  return (
    <div className="image-container">
      {canEdit && imageIsHovered && (
        <div
          onClick={() => onClick && onClick()}
          onMouseLeave={() => setImageIsHovered(false)}
          className="image-overlay has-text-white has-text-weight-light is-size-3"
        >
          Endre bilde
        </div>
      )}
      <img
        alt="Profile"
        onMouseEnter={() => setImageIsHovered(true)}
        className="profile-picture round"
        src={imageUrl ? imageUrl : 'https://bulma.io/images/placeholders/480x480.png'}
      />
    </div>
  );
};

export default Picture;
