import React from 'react';

interface IProps {
  red: number;
  green: number;
  blue: number;
}

const ColorBox: React.FC<IProps> = ({ red, green, blue }: IProps) => {
  const rgb = blue | (green << 8) | (red << 16);
  const hex = '#' + (0x1000000 + rgb).toString(16).slice(1);
  const fontColor = rgb === 16251386 ? 'black' : 'white'; //is gray
  return (
    <div>
      <div className="color-box box-shadow" style={{ background: 'rgb(' + red + ', ' + green + ', ' + blue + ')' }}>
        <span style={{ color: fontColor }} className="color-span">
          R {red}
        </span>
        <span style={{ color: fontColor }} className="color-span">
          G {green}
        </span>
        <span style={{ color: fontColor }} className="color-span">
          B {blue}
        </span>
      </div>
      <span style={{ margin: 10, color: '#333333' }}>{hex} </span>
    </div>
  );
};

export default ColorBox;
