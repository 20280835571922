import React, { useEffect } from 'react';
import ColumnHeader from '../../components/shared/ColumnHeader';
import Logo from '../../components/styleguide/Logo';
import Section from '../../components/styleguide/Section';
import Template from '../../components/styleguide/Template';
import ColorBox from '../../components/styleguide/ColorBox';
import LatoRegular from '../../static/fonts/Lato-Regular.ttf';
import LatoBold from '../../static/fonts/Lato-Bold.ttf';
import LatoItalic from '../../static/fonts/Lato-Italic.ttf';

interface IProps {
  title: string;
}

const Styleguide: React.FC<IProps> = ({ title }: IProps) => {
  useEffect(() => {
    document.title = `JrC - ${title}`;
  });

  return (
    <div className="columns styleguide">
      <div className="column">
        <ColumnHeader className="section" title="Styleguide" border rightComponent />
        <Section title={'Hvorfor?'}>
          Inkonsistens i hvordan vi kommuniserer, både visuelt, skriftlig og verbalt er med på å svekke varemerke til
          Junior Consulting. Denne siden beskriver i korte trekk hvilke krav vi stiller til bruk av f.eks. navn, logo og
          typografi.
        </Section>
        <Section title={'Selskapets navn'}>
          Muntlig kan både Junior og Junior Consulting brukes. Skriftlig skal ‘Junior Consulting’ eller ‘JrC’ brukes
        </Section>

        <Section title={'Logo'}>
          <div className="tile is-ancestor">
            <div className="tile is-vertical is-8">
              <div className="tile is-parent">
                <article className="tile is-child box">
                  <p className="subtitle">Hovedlogo</p>
                  <div className="content">
                    <Logo
                      raw="main"
                      name="Hovedlogo"
                      description="Brukes i offisielle sammenhenger, så ofte som mulig. Minste størrelse tillatt  100 pixler i bredden og 63 pixler i høyden. Forholdet mellom høyde og bredde i logoen skal ikke endres på."
                    />
                  </div>
                </article>
              </div>
              <div className="tile is-parent">
                <article className="tile is-child box">
                  <p className="subtitle">Ikon</p>
                  <div className="content">
                    <Logo
                      raw="icon"
                      name="icon"
                      description="Ikon-versjon av logoen. Brukes der det ikke er plass eller passer med hele hovedlogoen. F.eks. område er mindre enn  pixler i bredden"
                    />
                  </div>
                </article>
              </div>
              <div className="tile is-parent">
                <article className="tile is-child box">
                  <p className="subtitle">Flat logo</p>
                  <div className="content">
                    <Logo
                      raw="flat"
                      name="flat"
                      description="En flat versjon, kan brukes der høyden er mindre enn 63 pixler eller f.eks. i PowerPointer"
                    />
                  </div>
                </article>
              </div>
              <div className="tile is-parent">
                <article className="tile is-child box">
                  <p className="subtitle">Alumni logo</p>
                  <div className="content">
                    <Logo
                      raw="alumni"
                      name="alumni"
                      description="Brukes på samme måte som den flate versjonen, der JrCs alumni skal representeres"
                    />
                  </div>
                </article>
              </div>
            </div>
            <div className="tile is-parent">
              <article className="message tile is-child box notification is-danger" style={{ padding: 0 }}>
                <div className="message-header is-danger">Ikke rediger logoen, det inkluderer:</div>
                <div className="message-body">
                  <div className="content">
                    <ul>
                      <li>Ikke legg til fargeendringer</li>
                      <li>Ikke roter logoen</li>
                      <li>Ikke endre sideforhold (eng.: aspect ratio) i logoen</li>
                      <li>Aldri bare bruk Junior-teksten uten ikonet</li>
                      <li>Ikke bruk en annen skrift av Junior-teksten ved siden av ikonet</li>
                    </ul>{' '}
                  </div>
                </div>
              </article>
            </div>
          </div>
        </Section>
        <Section title="Maler">
          <div className="columns">
            <div className="column">
              <Template
                name="CV"
                type="presentation"
                file_id="1fUKYiQFvKe1Ov5dOptAv1qTXb8FkbN-3YNRg6wa7tpo"
                export_as="pptx"
              />
            </div>
            <div className="column">
              <Template
                name="Kaffedeck"
                type="presentation"
                file_id="1Eii2Fd8J5BVjHuelQTKgKzAEky2QxUnLSMi_SB8yV1E"
                export_as="pptx"
              />
            </div>
          </div>

          <div className="columns">
            <div className="column">
              <Template
                name="Powerpoint"
                type="presentation"
                file_id="1x-s0ceUzQlPyEA_XMkvfQLCDAIWCoAQv"
                export_as="pptx"
              />
            </div>
            <div className="column">
              <Template
                name="Møtereferat mal"
                type="document"
                file_id="16cNPVi95Gu5z-ic-l2lrP5DEo65Ra9uEcAQATMuWh1M"
                export_as="docx"
              />
            </div>
          </div>
        </Section>
        <Section title="Fargepalett">
          <div className="tile is-ancestor">
            <div className="tile is-vertical is-8">
              <div className="tile is-parent">
                <article className="tile is-child box">
                  <div>
                    <div className="color-boxes">
                      <ColorBox red={5} green={7} blue={8} />
                      <ColorBox red={223} green={0} blue={22} />
                      <ColorBox red={247} green={249} blue={250} />
                      <ColorBox red={204} green={149} blue={3} />
                      <ColorBox red={51} green={51} blue={51} />
                    </div>
                  </div>
                </article>
              </div>
            </div>
            <div className="tile is-parent">
              <article className="message tile is-child box notification is-danger" style={{ padding: 0 }}>
                <div className="message-header is-danger">Obs!</div>
                <div className="message-body">
                  <div className="content">
                    Rød-fargen skal kun brukes som signalfarge, og ikke til f.eks. tekstfarge
                  </div>
                </div>
              </article>
            </div>
          </div>
        </Section>
        <Section title="Typografi">
          <div className="tile">
            <article className="tile is-child box">
              <div>
                <div>
                  Fonten Lato skal alltid brukes, med regular som standard. Hvis ikke denne er tilgjengelig (eks. i
                  mail), skal en ‘sans serif’-font brukes. Unngå fontstørrelse under 12px, da dette kan være vanskelig å
                  lese. Anbefalt linjehøyde er 1.4
                </div>
                <form method="get" action={LatoRegular}>
                  <button className="button is-rounded">Last ned Lato regular</button>
                </form>
                <form method="get" action={LatoBold}>
                  <button className="button is-rounded">
                    Last ned <span style={{ fontWeight: 700 }}>&nbsp;Lato bold</span>
                  </button>
                </form>
                <form method="get" action={LatoItalic}>
                  <button className="button is-rounded">
                    Last ned <span style={{ fontStyle: 'italic' }}>&nbsp;Lato italic</span>
                  </button>
                </form>
              </div>
            </article>
          </div>
        </Section>
        <Section title="Ikoner">
          <div className="tile">
            <article className="tile is-child box">
              Vi bruker FontAwesome Pro. Les om hvordan komme i gang{' '}
              <a href="https://git.jrc.no/open-source/internarena/blob/master/readme.md#7-fontawesome-icons">her</a>
            </article>
          </div>
        </Section>
      </div>
    </div>
  );
};

export default Styleguide;
