import React, { Fragment, useEffect } from 'react';
import { Router, Route, Switch } from 'react-router-dom';
import Alert from 'react-s-alert';
import { history } from './utils/history';
import Layout from './components/shared/Layout';
import ButlerContextProvider from './utils/butlerContext';
import { useAuth0 } from './utils/authContext';
import Spinner from './components/shared/Spinner';
import Signup from './pages/register/Signup';
import ValidateEmail from './pages/register/ValidateEmail';
import VerifyEmail from './pages/register/VerifyEmail';

const App: React.FC = () => {
  const { isAuthenticated, isInitializing, loginWithRedirect, registrationStatus } = useAuth0();

  useEffect(() => {
    if (!registrationStatus && !isInitializing && !isAuthenticated) {
      loginWithRedirect();
    }
  }, [isInitializing, isAuthenticated, loginWithRedirect, registrationStatus]);

  if (isInitializing) {
    return <Spinner />;
  }

  if (registrationStatus && !registrationStatus.isEmailValid) {
    return <ValidateEmail />;
  }

  if (registrationStatus && !registrationStatus.isEmailVerified) {
    return <VerifyEmail />;
  }

  return (
    <Fragment>
      <Alert position="top-right" stack={true} effect="stackslide" />
      <ButlerContextProvider>
        <Router history={history}>
          <Switch>
            <Route path="/register" component={Signup} />
            <Route path="/" component={Layout} />
          </Switch>
        </Router>
      </ButlerContextProvider>
    </Fragment>
  );
};

export default App;
