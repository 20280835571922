import React, { useCallback, useEffect, useState } from 'react';
import Alert from 'react-s-alert';
import WorkHourForm from '../../components/timeregistrering/WorkHourForm';
import TimeEntry from '../../components/timeregistrering/TimeEntry';
import { useButler } from '../../utils/butlerContext';
import { IProject, ITime } from '@jrc/jrc-butler-api/butler';

export const validateIfStringIsValidNumber = (input: string): boolean => {
  if (!/^[0-9]*\.?[0-9]*$/.test(input)) {
    Alert.error('Kun tall og . er tillatt');
    return false;
  }
  return true;
};

export interface IUpdateTimeEntry {
  projectId: number;
  date: string;
  hours: number;
  description: string;
  tag: string;
}

interface Props {
  title: String;
  setIsLoading: (isLoading: boolean) => null;
}

const TimeRegistration: React.FC<Props> = ({ title, setIsLoading }: Props) => {
  const context = useButler();
  const [hours, setHours] = useState<ITime[]>([]);
  const [projects, setProjects] = useState<IProject[]>([]);
  const [isAddingWorkHour, setIsAddingWorkHour] = useState(false);

  const loadHoursAndProjects = useCallback(async () => {
    setIsLoading(true);
    const timeService = context.timeService;
    const projectService = context.projectService;
    const currentUser = context.currentUser;
    if (currentUser && currentUser.profile.auth_id != null) {
      const hours = await timeService.hoursPerEmployee(currentUser.profile.auth_id);
      setHours(hours);
      // TODO bruk butler projects når det blir lagt til. (Usikker på denne -Jesper)
      const projects = await projectService.getProjectsForUserId(currentUser.profile.auth_id);
      setProjects(projects);
    }
    setIsLoading(false);
  }, [context, setIsLoading]);

  useEffect(() => {
    document.title = `JrC - ${title}`;
    loadHoursAndProjects();
  }, [loadHoursAndProjects, setIsLoading, title]);

  const workHourHasAllFields = (workHour: IUpdateTimeEntry) => {
    return workHour.projectId && workHour.hours && workHour.tag && workHour.description;
  };

  const workHourIsBeforeLastBilled = async (workHour: IUpdateTimeEntry) => {
    const timeService = context.timeService;
    const lastBilledWorkHour = await timeService.latestBilledHourInProject(workHour.projectId);
    if (lastBilledWorkHour === undefined) {
      return false;
    } else {
      return Date.parse(lastBilledWorkHour.date) > Date.parse(workHour.date);
    }
  };

  const verifyWorkHours = async (timeEntry: IUpdateTimeEntry): Promise<boolean> => {
    if (!workHourHasAllFields(timeEntry)) {
      Alert.error('Ett eller flere av feltene var ikke fylt ut');
      return false;
    } else if (await workHourIsBeforeLastBilled(timeEntry)) {
      Alert.error('Det er allerede fakturert timer etter satt dato. Vennligst kontakt økonomiansvarlig');
      return false;
    }
    return true;
  };

  const addWorkHour = async (timeEntry: IUpdateTimeEntry) => {
    const timeService = context.timeService;
    const currentUser = context.currentUser;

    if (!currentUser?.profile.auth_id) {
      Alert.error(
        'Det oppstod en feil ved opplastning. Forsøk å reload siden og ta kontakt med IS dersom det vedvarer'
      );
      return;
    }
    if (!(await verifyWorkHours(timeEntry))) return;

    setIsAddingWorkHour(true);

    const entryWithAuthId = { ...timeEntry, authId: currentUser.profile.auth_id };
    const time = await timeService.createTime(entryWithAuthId);

    setHours([...hours, time]);
    setIsAddingWorkHour(false);

    Alert.success('Timen ble lagt til!');
  };

  const deleteEntry = async (entryId: number) => {
    const timeService = context.timeService;
    await timeService.deleteTime(entryId);
    setHours(hours.filter(hour => hour.id !== entryId));
    Alert.success('Timen ble slettet!');
  };

  const updateEntry = async (timeEntry: ITime) => {
    const timeService = context.timeService;
    if (timeEntry.id != null) {
      const hour = await timeService.updateTime(timeEntry.id, timeEntry);
      const filteredHours = hours.filter(hour => hour.id !== timeEntry.id);
      setHours([...filteredHours, hour]);
      Alert.success('Timene ble oppdatert');
    } else {
      Alert.error('Klarte ikke å oppdatere time');
    }
  };

  const reduceTags = (hours: ITime[]) => {
    const defaults = ['Utvikling', 'Møte', 'WS'];
    if (hours.length > 0) {
      const res = [...hours.map(hour => hour.tag), ...defaults];
      return res.filter(onlyUniqueTags);
    }
    return defaults;
  };

  const onlyUniqueTags = (value: string, index: number, self: string[]) => {
    return self.indexOf(value) === index;
  };

  const numHours = () =>
    (hours.length > 0 ? hours.reduce((sum, employee) => sum + Number(employee.hours), 0) : 0).toFixed(2);
  const paidHours = () =>
    (hours.length > 0
      ? hours.reduce((sum, employee) => (employee.paid ? sum + Number(employee.hours) : sum), 0)
      : 0
    ).toFixed(2);
  const outstandingHours = () =>
    (hours.length > 0
      ? hours.reduce((sum, employee) => (employee.paid ? sum : sum + Number(employee.hours)), 0)
      : 0
    ).toFixed(2);

  return (
    <div className="columns">
      <div className="column">
        <div className="level m-t-20 m-b-50">
          <div className="level-item has-text-centered">
            <div>
              <p className="heading">Tot. timer</p>
              <p className="title">{numHours()}</p>
            </div>
          </div>
          <div className="level-item has-text-centered">
            <div>
              <p className="heading">Fakturerte timer</p>
              <p className="title">{paidHours()}</p>
            </div>
          </div>
          <div className="level-item has-text-centered">
            <div>
              <p className="heading">Utestående timer</p>
              <p className="title">{outstandingHours()}</p>
            </div>
          </div>
        </div>
        <div className="register-work-hour m-b-50">
          <h5 className="title is-5">Registrer ny time</h5>
          <WorkHourForm
            projects={projects}
            tagOptions={reduceTags(hours)}
            isLoading={isAddingWorkHour}
            onSave={entry => addWorkHour(entry)}
          />
        </div>

        <div>
          <h5 className="title is-5">Mine timer</h5>
          {hours.length !== 0 ? (
            <table className="table is-fullwidth is-striped">
              <thead>
                <tr>
                  <th>Dato</th>
                  <th>Prosjekt</th>
                  <th>Antall timer</th>
                  <th>Beskrivelse</th>
                  <th>Tags</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                {hours
                  .sort((a, b) => (new Date(a.date) > new Date(b.date) ? -1 : 1))
                  .map(
                    hour =>
                      context.currentUser?.profile.auth_id && (
                        <TimeEntry
                          key={String(hour.id)}
                          projects={projects}
                          entry={hour}
                          authId={context.currentUser?.profile.auth_id}
                          invoiceId={hour.invoiceId}
                          onDelete={entryId => deleteEntry(entryId)}
                          onUpdate={newState => updateEntry(newState)}
                        />
                      )
                  )}
              </tbody>
            </table>
          ) : (
            <p>Du har ikke registrert noen timer enda</p>
          )}
        </div>
      </div>
    </div>
  );
};

export default TimeRegistration;
