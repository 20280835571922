import React from 'react';
import { IUser } from '@jrc/jrc-butler-api/butler';

interface Props {
  removeContact: (authId?: string | null) => void;
  selectedContacts: IUser[];
}

const JrCContactList: React.FC<Props> = ({ removeContact, selectedContacts }: Props) => {
  return (
    <div className="selected-member-list">
      <table className="table is-fullwidth">
        <thead>
          <tr>
            <th>Navn</th>
            <th />
          </tr>
        </thead>
        <tbody>
          {selectedContacts &&
            selectedContacts.map(member => {
              return (
                <tr key={String(member?.auth_id)}>
                  <td>
                    {member.first_name} {member.last_name}
                  </td>
                  <td onClick={() => removeContact(member.auth_id)}>
                    <div className="delete" />
                  </td>
                </tr>
              );
            })}
        </tbody>
      </table>
    </div>
  );
};

export default JrCContactList;
