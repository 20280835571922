import React, { useContext, useState, useEffect } from 'react';
import {
  butlerNews,
  butlerMetabase,
  butlerEvents,
  butlerProfiles,
  butlerClients,
  butlerProjects,
  butlerTime
} from '@jrc/jrc-butler-api';
import { DefaultHttpApiBridge } from 'conjure-client';
import { useAuth0 } from './authContext';
import { IUser } from '@jrc/jrc-butler-api/butler';
import FileStorageService from '../services/fileStorageService';
const butlerProdUrl = 'https://butler.jrc.no/api';

const butlerUrl = process.env.REACT_APP_BUTLER_URL ?? butlerProdUrl;

export type CurrentUser = {
  profile: IUser;
};

export const getBridge = (token: string) =>
  new DefaultHttpApiBridge({
    baseUrl: butlerUrl,
    userAgent: {
      productName: 'internarena',
      productVersion: '1.0.0'
    },
    token
  });

export interface ButlerContextProps {
  newsService: butlerNews.INewsService;
  metaService: butlerMetabase.IMetaService;
  eventService: butlerEvents.IEventsService;
  profileService: butlerProfiles.IProfilesService;
  fileStorageService: FileStorageService;
  clientService: butlerClients.IClientsService;
  clientCommentService: butlerClients.IClientCommentsService;
  // Should be inside butlerClients
  clientMemberService: butlerClients.IClientMembersService;
  projectService: butlerProjects.IProjectsService;
  projectMemberService: butlerProjects.IProjectMembersService;
  projectCommentService: butlerProjects.IProjectCommentsService;
  projectReferenceService: butlerProjects.IProjectReferencesService;
  timeService: butlerTime.ITimeService;
  isInitializing: boolean;
  currentUser?: CurrentUser;
}

const defaultBridge = getBridge('');

const emptyContext = {
  newsService: new butlerNews.NewsService(defaultBridge),
  metaService: new butlerMetabase.MetaService(defaultBridge),
  eventService: new butlerEvents.EventsService(defaultBridge),
  profileService: new butlerProfiles.ProfilesService(defaultBridge),
  fileStorageService: new FileStorageService(defaultBridge),
  clientService: new butlerClients.ClientsService(defaultBridge),
  clientCommentService: new butlerClients.ClientCommentsService(defaultBridge),
  clientMemberService: new butlerClients.ClientMembersService(defaultBridge),
  projectService: new butlerProjects.ProjectsService(defaultBridge),
  projectCommentService: new butlerProjects.ProjectCommentsService(defaultBridge),
  projectMemberService: new butlerProjects.ProjectMembersService(defaultBridge),
  projectReferenceService: new butlerProjects.ProjectReferencesService(defaultBridge),
  timeService: new butlerTime.TimeService(defaultBridge),
  isInitializing: true
};

export const ButlerContext = React.createContext<ButlerContextProps>(emptyContext);

export const useButler = () => useContext(ButlerContext);

interface Props {
  children?: React.ReactNode;
}

export const ButlerContextProvider: React.FC<Props> = ({ children }) => {
  const { apiToken, apiUser } = useAuth0();
  const [context, setContext] = useState<ButlerContextProps>(emptyContext);

  useEffect(() => {
    const createNewContext = async () => {
      if (apiToken && apiUser && apiUser.sub) {
        const bridge = getBridge(apiToken);

        const profileService = new butlerProfiles.ProfilesService(bridge);

        const parsedUserId = apiUser.sub.replace('auth0|', '');

        let currentUser = undefined;

        try {
          const profile = await profileService.getUser(parsedUserId);
          currentUser = {
            profile
          };
        } catch (error) {}

        const newContext: ButlerContextProps = {
          newsService: new butlerNews.NewsService(bridge),
          eventService: new butlerEvents.EventsService(bridge),
          metaService: new butlerMetabase.MetaService(bridge),
          profileService: profileService,
          fileStorageService: new FileStorageService(bridge),
          clientService: new butlerClients.ClientsService(bridge),
          clientCommentService: new butlerClients.ClientCommentsService(bridge),
          clientMemberService: new butlerClients.ClientMembersService(bridge),
          projectService: new butlerProjects.ProjectsService(bridge),
          projectCommentService: new butlerProjects.ProjectCommentsService(bridge),
          projectMemberService: new butlerProjects.ProjectMembersService(bridge),
          projectReferenceService: new butlerProjects.ProjectReferencesService(bridge),
          timeService: new butlerTime.TimeService(bridge),
          isInitializing: false,
          currentUser: currentUser
        };

        setContext(newContext);
      }
    };
    createNewContext();
  }, [apiToken, apiUser]);

  return <ButlerContext.Provider value={context}>{children}</ButlerContext.Provider>;
};

export default ButlerContextProvider;
