import React, { FC } from 'react';
interface Iprops {
  title: string;
  description: string;
  link?: string;
}
const WikiLink: FC<Iprops> = ({ title, description, link }) => (
  <li>
    <a target="_blank" rel="noopener noreferrer" href={link}>
      {title}
    </a>
    <br />
    <p>{description}</p>
  </li>
);

export default WikiLink;
