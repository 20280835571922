export type ProjectGroupTypes = 'NONE' | 'BRANDING' | 'JRCBOKEN' | 'INTERNE SYSTEMER' | 'NETTSIDE' | 'MERSALG';

enum PROJECT_GROUPS {
  // These fields are the same as the ones in profilesService in jrc-butler
  // If changes are made here, you must also do the same changes in the model.
  NONE = 'Ikke satt',
  BRANDING = 'Branding',
  JRCBOKEN = 'JrC-Boken',
  'INTERNE SYSTEMER' = 'Interne systemer',
  NETTSIDE = 'Nettside',
  MERSALG = 'Mersalg'
}

export default PROJECT_GROUPS;
