import React, { useEffect, useState } from 'react';
import Modal from '../shared/Modal';

interface IProps {
  onSave: (fromDate: string, toDate: string) => void;
  isActive: boolean;
  onClose: () => void;
  isLoading: boolean;
}

const DownloadHoursForm: React.FC<IProps> = ({ onSave, isActive, onClose, isLoading }: IProps) => {
  const [fromDate, setFromDate] = useState<string>('');
  const [toDate, setToDate] = useState<string>('');

  useEffect(() => {
    const fromDate = new Date();
    const toDate = new Date();
    fromDate.setDate(toDate.getDate() - 30);
    setFromDate(fromDate.toJSON().slice(0, 10));
    setToDate(toDate.toJSON().slice(0, 10));
  }, []);

  return (
    <Modal
      title="Last ned timer"
      isActive={isActive}
      onClose={onClose}
      onSave={() => onSave(fromDate, toDate)}
      isLoading={isLoading}
    >
      <div style={styles.calendarContainer}>
        <p className="control is-expanded">
          Fra dato
          <input
            className="input"
            type="text"
            value={fromDate}
            placeholder="Dato"
            onChange={e => setFromDate(e.target.value)}
          />
        </p>
        <p className="control is-expanded">
          Til dato
          <input
            className="input"
            type="text"
            value={toDate}
            placeholder="Dato"
            onChange={e => setToDate(e.target.value)}
          />
        </p>
      </div>
      <hr />
    </Modal>
  );
};

const styles = {
  calendarContainer: {
    padding: '19px 120px 240px 120px'
  },
  buttonContainer: {
    display: 'flex',
    justifyContent: 'space-around'
  }
};

export default DownloadHoursForm;
