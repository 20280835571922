import React, { useState } from 'react';
import Modal from '../shared/Modal';
import STUDY_PROGRAMS from './studyPrograms';
import PROJECT_GROUPS from './projectGroups';
import Alert from 'react-s-alert';
import { makeFormOptions } from '../../utils/helpers';
import { JrcTitles, ProjectGroups, StudyPrograms } from '@jrc/jrc-butler-api/butler';
import { IUpdatedData } from 'types/updatedData';

interface IUserInfo {
  active: boolean | null | undefined;
  alumni_company: string | null | undefined;
  alumni_title: string | null | undefined;
  exchange: boolean | null | undefined;
  address: string | null | undefined;
  title: JrcTitles | null | undefined;
  linkedin: string | null | undefined;
  phone_nr: string | null | undefined;
  email: string;
  study_program: string;
  project_group: string;
}

interface IContactInfoForm {
  userInfo: IUserInfo;
  isLoading: boolean;
  isActive: boolean;
  onClose: () => void;
  onSave: (updatedData: IUpdatedData) => Promise<void>;
  editorIsPartner: boolean;
}

const ContactInfoForm: React.FC<IContactInfoForm> = ({
  userInfo,
  isLoading,
  isActive,
  onClose,
  onSave,
  editorIsPartner
}) => {
  const [userTitle, setUserTitle] = useState(userInfo.title);
  const [userIsActive, setUserIsActive] = useState<boolean>(!!userInfo.active);
  const [phoneInput, setPhoneInput] = useState(userInfo.phone_nr ?? '');
  const [emailInput, setEmailInput] = useState(userInfo.email);
  const [addressInput, setAddressInput] = useState(userInfo.address ?? '');
  const [exchangeCheckBox, setExchangeCheckBox] = useState<boolean>(userInfo.exchange ?? false);
  const [linkedinInput, setLinkedinInput] = useState(userInfo.linkedin ?? '');
  const [studyProgramDropdown, setStudyProgramDropdown] = useState<StudyPrograms>(
    userInfo.study_program as StudyPrograms
  );
  const [projectGroupDropdown, setProjectGroupDropdown] = useState<ProjectGroups>(
    userInfo.project_group as ProjectGroups
  );
  const [alumniCompanyInput, setAlumniCompanyInput] = useState(userInfo.alumni_company ?? '');
  const [alumniTitleInput, setAlumniTitleInput] = useState(userInfo.alumni_title ?? '');

  const updateUserActive = (active: boolean): void => {
    setUserIsActive(active);
    setUserTitle(active ? JrcTitles.CONSULTANT : JrcTitles.ALUMNI);
  };

  const didClickSave = () => {
    const active = userIsActive;
    const phone_nr = phoneInput;
    const email = emailInput;
    const address = addressInput;
    const exchange = active ? exchangeCheckBox : false;
    const linkedin = linkedinInput.startsWith('http') ? linkedinInput : '';
    const study_program = studyProgramDropdown;
    const project_group = projectGroupDropdown;
    const alumni_company = !active ? alumniCompanyInput : userInfo.alumni_company;
    const alumni_title = !active ? alumniTitleInput : userInfo.alumni_title;

    if (linkedin === '' || linkedin.charAt(linkedin.length - 1) === '/') {
      Alert.error('Du må fylle inn en gydig linkedin profil. Ikke avslutt med /');
    } else {
      onSave({
        active,
        phone_nr,
        exchange,
        address,
        linkedin,
        email,
        study_program,
        project_group,
        alumni_company,
        alumni_title,
        title: userTitle,
        about: '' // userInfo.about
      });
    }
  };

  return (
    <Modal title="Kontaktinformation" onSave={didClickSave} isLoading={isLoading} isActive={isActive} onClose={onClose}>
      <div className="field">
        <div className="field-body">
          <div className="field">
            <label className="label">Status</label>
            <div className="control">
              <label className="radio">
                <input
                  checked={userIsActive}
                  onChange={() => updateUserActive(true)}
                  value="active"
                  type="radio"
                  name="member"
                />
                Aktiv
              </label>
              <label className="radio">
                <input
                  onChange={() => updateUserActive(false)}
                  checked={!userIsActive}
                  value="alumni"
                  type="radio"
                  name="member"
                />
                Alumni
              </label>
            </div>
            {userIsActive ? (
              <div className="field">
                <input
                  id="exchange"
                  className="control"
                  type="checkbox"
                  checked={exchangeCheckBox}
                  onChange={e => setExchangeCheckBox(e.target.checked)}
                />
                På utveksling
              </div>
            ) : (
              <div>
                <div className="field">
                  <label className="label">Bedrift</label>
                  <div className="control">
                    <input
                      className="input"
                      onChange={e => setAlumniCompanyInput(e.target.value)}
                      value={alumniCompanyInput}
                      type="text"
                      placeholder="e.g Senior Consulting"
                    />
                  </div>
                </div>
                <div className="field">
                  <label className="label">Tittel</label>
                  <div className="control">
                    <input
                      className="input"
                      value={alumniTitleInput}
                      onChange={e => setAlumniTitleInput(e.target.value)}
                      type="text"
                      placeholder="e.g Daglig leder"
                    />
                  </div>
                </div>
              </div>
            )}
            {editorIsPartner && (
              <div className="field">
                <label className="label">Tittel</label>
                <div className="control">
                  <label className="radio">
                    <input
                      checked={userTitle === JrcTitles.CONSULTANT}
                      onChange={() => setUserTitle(JrcTitles.CONSULTANT)}
                      type="radio"
                      name="title"
                    />
                    Konsulent
                  </label>
                  <label className="radio">
                    <input
                      checked={userTitle === JrcTitles.ALUMNI}
                      onChange={() => setUserTitle(JrcTitles.ALUMNI)}
                      type="radio"
                      name="title"
                    />
                    Alumni
                  </label>
                  <label className="radio">
                    <input
                      checked={userTitle === JrcTitles.PARTNER}
                      onChange={() => setUserTitle(JrcTitles.PARTNER)}
                      type="radio"
                      name="title"
                    />
                    Partner
                  </label>
                  <label className="radio">
                    <input
                      checked={userTitle === JrcTitles.CEO}
                      onChange={() => setUserTitle(JrcTitles.CEO)}
                      type="radio"
                      name="title"
                    />
                    CEO
                  </label>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>

      <div className="field">
        <label className="label">Studieprogram</label>
        <div className="field select">
          <select value={studyProgramDropdown} onChange={e => setStudyProgramDropdown(e.target.value as StudyPrograms)}>
            <option disabled value="None">
              Velg studieprogram
            </option>
            {makeFormOptions(STUDY_PROGRAMS)}
          </select>
        </div>
      </div>

      <div className="field">
        <label className="label">Prosjektgruppe</label>
        <div className="field select">
          <select value={projectGroupDropdown} onChange={e => setProjectGroupDropdown(e.target.value as ProjectGroups)}>
            <option disabled value="None">
              Velg prosjektgruppe
            </option>
            {makeFormOptions(PROJECT_GROUPS)}
          </select>
        </div>
      </div>

      <div className="field">
        <label className="label">Telefon</label>
        <div className="control">
          <input
            maxLength={8}
            className="input"
            onChange={e => setPhoneInput(e.target.value)}
            value={phoneInput}
            type="text"
            placeholder="e.g 900222333"
          />
        </div>
      </div>

      <div className="field">
        <label className="label">LinkedIn</label>
        <div className="control">
          <input
            className="input"
            onChange={e => setLinkedinInput(e.target.value)}
            value={linkedinInput}
            type="text"
            placeholder="e.g. http://linkedin.com/in/your-name"
          />
        </div>
      </div>

      <div className="field">
        <label className="label">Email</label>
        <div className="control">
          <input
            className="input"
            onChange={e => setEmailInput(e.target.value)}
            value={emailInput}
            type="email"
            placeholder="e.g. hei@juniorconsulting.no"
          />
        </div>
      </div>

      <div className="field">
        <label className="label">Adresse</label>
        <div className="control">
          <input
            className="input"
            onChange={e => setAddressInput(e.target.value)}
            value={addressInput}
            type="text"
            placeholder="e.g Junior Consultants vei 1, 7030 Trondheim"
          />
        </div>
      </div>
    </Modal>
  );
};

export default ContactInfoForm;
