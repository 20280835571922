import React, { useState } from 'react';

import Modal from './Modal';

interface UpdatedData {
  name: string;
  role: string;
  phone: string;
  email: string;
}

interface IProps {
  isLoading: boolean;
  isActive: boolean;
  name?: string | null;
  role?: string | null;
  phone?: string | null;
  email?: string | null;
  onClose: () => void;
  onSave: (updatedData: UpdatedData) => void;
}

const ContactForm: React.FC<IProps> = ({ isLoading, isActive, name, role, phone, email, onClose, onSave }) => {
  const [nameInput, setNameInput] = useState<string>(name ?? '');
  const [roleInput, setRoleInput] = useState<string>(role ?? '');
  const [phoneInput, setPhoneInput] = useState<string>(phone ?? '');
  const [emailInput, setEmailInput] = useState<string>(email ?? '');

  const didClickSave = () => {
    const name = nameInput;
    const role = roleInput;
    const phone = phoneInput;
    const email = emailInput;
    onSave({ name, role, phone, email });
  };

  return (
    <Modal
      title="Kontaktperson"
      onSave={() => didClickSave()}
      isLoading={isLoading}
      isActive={isActive}
      onClose={onClose}
    >
      <div className="field">
        <label className="label">Navn</label>
        <div className="control">
          <input
            className="input"
            onChange={event => setNameInput(event.target.value)}
            value={nameInput}
            type="text"
            placeholder="Kontakt navn"
          />
        </div>
      </div>

      <div className="field">
        <label className="label">Rolle</label>
        <div className="control">
          <input
            className="input"
            onChange={event => setRoleInput(event.target.value)}
            value={roleInput}
            type="text"
            placeholder="Kontakting sin rolle"
          />
        </div>
      </div>

      <div className="field">
        <label className="label">Telefon</label>
        <div className="control">
          <input
            className="input"
            onChange={event => setPhoneInput(event.target.value)}
            value={phoneInput}
            type="email"
            placeholder="Telefon"
          />
        </div>
      </div>

      <div className="field">
        <label className="label">E-post</label>
        <div className="control">
          <input
            className="input"
            onChange={event => setEmailInput(event.target.value)}
            value={emailInput}
            type="email"
            placeholder="e.g hei@firma.no"
          />
        </div>
      </div>
    </Modal>
  );
};

export default ContactForm;
